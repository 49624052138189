<template>
  
    <div id="Muestras">
       
      
      
        <div v-if="historyOption =='Default'">
         
          <div class="text_option txt1">
                           
                            <h3>Identificación de Muestras</h3>
                    </div>
         
         
            <div class="dflx">

         <div class="btnfiltro btn1 dflx"   @click="filtFecha()"> <div class="txtb"> FECHA</div> <img src="../../assets/filtrar.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtActivas()"> <div class="txtb">ACTIVAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
          <div class="btnfiltro btn2 dflx" @click="Todas()"> <div class="txtb">TODAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtConcluidas()"> <div class="txtb">CONCLUIDAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
         

          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img  src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>

          <div v-if="historyOption =='Default'" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>
        </div>



         <div class="dflx buscador-fecha" >
              <label for="fecha1" class="fecha_search">Selecciona el rango de fecha</label>
              <input  v-model="fecha2" type="date"  name="fecha2"  > <!-- :max="day()" -->
                <label for="fecha1" class="fecha_search"> a</label>
              <input  v-model="fecha1" type="date"  name="fecha1"  > <!-- :max="day()" -->
              <button @click="actualizar(fecha1, fecha2)">Buscar</button>
        </div>
        

        <!-- Tabbla-->

        <!-- Tabla admin -->

        <div  v-if="identity.role =='legrafica' || identity.role =='admin' || identity.role =='spadmin'" class="tbl_clientes">
            <div class="tbl_header">
                <div class="tblh th1">
                  <p>FECHA</p>
                </div>
                <div class="tblh th1">
                  <p>HORA</p>
                </div>
                <div class="tblh th1">
                  <p>No. de MUESTRA</p>
                </div>
                <div class="tblh th2">
                  <p>Nombre del Cliente</p>
                </div>
               
                 <div class="tblh th4">
                  <p>DETERMINACIONES</p>
                </div>
                
                  <div class="tblh th6">
                  <p>CLAVE</p>
                </div>
                 <div class="tblh th7">
                 
                </div>
                
               
            </div>
            
            <div class="tbl_content" >
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error' && data != ''">
                    <div class="tbl_items" v-for="(item, key) in filteredItems" :key="key">
                        
                        <div v-if="identity.role != 'spadmin'">
                            <div v-if="item.servicio.forma =='1'" class="tbl_items" >
                                <div   class="tbl_item it1"> 
                                    <p>{{convertdate(item.servicio.fecha)}}</p>
                                </div>
                                <div   class="tbl_item it1"> 
                                    <p>{{item.servicio.hr}}</p>
                                </div>
                                <div   class="tbl_item it1"> 
                                    <p>{{item.num}}</p>
                                </div>
                                
                                <div   class="tbl_item it2"> 
                                    <p v-if="item.servicio.id_sucursal !=null && item.servicio.sucursal.name ">{{item.servicio.sucursal.name}}</p>
                                    <p v-else>{{item.servicio.cliente.name}}</p>
                                </div>
                               
                                <div   class="tbl_item it4"> 
                                    <p>{{determinaciones(item.resultados)}}</p>
                                </div>
                               
                                <div   class="tbl_item it6"> 
                                    <p>{{item.servicio.clave}}</p>
                                </div>
                                <div  class="tbl_item it7" @click="showModal(item)"> 
                                    <img src="../../assets/editar.png" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- spadmin -->
                        <div v-else>
                            <div  class="tbl_items" v-if="item.servicio.forma =='2'" > 
                                 <div   class="tbl_item it1"> 
                                    <p>{{convertdate(item.servicio.fecha)}}</p>
                                </div>
                                <div   class="tbl_item it1"> 
                                    <p>{{item.servicio.hr}}</p>
                                </div>
                                <div   class="tbl_item it1"> 
                                    <p>{{item.num}}</p>
                                </div>
                                
                                <div   class="tbl_item it2"> 
                                     <p v-if="item.servicio.id_sucursal !=null && item.servicio.sucursal.name"> {{item.servicio.sucursal.name}} </p>
                                     <p v-else>{{item.servicio.cliente.name}}</p>
                                </div>
                               
                                <div   class="tbl_item it4"> 
                                    <p>{{determinaciones(item.resultados)}}</p>
                                </div>
                               
                                <div   class="tbl_item it6"> 
                                    <p>{{item.servicio.clave}}</p>
                                </div>
                                <div  class="tbl_item it7" @click="showModal(item)"> 
                                    <img src="../../assets/editar.png" alt="">
                                </div>
                            </div>
                        </div>
                       
                      
                       
                    </div>
                </div>  
                <div v-else>
                    <p class="snp2">Aun no hay registros.</p>
                </div>
            </div>
        </div> <!--- FIN tabla --->


        <div v-if="identity.role == 'legrafica'  || identity.role == 'admin' || identity.role == 'spadmin' " class="text_option txt1 ttop">
                           
                <h3>Vista Analista</h3>
        </div>

         <!-- Tabla analista -->
        <div  class="tbl_clientes_analista" > <!--v-if="identity.role != 'analista'"  -->
                 <div class="tbl_header">
                <div class="tblh th1">
                  <p>FECHA</p>
                </div>

                 <div class="tblh th8">
                  <p>Clave <br> LAASON</p>
                </div>
                 <div class="tblh th1">
                  <p>No. de MUESTRA</p>
                </div>
               
               
               
                 <div class="tblh th2">
                  <p>DETERMINACIONES</p>
                </div>

                <div class="tblh th10">
                  <p>INICIALES DE <br> ANALISTA</p>
                </div>

                  <div class="tblh th10">
                  <p>INICIALES DE <br> LIBERACION</p>
                </div>

              
                 <div class="tblh th11">
                  <p>SUPERVISÓ</p>
                </div>

             
                 <div class="tblh th30">
                   <p>GUARDAR <br> CAMBIOS</p>
                </div> 
                 <div class="tblh th30">
                   <p>OBSV</p>
                </div> 
                
               
            </div>
          
            <div class="tbl_content">
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error' && data != ''">
                    <div  v-for="(item, key) in filteredItems" :key="key">
                            <div v-if="identity.role != 'spadmin'">
                                <div v-if="item.servicio.forma =='1'">
                                       <div  class="tbl_items">
                                            <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it1 _cp"> 
                                            <p>{{convertdate(item.servicio.fecha)}}</p>
                                        </div>

                                        <div @click="changeView('Resultados',item)" :class="{am:item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it8 _cp"> 
                                            <p>{{item.servicio.clave}}</p>
                                        </div>
                                        <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }" class="tbl_item it1"> 
                                            <p>{{item.num}}</p>
                                        </div>
                                        
                                    
                                       

                                        
                                        <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it9 _cp"> 
                                            <p>{{determinaciones(item.resultados)}}</p>
                                        </div>

                                    <select  class="it10" name="f_analista" id="" v-model="item.f_analista" :disabled=" (( item.f_liberacion != null && item.f_liberacion != '') || (item.superviso != null && item.superviso != '') )&& (identity.role !='admin' && identity.role !='spadmin' && identity.role !='legrafica')">
                                                <option
                                                        v-for="(an, key) in analistas"
                                                        v-bind:key="key"
                                                        v-bind:value="an.ini"
                                                        :disabled="an.ini != identity.ini && identity.role =='analista'"
                                                    >
                                                        {{ an.ini}}
                                                    </option>
                                        </select>

                                    <select  :disabled="(item.f_analista==null || item.f_analista=='' || (item.superviso != null && item.superviso != '') || (item.f_analista !=null || item.f_analista !='') ) && (identity.role !='admin' && identity.role !='spadmin' && identity.role !='legrafica')" class="it10" name="f_liberacion" id="" v-model="item.f_liberacion">
                                                <option
                                                        v-for="(an, key) in analistas"
                                                        v-bind:key="key"
                                                        v-bind:value="an.ini"
                                                        :disabled="an.ini != identity.ini && identity.role =='analista'"
                                                    
                                                    >
                                                        {{ an.ini}}
                                                    </option>
                                        </select>
                                    <div :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }" class="tbl_item it11"> 
                                            <p>  <span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                                        </div>
                                    
                                    <div class="tbl_item it30">
                                        <div v-if="item.f_liberacion == null ||  item.f_liberacion == '' || identity.role =='admin' || identity.role =='spadmin' || identity.role =='legrafica'"   @click="editarFirma(item.id, item.f_analista, item.f_liberacion, item)" > <!-- -->
                                            <img  src="../../assets/Save.png" alt="">
                                        </div> 
                                    </div>
                                      <div  class="tbl_item it7" @click="showModal(item)"> 
                                        <img src="../../assets/editar.png" alt="">
                                    </div>
                                    
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="item.servicio.forma =='2'" > 
<div  class="tbl_items">
                                            <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it1 _cp"> 
                                            <p>{{convertdate(item.servicio.fecha)}}</p>
                                        </div>

                                        <div @click="changeView('Resultados',item)" :class="{am:item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it8 _cp"> 
                                            <p>{{item.servicio.clave}}</p>
                                        </div>
                                        <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }" class="tbl_item it1"> 
                                            <p>{{item.num}}</p>
                                        </div>
                                        
                                    
                                   

                                        
                                        <div @click="changeView('Resultados',item)" :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }"  class="tbl_item it9 _cp"> 
                                            <p>{{determinaciones(item.resultados)}}</p>
                                        </div>

                                    <select  class="it10" name="f_analista" id="" v-model="item.f_analista" :disabled=" (( item.f_liberacion != null && item.f_liberacion != '') || (item.superviso != null && item.superviso != '') )&& (identity.role !='admin' && identity.role !='spadmin' && identity.role !='legrafica')">
                                                <option
                                                        v-for="(an, key) in analistas"
                                                        v-bind:key="key"
                                                        v-bind:value="an.ini"
                                                        :disabled="an.ini != identity.ini && identity.role =='analista'"
                                                    >
                                                        {{ an.ini}}
                                                    </option>
                                        </select>

                                    <select  :disabled="(item.f_analista==null || item.f_analista=='' || (item.superviso != null && item.superviso != '') || (item.f_analista !=null || item.f_analista !='') ) && (identity.role !='admin' && identity.role !='spadmin' && identity.role !='legrafica')" class="it10" name="f_liberacion" id="" v-model="item.f_liberacion">
                                                <option
                                                        v-for="(an, key) in analistas"
                                                        v-bind:key="key"
                                                        v-bind:value="an.ini"
                                                        :disabled="an.ini != identity.ini && identity.role =='analista'"
                                                    
                                                    >
                                                        {{ an.ini}}
                                                    </option>
                                        </select>
                                    <div :class="{am: item.f_analista == null ||  item.f_analista  =='' || item.f_liberacion == null || item.f_liberacion == '' }" class="tbl_item it11"> 
                                            <p>  <span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                                        </div>
                                    
                                    <div class="tbl_item it30">
                                        <div v-if="item.f_liberacion == null ||  item.f_liberacion == '' || identity.role =='admin' || identity.role =='spadmin' || identity.role =='legrafica'"   @click="editarFirma(item.id, item.f_analista, item.f_liberacion, item)" > <!-- -->
                                            <img  src="../../assets/Save.png" alt="">
                                        </div> 
                                    </div>
                                      <div  class="tbl_item it7" @click="showModal(item)"> 
                                    <img src="../../assets/editar.png" alt="">
                                </div>
                                    
                                    </div>
                                </div>
                            </div>
                       
                       
                      
                       
                    </div>
                   
                </div>  
                <div v-else>
                    <p class="snp">Aun no hay registros.</p>
                </div>
            </div>
        </div>




        </div> <!--- FIN opcion default--->


        <div v-if="historyOption =='Resultados'">
                    <div class="text_option txt1">
                            <h3>Muestra {{added.num}}</h3>
                           <!-- <h3>{{added.servicio.clave}}</h3> -->
                    </div>

                     <div  v-if="identity.role =='legrafica' || identity.role =='admin' || identity.role =='spadmin' || identity.role =='analista'" class="">
            <div class="tbl_header2">
                <div class="tblh th12">
                  <p>FECHA DE <br> RECEPCIÓN</p>
                </div>
                
             

                <div class="tblh th14">
                   <p>ANALISTA</p>
                </div>
                 <div class="tblh th14">
                   <p>LIBERACIÓN</p>
                </div>
                
                
               
            </div>
            
            <div class="tbl_content" >
                <div >
                    <div class="tbl_items">
                        <div   class="tbl_item it12"> 
                               <p>{{convertdate(added.servicio.fecha)}}</p>
                        </div>
                     
                           
                      
                      

                         <div  class="tbl_item it14"> 
                               <p > <span v-if="added.f_analista != null && added.f_analista != ''" > {{added.f_analista}} </span> <span v-else> - </span> </p>
                              
                        </div>
                         <div  class="tbl_item it14"> 
                              <p > <span v-if=" added.f_liberacion != null && added.f_liberacion != ''" > {{added.f_liberacion}} </span> <span v-else> - </span> </p>
                        </div>
                        
                       
                      
                       
                    </div>
                </div>  

                <form @submit.prevent="
                     enviarValidacion(
                        added
                    )
                ">
            <div class="dflx mthr">
                <div class="tblh th15">
                  <p>FECHA DE <br>INICIO</p>
                </div>
                
                <div class="tblh th15">
                  <p>FECHA DE <br> FINALIZACIÓN</p>
                </div> 
                 <div class="tblh th15">
                  <p>FECHA DE <br> LIBERACIÓN</p>
                </div> 
            </div>
     
            <div>
                <div >
                      <input class="in15" v-model="added.fecha_i" type="date" :required="added.fecha_i=='' || added.fecha_i==null">
                     <input  class="in15" v-model="added.fecha_f" type="date" :min="added.fecha_i" > <!-- :required="added.fecha_f=='' || added.fecha_f==null" -->
                      <input disabled  class="in15" v-model="f3" type="text">
                </div>
              
               <div >
                    <input disabled  class="in15" v-model="f1" type="text" >
                     <input  disabled  class="in15" v-model="f2" type="text" >
                      <input disabled  class="in15" v-model="f5" type="text">
               </div>
             <!--  <p v-if="added.fecha_i != null && added.fecha_i !=''" class="editarfechas" @click="editfechas()">Editar fechas</p> -->
            </div>
            
           <div class="dflx mthr">
              <div class="tblh th19">
                  <p>PERFIL</p>
                </div>
                 <div class="tblh th19">
                  <p>TIPO MUESTRA</p>
                </div>
                 <div class="tblh th19">
                  <p>MÉTODO</p>
                </div>
                <div class="tblh th16">
                  <p>DETERMINACIONES</p>
                </div>
                  <div class="tblh th17">
                  <p>RESULTADOS</p>
                </div>
              
                <div class="tblh th19">
                  <p>REFERENCIA</p>
                </div>
                
              
            </div>
            <div v-for="(res , key) in added.resultados" :key="key" class="dflx">
                <input v-model="res.profile.name" id="sit18" disabled type="text">
                <input v-model="res.profile.tipo_muestra" id="sit18" disabled type="text">
                      <input v-model="res.profile.metodo" id="sit18" disabled type="text">
                      <input v-model="res.pname" class="it16" disabled type="text">
                      <input v-model="res.resultado" class="it17" type="text" > <!-- required-->
                      <select v-model="res.uni" id="sit17" ><!-- required-->
                          <option
                                        v-for="(uni, key) in res.luni"
                                        v-bind:key="key"
                                        v-bind:value="uni"
                                    >
                                        {{uni}}
                                    </option>
                      </select>
                       
                       
                      <select v-model="res.ref" id="sit18" > <!-- required-->
                          <option
                                        v-for="(re, key) in res.lp"
                                        v-bind:key="key"
                                        v-bind:value="re"
                                    >
                                        {{re}}
                                    </option>
                      </select>
            </div>

<div class="dflx">
        <p @click="wait()" class="regresar">Regresar</p>
       <button class="validar" type="submit"> <span v-if="added.f_liberacion != null && added.f_liberacion !=''">Enviar a <strong>validación</strong></span> <span v-else> Enviar  <strong>resultados</strong></span></button>
</div>
               



            </form>
               
            </div>
        </div> <!--- FIN tabla --->


                   
         
        </div>






       
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        
   
        
      




         <div v-if="viewModal" class="modal_view">
           
            <div id="modal_forms">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            
          
           
            <div class="body_modal">
                 





                <form @submit.prevent="
                    editar2(
                        mtra.extra.id,
                       
                         mtra.extra.obv,
                        
                    )
                "> 
                  
                  
                  
                    <div>
                        <p  class="obsrv">OBSERVACIONES</p>
                          <input  class="int5" v-model="mtra.extra.obv" type="text"  required  />
                    </div>


                    <div class="modal_btns dflx">
                        <button class="guardar" type="submit">Guardar</button>
                    </div>
                  

                       
                </form>

                <!-- {{mtra}}  -->

            </div>
            
            <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
            </div>


            <div class="modal_btns2 dflx">
                <p @click="closeModal()" class="cancelar">Cancelar</p> <!-- <p  @click="eliminar()" class="eliminar">eliminar</p>-->
            </div>
          </div>
        </div>

        <!-- fin modal -->





         <div v-if="viewModal2" class="modal_view"> 
           
              <div id="modal_val">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_val"> <span>Enviado</span> correctamente </h3>
           
            <div class="modal_val_btns dflx">
                 <p  @click="back()" >INICIO</p>
            </div>
          </div>
        </div> 

        

    </div>
</template>
<script>




import {mapActions} from 'vuex';
import dayjs from 'dayjs'
export default {
    name: 'Muestras',
    components:{
       
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           updsearchcli:'',
           currentPage: 1,
           itemsPerPage:   12,


           viewModal:false,
           itemDel:'',
           mtra:'',

           viewModal2:false,
           

           fanalista:'',
           fliberacion:'',
           
           editfecha:false,
           fecha_search:'',

           f1:'',
           f2:'',
           f5:'',

              fecha1:'',
            fecha2:'',
       }
   },
   created(){

     let day1 =  localStorage.getItem('fecha1')
            let day2 =  localStorage.getItem('fecha2')

            if(!day1 || !day2){
             day1 =  dayjs().format('YYYY-MM-DD')
             day2 =  dayjs().subtract(7,'days').format('YYYY-MM-DD')

              localStorage.setItem('fecha1',day1)
              localStorage.setItem('fecha2',day2)
           
 
            }
            
            this.fecha1 =day1
            this.fecha2 =day2


    let payload ={
        option:'muestras_b_rg',
        data:{
            fecha1:this.fecha1,
             fecha2:this.fecha2
        }
    }
    this.getAllRangeMtr(payload)


       //this.getAllInfoMtr('muestras')
       this.getIdentity(),
       this.getAllInfoUsr('usuarios')
             let buscador = localStorage.getItem('buscador')
       if(buscador != undefined && buscador != null && 	buscador != ''){
         this.search_value = buscador
       }
   },
  
   watch:{
     search_value(value){
           localStorage.setItem('buscador', value)
     }
   },

    computed:{
        data() {
           let data = this.$store.getters["muestras/data"]
           let count =0
      



            let array =[]
            if(data != 'No se encontraron coincidencias.' && data != 'error'){
                if(this.identity.role == 'spadmin'){
                    for (const item of data) {
                        if(item.servicio.forma =='2'){
                            if(this.search_value !='' ){
                              if(this.includesItem(this.search_value, item.servicio.cliente.name)){
                               
                                array.push(item)
                              }
                                
                            }else{
                               
                                array.push(item)
                            }  
                        }
                    }

                    data = array
                }else{
                    for (const item of data) {
                        if(item.servicio.forma=='1'){
                            if(this.search_value !='' ){
                              if(this.includesItem(this.search_value, item.servicio.cliente.name)){
                               
                                array.push(item)
                              }
                                
                            }else{
                               
                                array.push(item)
                            }  
                        }
                    }

                    data = array
                }
            }

            
             return data ;

        },
        historyOption(){
            return this.$store.getters["muestras/getHistoryOption"]
        },
     
        analistas() {
            let data = this.$store.getters["usuarios/data"];
            if(!Array.isArray(data)) return [];
            
          
            let arr = data.map(data => {return data.ini})

            if(Array.isArray(this.data)){
                for (const item of this.data) {
                       if(!arr.includes(item.f_analista) ) arr.push(item.f_analista);
                       if(!arr.includes(item.f_liberacion) )  arr.push(item.f_liberacion);
                      if(!arr.includes(item.superviso) ) arr.push(item.superviso);
                }
            }

            let newarr = arr.map(item=>{
                return {ini:item}
            })
           
    

             return newarr
        }, 
         added(){
             let added = this.$store.getters["muestras/getAdded"]
            
         
             if(added.fecha_i != null && added.fecha_i != ''){
                   this.f1 = this.convertdate(added.fecha_i)
             }else{
                 this.f1 =''
             }
            
          
             if(added.fecha_f != null && added.fecha_f != ''){
                   this.f2 = this.convertdate(added.fecha_f)
             }else{
                 this.f2 =''
             }
             
             
             if(added.fecha_l != null && added.fecha_l != ''){
                 this.f3 = this.convertdate(added.fecha_l)
             }else{
                 this.f3 =''
             }

                if(added.resultados){
                     for(var i=0; i<added.resultados.length;i++){
                      
                            !added.resultados[i].ref  ? added.resultados[i].ref = added.resultados[i].lp[0]  :added.resultados[i].ref
                            !added.resultados[i].uni  ? added.resultados[i].uni = added.resultados[i].luni[0]  :added.resultados[i].uni
                      }
                }
            
              
            return added
        },


        



          //Paginacion
        allItems () {
               let data = this.data
            data == 'No se encontraron coincidencias.' || data == 'error' ? data =[] : data
            return Object.keys(data).map(pid => data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage = this.page()
                }
        return total
       },
       identity() {
             return this.$store.getters['admin/getIdentity'];
       }
    },
   
   methods:{
         ...mapActions('muestras', ['setHistoryOptionMtr']),
          ...mapActions('muestras', ['setHistoryIndexMtr']),
         ...mapActions('muestras', ['setAddedMtr']),
         ...mapActions('muestras', ['getAllInfoMtr']),
           ...mapActions('muestras', ['getAllRangeMtr']),
          ...mapActions('muestras', ['getInfoByIdMtr']),
         ...mapActions('usuarios', ['getAllInfoUsr']),

         includesItem(search, name){
              search = search.trim()
                name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if(search ==''){
                    return true
                }
                name = name.toLowerCase()
                search= search.toLowerCase()
                if(search ==''){
                    return false;
                }
                return name.includes(search)
        },


         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
        page(){
            let num = localStorage.getItem('page_muestras');
            isNaN(num) || num == undefined || num == '' || num == null ?  num = 1 : num
            return num
        },
        editfechas(){
            if( this.editfecha == true){
                this.editfecha = false;
            }else{
                this.editfecha = true;
            }
        },
         showModal: function(item){
             this.mtra = item
             this.viewModal=true
         },
          showModal2: function(){
             this.viewModal2=true
         },
         closeModal:function(){
             this.viewModal=false;
              this.viewModal2=false;
             this.mtra ='';
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
         back:function(){
        setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,3000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
      delStatus2: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           
            
        },
         enviarValidacion: async function(muestra ){
           

    if(muestra.extra.pdfenviado =='no'){
        if(muestra.f_analista != null && muestra.f_analista != ''){
                   if(muestra.superviso == null || muestra.superviso == ''){
                       if(muestra.f_liberacion == null || muestra.f_liberacion  ==''){
                            let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/resultados', item: muestra});
                                        
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                            this.delStatus2()
                        }else{ // success
                             

                             //! liberar solo si todo esta lleno 
                             let resultados = muestra.resultados;
                             let vacia = false;
                            for(var i =0; i< resultados.length; i++){

                                if(resultados[i].resultado == '' || resultados[i].resultado == null || resultados[i].resultado == undefined ){
                                    vacia = true
                                }
                            
                            }


                             if(vacia == false){
                                 
                                let data ={
                                    id:muestra.id,
                                    fliberacion: this.identity.ini
                                } 
                                    let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/fl', item: data});
                                                
                                    if(result.status=='error'){
                                        this.status='error'
                                        this.message= result.message
                                    }else{ // success
                                    this.showModal2()
                                    }
                             }else{
                                 this.showModal2()
                             }



                        

                           
                           
                        } 
                       }else if(this.identity.role != 'analista'){
                                let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/resultados', item: muestra});
                                        
                                if(result.status=='error'){
                                    this.status='error'
                                    this.message= result.message
                                }else{ // success
                                this.showModal2()
                                }
                       }else{
                           
                           this.status='error'
                          this.message= 'La muestra no puede ser modificada una vez que fue liberada.'
                          this.delStatus2()
                       }
                   }else{//se superviso pero es un admin
                      if(this.identity.role != 'analista'){
                                let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/resultados', item: muestra});
                                        
                                if(result.status=='error'){
                                    this.status='error'
                                    this.message= result.message
                                }else{ // success
                                this.showModal2()
                                }
                       }else{
                            
                           this.status='error'
                          this.message= 'La muestra no puede ser modificada una vez que fue liberada.'
                          this.delStatus2()
                       }
                   }
                   
               }else{
                     this.status='error'
                     this.message= 'Debes firmar antes de enviar a validación.'
                     this.delStatus2()
               }
            }else{
                     this.status='error'
                     this.message= 'La muestra no se puede modificar una vez que ha sido enviado el pdf.'
                     this.delStatus2()
               }
               
               
        },



        editar2: async function (id,  obv){
                 let data ={
                   "id" :id, 
                 
                    "obv": obv,
                   
                }

                let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/extra', item: data});
                                        
                if(result.status=='error'){
                    this.status='error'
                    this.message= result.message
                }else{ // success
                    this.viewModal=false;
                     this.mtra ='';
                } 
        },


        editarFirma: async function (id, fanalista, fliberacion, muestra){

            if(fanalista =='' && fliberacion ==''){
                    this.status='error'
                    this.message= 'Seleccione una firma.'
                    this.delStatus();
                }else{
                    if(fanalista != '' && fanalista != null){
                        
                        /** hacemos el update de la firma del analista */
                        let data ={
                            id:id,
                            fanalista: fanalista
                        }
                        let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/fa', item: data});
                                        
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.wait()
                        } 
                    }

                     if(fliberacion != '' && fliberacion != null){
                        /** hacemos el update de la firma de liberación */
                        if(muestra.fecha_i==null || muestra.fecha_i==''){ //sin resultados capturados
                             this.status='error'
                            this.message= 'Captura los resultados antes de firmar la liberación.'
                            this.delStatus();
                        }else{
                             let data ={
                            id:id,
                            fliberacion: fliberacion
                         }
                            let result = await this.$store.dispatch("muestras/editItemMtr",  {option:'muestras_b/fl', item: data});
                                        
                            if(result.status=='error'){
                                this.status='error'
                                this.message= result.message
                            }else{ // success
                               this.wait()
                            }
                        }
                        
                    }
                } 
                
        },



        filtConcluidas(){
            this.getAllInfoMtr('muestras_b/concluidas')
        },

         filtActivas(){
             this.getAllInfoMtr('muestras_b/activas')
        },  

        
         Todas(){
             this.getAllInfoMtr('muestras_b')
        },  

         filtFecha(){
             this.getAllInfoMtr('muestras_b/fecha')
        },
        day(){
            let day = dayjs().format('YYYY-MM-DD')
            return day
        },
        buscarFecha: async function(fecha){
            if(fecha ==undefined || fecha =='' || fecha ==null){
                      this.status='error'
                      this.message= 'Fecha no valida.'
                       this.delStatus();
            }else{
                  let data ={
                    id:fecha,
                    option:'muestras_b/buscar/fecha'
                 }
                this.getInfoByIdMtr(data)
            }
           
        },


         actualizar: async function(fecha1, fecha2){
            this.fecha1 =fecha1
            this.fecha2 = fecha2

 localStorage.setItem('fecha1',fecha1)
              localStorage.setItem('fecha2',fecha2)
            let payload ={
                option:'muestras_b_rg',
                data:{
                    fecha1:this.fecha1,
                    fecha2:this.fecha2
                }
            }
            this.getAllRangeMtr(payload)
        },

        
      
         changeView(option, item){
             this.item=item
             this.setAddedMtr(item)
             this.setHistoryOptionMtr(option)
         },



         // botones de paginacion.
        next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
                localStorage.setItem('page_muestras', this.currentPage)
          }
         
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
            localStorage.setItem('page_muestras', this.currentPage)
          
          }
            
        },


        convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
        determinaciones(resultados){
            let str =''
            if(resultados ==''){
                return str
            }
            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pabr
                }else{
                    str = str + ',' + resultados[i].pabr
                }
            }

            return str
        },
          determinaciones2(resultados){
            let str =''

            if(resultados ==''){
                return str
            }

            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pname
                }else{
                    str = str + ',' + resultados[i].pname
                }
            }

            return str
        }
   }

    
 
}
</script>

<style scoped>

 p.obsrv{
        background: #007E94;
        height: 2.2916666666666665VW;
         font-size: 0.625VW;
        color: white;
        font-weight: 700;
          text-align: center;
        padding-top: .6vw;
        margin-bottom: 0vw;
    }
     input.int5 {
        width: 24.1VW; 
    }

.buscador-fecha{
    margin-top: 1vw;
}
.buscador-fecha label{
    color: #868686;
    font-weight: 400;
    padding-top: .2vw;
    margin-right: .3vw;
}
.buscador-fecha input{
    width: 9vw;
}
.buscador-fecha button{
   
   background: #007E94;
   width:5.6vw;
   margin-left: .3vw;
    border: 0px;
    
    font-size: 0.625vw;
    font-weight: 700;
    color: white;
   
}

p.editarfechas{
    color: #868686;
    cursor: pointer;
    font-style: italic;
    margin-top: .5vw;
    margin-left: .5vw;
}

._cp{
    cursor: pointer;
}
.ttop{
    margin-top: 5vw;
}

.am{
    background: #FFF0BA !important;
}
 #Muestras{
        margin-top: 5.288020833333333VW;
        margin-left: 2.427083333333334VW;
    }

    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
    margin-bottom: 2.083333333333333VW;
  }

     .buscador {
    display: flex;
    margin-right: 2.7vw;
    margin-left: 5vw;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
      background: #FFFFFF
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }

   /** TABLA */
    .tbl_clientes{
            width: 48.4VW;
        margin-top: 1.5625VW;
    }
    .tbl_clientes_analista{
         width: 55.5VW;
        margin-top: 1.5625VW;
    }

    p.snp{
         width: 54.9VW;
          height: 2.2916666666666665VW;
          text-align: center;
          padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }
     p.snp2{
        width: 54.9VW;
        height: 2.2916666666666665VW;
        text-align: center;
        padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
        font-size: 0.7375VW;
    }
 .tbl_header2{
       display: flex;
 }
      
    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
       
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: 1VW;
         background: #F8F8F8;
         border: 1px solid white; 
         overflow: auto;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
      
    }
    
   
   
    

    .th1{
        width: 5.15625VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th1 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it1{
          width: 5.15625VW;
          padding-left: 0vw;
    }.it1 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
        text-align: center;
        
    }

    .th2{
        width: 16.25VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th2 p{
        font-size: 0.9375VW;
        font-weight: 700;
        padding-top: 0.46875VW;
    }

    .it2{
          width: 16.25VW;
          padding-left: 0vw;
    }.it2 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 700;
        padding-left: 1.0416666666666665VW;
        
    }
    .it9{
           width: 16.25VW;
          padding-left: 0vw;
    }.it9 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 400;
        padding-left: 1.0416666666666665VW;
        
    }

     .th3{
        width:6.822916666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th3 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it3{
          width:6.822916666666666VW;
          padding-left: 0vw;
    }.it3 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
      padding-left: 1.0416666666666665VW;

    }

     .th4{
        width:8.802083333333334VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th4 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it4{
          width:8.802083333333334VW;
          padding-left: 0vw;
    }.it4 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
      padding-left: 1.0416666666666665VW;

    }
       .th5{
        width:8.802083333333334VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th5 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.2875VW;
    }

    .it5{
          width:8.802083333333334VW;
          padding-left: 0vw;
    }.it5 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 700;
         text-align: center;

    }

       .th6{
        width:5.572916666666667VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th6 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.6875VW;
    }

    .it6{
          width:5.572916666666667VW;
          padding-left: 0vw;
    }.it6 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 700;
         text-align: center;

    }

    .th7{
        width:2.3vW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }

     .th30{
        width:2.34375VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background: #868686;
    }.th30 p{
        font-size: 0.3645833333333333VW;
        padding-top: .6vw;
    }

     .it30{
          width:2.34375VW;
          padding-left: .6vw;
    }.it30 img{
       width: 0.9375VW;
       height: 0.8854166666666666VW;
       cursor: pointer;
    }


    .it7{
          width:2.3vW;
          padding-left: .3vw;
    }.it7 img{
       width: 0.9375VW;
       height: 0.8854166666666666VW;
       cursor: pointer;
    }


    .th8{
        width: 5.15625VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th8 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

    .it8{
          width: 5.15625VW;
          padding-left: 0vw;
    }.it8 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
        text-align: center;
        
    }

    .th10{
        width: 5.46875VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th10 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

     .it10{
          width:  5.46875VW;
          padding-left: 0vw;
    }.it10 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
        text-align: center;
        
    }
   
    select.it10{
        width: 5.46875VW;
        height: 2.2916666666666665VW;
        padding-left: 0vw;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }

     .th11{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th11 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it11{
          width:8.229166666666666VW;
          padding-left: 0vw;
    }.it11 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }


     .th12{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background: #868686 !important;
    }.th12 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

    .it12{
          width:8.229166666666666VW;
          padding-left: 0vw;
    }.it12 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
       padding-left: 0;
       text-align: center;

    }

    .th13{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th13 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it13{
          width:8.229166666666666VW;
          padding-left: 0vw;
          
    }.it13 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }

    .th14{
        width:15.572916666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th14 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it14{
          width:15.572916666666666VW;
          padding-left: 0vw;
          
    }.it14 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }
   

    .th15{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th15 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

    .it15{
          width:8.229166666666666VW;
          padding-left: 0vw;
          
    }.it15 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }

    input.in15{
         width:8.229166666666666VW;
         height: 1.6145833333333335VW;
    }

    .mthr{
        margin-top: 1vw;
    }


    .th16{
            width:18.229166666666664VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th16 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    input.it16{
        width:18.229166666666664VW;
  height: 1.6145833333333335VW;
    }

     .th17{
            width:15.572916666666666VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th17 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }
    select#sit17{
        width: 7.19125VW;
    }
     select#sit18 , input#sit18{
        width: 8.2VW;
    }
    input#sit18{
        overflow: auto;
    }
   input.it17{
         height: 1.6145833333333335VW;
         width: 8.333333333333332VW;
    }
       .th18{
            width:12.968750000000002VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th18 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    select#it18{
        width:12.968750000000002VW;
         height: 1.6145833333333335VW;
       background: #F8F8F8;
        
    }

       .th19{
            width:8.229166666666666VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th19 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

  
.it19{
          width:8.229166666666666VW;
          padding-left: 0vw;
           padding-top: 0.1vw;
           height: 1.6145833333333335VW;
          
    }.it19 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;
      

    }
    button.validar{
        background: #007E94;
        color: white;
        border: none;
        width: 10.15625VW;
        height: 2.2916666666666665VW;
        margin-top:  2.2916666666666665VW;
    }

    p.regresar{
         background: #868686;
        color: white;
        border: none;
        cursor: pointer;
        width: 10.15625VW;
        text-align: center;
        padding-top: .6vw;
        height: 2.2916666666666665VW;
        margin-top:  2.2916666666666665VW;
        margin-right: .5vw;
    }
   /** FIn tabla */



/**MODAL forms */
 #modal_forms{
        background: white;
        width: 35%;
        height: 25.85416666666667VW;
        position: absolute;
        top: 10%;
        right: 33%;
        padding: 0.8854166666666666VW;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        overflow: auto;
    }
    p.close_modal{
        color: #868686 ;
        text-align: right;
        cursor: pointer;
        font-size: 2vw;
        font-family: var(--Sans);
        font-weight: 300;
        top: 0vw;
        right: 1vw;
        position: absolute;
    }


    .header_modal{
        margin-left: 2.489583VW;
        width: 27.65625VW;
        margin-top: 1.7708333333333333VW;
    }
    p.textmodal{
        font-weight: 400;
        font-size: 0.729166VW;
        color: #868686;
        margin-bottom: 0vw;
    }
    p.textdata{
        font-weight: 700;
        font-size: 0.6770834VW;
        color: #868686;
        margin-bottom: 0.989583VW;
    }


    .body_modal{
          width: 24.114583VW;
    margin-left: 4.364583VW;
    margin-top: 5vw;
    }

    .hrd{
        background: #009084;
        height: 2.2916666666666665VW;
    }.hrd p{
        font-size: 0.625VW;
        color: white;
        font-weight: 700;
        width: 50%;
        margin-bottom: 0vw;
        text-align: center;
        padding-top: .2vw;
    }.hrd p:first-child{
        border-right: 0.052083333333333336VW solid var(--color-5);
        padding-top: .6vw;
    }

    p.dtr{
        background: #009084;
        height: 2.2916666666666665VW;
         font-size: 0.625VW;
        color: white;
        font-weight: 700;
          text-align: center;
        padding-top: .6vw;
        margin-bottom: 0vw;
    }

    p.dtr2{
        background: #F8F8F8;
        margin-bottom: 0vw;
        height: 2.2916666666666665VW;
        padding-top: .2vw;
        padding-left: .4vw;
        font-size: 0.6770834VW;
        color: #868686;
    }


    .hdr2{
         background: #007E94;
        height: 2.2916666666666665VW;
    }.hdr2 p{
        font-size: 0.625VW;
        color: white;
        font-weight: 700;
        width: 50%;
        margin-bottom: 0vw;
        text-align: center;
        padding-top: .2vw;
    }.hdr2 p:first-child{
        border-right: 0.052083333333333336VW solid var(--color-5);
       
    }
     p.apr{
         background: #007E94;
        height: 2.2916666666666665VW;
         font-size: 0.625VW;
        color: white;
        font-weight: 700;
          text-align: center;
        padding-top: .6vw;
        margin-bottom: 0vw;
    }
    input.inapr{
        width: 100%;
    }


    input, select{
        width: 50%;
         border-top: 0.052083333333333336VW solid var(--color-5);
         border-bottom: 0.052083333333333336VW solid var(--color-5);
         height: 1.6145834VW;
    }
    input{
         border-left: 0.052083333333333336VW solid var(--color-5);
          border-right: none;
            color: #868686;
          background: #F8F8F8;
         
    }
    select{
        background: #D3D3D3;
        border-left: none;
        border-right: none;
        text-align: center;
         color: #868686;
         font-weight: 700;
    }select option{
        background: white;
         color: #868686;
        border: 0.052083333333333336VW solid var(--color-5);
    }

   button.aceptar{
       background: #009084;
        color: white;
        border: none;
        font-weight: 700;
        height: 2.2916666666666665VW;
        width: 7.916666666666666VW;
        margin-bottom: 1.5625VW;
   }

   button.guardar{
       background: #007E94;
        color: white;
        border: none;
        font-weight: 700;
        height: 2.2916666666666665VW;
        width: 7.916666666666666VW;
        margin-bottom: 1.5625VW;
   }
    

    p.txt2{
        font-weight: 700;
        font-size: 0.78125VW;
        color: #007E94;
        text-align: center;
        margin-top: 1.7187500000000002VW;
    }

    .tbl2 p{
        background: #007E94;
        font-weight: 700;
        color: white;
        font-size: 0.625VW;
        text-align: center;
        height: 2.2916666666666665VW;
        margin-bottom: 0vw;
    }

    p.t1{
     border-right: 0.052083333333333336VW solid var(--color-5);
        padding-top: .2vw;
        width: 6.5625VW;
    }
    input.int1 {
        width: 6.3625VW; 
    }
    input.int3 {
        width: 6.4625VW; 
    }
    input.int4 {
        width: 6.4625VW; 
    }
     input.int2 {
        width:  4.8VW; 
    }

     p.t2{
          padding-top: .6vw;
     border-right: 0.052083333333333336VW solid var(--color-5);
     width: 4.895833333333333VW;
    }

     p.t3{
             padding-top: .6vw;
         border-right: 0.052083333333333336VW solid var(--color-5);
           width: 6.5625VW;
    }

     p.t4{
             padding-top: .6vw;
              width: 6.5625VW;
     }




   

    .modal_btns2 p{
        background: #007E94;
        color: white;
        border: none;
        font-weight: 700;
        height: 2.2916666666666665VW;
        width: 7.916666666666666VW;
        margin-bottom: 1.5625VW;
        background: #797979;
        font-size: 0.625VW;
        padding-top: .7vw;
        text-align: center;
    }

    p.cancelar{
        margin-right: 0vw; 
    }

    p.eliminar{
        margin-left:  2vw;
    }
    p.cancelar:hover{
        background: var(--color-1)
    }
    p.eliminar:hover{
         background: var(--color-7);
    }

    .modal_btns2{
        justify-content: right;
        margin-top: 3vw;
    }


/** fin modal forms */


    .btnfiltro{
        cursor:pointer;
        width: 7.240vw;
        height: 1.823vw;
        border: 0px;
        margin-right: 0.072vw;
        font-size: 0.625vw;
        font-weight: 700;
        color:white;
        text-align: left;
        padding-top: .4vw;
        padding-left: 0.990vw;
    }
   .btnfiltro .txtb{
        width: 4.6vw;
       
    }
    .btnfiltro img{
        width: 0.885vw;
        height: 0.938vw;
    }
    .btn1{
        background: #009084;
    }
    .btn2{
         background: #007E94;
    }




    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }


 .buscador-fecha{
    margin-top: 1vw;
}
.buscador-fecha label{
    color: #868686;
    font-weight: 400;
    padding-top: .2vw;
    margin-right: .3vw;
    padding-left: .5vw;
    padding-right: .5vw;
}
.buscador-fecha input{
    width: 9vw;
}
.buscador-fecha button{
   
   background: #007E94;
   width:5.6vw;
   margin-left: .3vw;
    border: 0px;
    
    font-size: 0.625vw;
    font-weight: 700;
    color: white;
   
}

</style>

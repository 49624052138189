<template>
    <div id="Sections">

        

        <HeaderSec />
       
   
        <div v-if="historyOption =='Default'">
            <Default/>

        </div>
        <div v-else-if="historyOption =='Usuarios'">
                <Usuarios/>
        </div>
        <div v-else-if="historyOption =='Dashboard'">
                 <Default/>
        </div>
        <div v-else-if="historyOption =='Clientes'">
            <Clientes />
        </div>
        <div v-else-if="historyOption =='Parametros'">
            <Parametros />
        </div>
         <div v-else-if="historyOption =='Perfiles'">
            <Perfiles />
        </div>
         <div v-else-if="historyOption =='Servicios'">
            <Servicios />
        </div>
          <div v-else-if="historyOption =='Muestras'">
            <Muestras />
        </div>
         <div v-else-if="historyOption =='Validaciones'">
            <Validaciones />
        </div>
           <div v-else-if="historyOption =='Solicitudes'">
            <Solicitudes />
        </div>
        <div v-else-if="historyOption =='PDFcreate'">
            <PDFcreate />
        </div>
        <div v-else-if="historyOption =='PDFenglishcreate'">
            <PDFcreate_eng />
        </div>
           <div v-else-if="historyOption =='PDFclient'">
            <PDFclient />
        </div>
        
        <div v-else>
            <div class="proximamente">
                 <Default/>
            </div>       
        </div>

       
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import HeaderSec from '../components/HeaderSec.vue';
import Usuarios from '../views/Usuarios/Usuarios.vue';
import Default  from '../views/Default/Defaul.vue'
import Clientes from '../views/clientes/Clientes.vue'
import Parametros from '../views/params/Parametros.vue'
import Perfiles from '../views/params/profile.vue'
import Servicios from '../views/solserv/SolicitudServicios.vue'
import Muestras from '../views/muestras/Muestras.vue'
import Validaciones from '../views/validaciones/Validaciones.vue'
import Solicitudes from '../views/servicios/Solicitudes.vue'
import PDFcreate from './PDFcreate.vue'
import PDFcreate_eng from './PDFcreate_eng.vue'
import PDFclient from '../components/PDFclient.vue'

export default {
  name: 'Sections',
  components:{
      HeaderSec,
      Default,
      Usuarios,
      Parametros,
      Perfiles,
      Clientes,
      Servicios,
      Muestras,
      Validaciones,
      Solicitudes,
      PDFcreate,
      PDFcreate_eng,
      PDFclient
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
       ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>

<template>
  
    <div id="Solicitudes">
       
      

        <div v-if="historyOption =='Default'">
            <div class="dflx">
      
        
        <div class="btn_añadir" @click="nuevoServicio()">
           <button><img src="../../assets/i_mas.png" alt=""> <span>Nueva</span> Solicitud de Servicios </button>
        </div>

          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img  src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>

          <div v-if="historyOption =='Default'" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>


        </div>

      
         <div class="dflx buscador-fecha" >
              <label for="fecha1" class="fecha_search">Selecciona el rango de fecha</label>
                 <input  v-model="fecha2" type="date"  name="fecha2"  > <!-- :max="day()" -->  
                <label for="fecha1" class="fecha_search"> a</label>
                <input  v-model="fecha1" type="date"  name="fecha1"  > <!-- :max="day()" -->
         
              <button @click="actualizar(fecha1, fecha2)">Buscar</button>
        </div>
        

        <div class="tbl_solserv">
            <div class="tbl_header">
                <div class="tblh hp1">
                  <p>Clave  <br>LAASON</p>
                </div>
                <div class="tblh hp2">
                    <p>Nombre Cliente</p>
                </div>
                  <div class="tblh hp4">
                   <p>Fecha</p>
                </div>
                <div class="tblh hp3">
                  <p>Hora</p>
                </div>
               
                
                 <div class="tblh hoptions">
                    
                </div>
            </div>
         
            <div tbl_content>
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error' && data != ''">
                    <div  v-for="(item, key) in filteredItems" :key="key">
                        
                        <div v-if="identity.role != 'spadmin'">
                            <div v-if="item.forma == '1'" class="tbl_items">
                                <div  class="tbl_item itp1">
                                <p @click="changeView('Muestras_Solicitud', item)">{{item.clave_LAASON}}</p>
                            </div>
                            <div  class="tbl_item itp2">
                                <p @click="changeView('Muestras_Solicitud', item)"> <span v-if="item.id_sucursal !=null && item.sucursal.name">{{item.sucursal.name}}</span> <span v-else>{{item.cliente.name}} </span></p>
                            </div>
                        
                            <div class="tbl_item itp4">
                                <p>{{convertdate(item.fecha)}}</p>
                            </div>
                            <div class="tbl_item itp3">
                                <p>{{item.hr}}</p>
                            </div>
                        
                            <div class="itoptions">
                            <!-- <div class="icon_editar" >
                                    <img src="../../assets/i_editar.png" alt="icono editar"   >
                                </div> -->
                                <div class="icon_eliminar " @click="showModal(item.id)">
                                    <img src="../../assets/i_eliminar.png" alt="icono eliminar"   >
                                </div>
                            </div>

                            
                            <div @click="downloadWithCSS(item)" class="tbloptions tbldwl">
                                <img src="../../assets/Download.svg" alt="">
                                <p>DESCARGAR SOLICITUD</p>
                            </div>
                            </div>
                        </div>
                        <!-- mostrar forma 2 a spadmin  -->
                        <div v-else >
                            <div  class="tbl_items"  v-if="item.forma == '2'"> 
                                        <div  class="tbl_item itp1">
                               <p @click="changeView('Muestras_Solicitud', item)">{{item.clave_LAASON}}</p>
                            </div>
                            <div  class="tbl_item itp2">
                                <p @click="changeView('Muestras_Solicitud', item)">{{item.cliente.name}}</p>
                            </div>
                        
                            <div class="tbl_item itp4">
                                <p>{{convertdate(item.fecha)}}</p>
                            </div>
                            <div class="tbl_item itp3">
                                <p>{{item.hr}}</p>
                            </div>
                        
                            <div class="itoptions">
                                <div class="icon_eliminar " @click="showModal(item.id)">
                                    <img src="../../assets/i_eliminar.png" alt="icono eliminar"   >
                                </div>
                            </div>

                            
                            <div @click="downloadWithCSS()" class="tbloptions tbldwl">
                                <img src="../../assets/Download.svg" alt="">
                                <p>DESCARGAR SOLICITUD</p>
                            </div>
                            </div>
                         </div>
                        
                        
                         
                        

                    </div>
                </div>  
                <div v-else>
                    <p class="snr">Aun no hay registros.</p>
                </div>
            </div>
        </div>

        </div>
        <div v-if="historyOption == 'Muestras_Solicitud'">
          <SolicitudMuestras/>

        </div>
        <div v-if="historyOption == 'MuestraDetalle'">
         <MuestraDetalle/>

        </div>

        <div  v-if="historyOption == 'Add'">
             <AddServicio />
        </div>
       


       
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        

        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_delete">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_modal">Eliminar Solicitud</h3>
            <p class="message_modal">¿Estás seguro que deseas eliminar esta solicitud?, las muestras ligadas a esta solicitud tambien seran eliminadas.</p>
            <div class="modal_btns dflx">
                <p @click="closeModal()" class="cancelar">cancelar</p> <p  @click="eliminar()" class="eliminar">eliminar</p>
            </div>
          </div>
        </div>
        

    </div>
</template>
<script>

import SolicitudMuestras from './SolicitudMuestras.vue'
import MuestraDetalle from './muestrasDetalle.vue';
import AddServicio from '../solserv/addServicio.vue'

import dayjs from 'dayjs'
import {mapActions} from 'vuex';
export default {
    name: 'Solicitudes',
    components:{
       SolicitudMuestras,
       MuestraDetalle,
       AddServicio
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           updsearchcli:'',
           currentPage: 1,
           itemsPerPage:  12,
            fecha1:'',
            fecha2:'',

           viewModal:false,
           itemDel:''
           
       }
   },
   created(){
    
          let day1 =  localStorage.getItem('fecha1')
            let day2 =  localStorage.getItem('fecha2')

            if(!day1 || !day2){
             day1 =  dayjs().format('YYYY-MM-DD')
             day2 =  dayjs().subtract(7,'days').format('YYYY-MM-DD')

              localStorage.setItem('fecha1',day1)
              localStorage.setItem('fecha2',day2)
           
 
            }
            
            this.fecha1 =day1
            this.fecha2 =day2
    let payload ={
        option:'servicios_rg',
        data:{
            fecha1:this.fecha1,
             fecha2:this.fecha2
        }
    }
    this.getAllRangeSrv(payload)
      // this.getAllInfoSrv('servicios')


       this.getIdentity()
       
      let buscador = localStorage.getItem('buscador')
       if(buscador != undefined && buscador != null && buscador != ''){
         this.search_value = buscador
       }

         
       
   },
   
  watch:{
     search_value(value){
           localStorage.setItem('buscador', value)
     }
   },

    computed:{
        data() {
            let data = this.$store.getters["solserv/data"]
            let array =[]
            if(data != 'No se encontraron coincidencias.' && data != 'error'){
                if(this.identity.role == 'spadmin'){
                    for (const item of data) {
                        if(item.forma=='2'){
                            array.push(item)
                        }
                    }

                  
                }else{
                    for (const item of data) {
                        if(item.forma=='1'){
                            array.push(item)
                        }
                    }

                   
                }

                if(this.search_value !=''){
                    let arr = array.filter(item => this.includesItem(this.search_value , item.cliente.name))
                    data = arr
                }else{
                    data = array
                }
            }
             return data ;
        },
        historyOption(){
            return this.$store.getters["solserv/getHistoryOption"]
        },

        
            identity() {
                return this.$store.getters['admin/getIdentity'];
                },


          //Paginacion
        allItems () {
             let data = this.data
            data == 'No se encontraron coincidencias.' || data == 'error' ? data =[] : data
            return Object.keys(data).map(pid => data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
   
   methods:{
         ...mapActions('solserv', ['setHistoryOptionSrv']),
         ...mapActions('solserv', ['setAddedSrv']),
         ...mapActions('solserv', ['getAllInfoSrv']),
          ...mapActions('solserv', ['getAllRangeSrv']),
         ...mapActions('PDF', ['getAllInfoPDF']),
           
           
           ...mapActions('main', ['getInfoById']),
       
       
       
       ...mapActions('main', ['setHistoryOptionMain']),
      ...mapActions('solserv', ['setHistoryOptionSrv']),
       ...mapActions('solserv', ['setHistoryOptionSrv']),
         ...mapActions('PDF', ['setAddedPDF']),
      includesItem(search, name){
                search = search.trim()
                name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if(search ==''){
                    return true
                }
                name = name.toLowerCase()
                search= search.toLowerCase()
                if(search ==''){
                    return false;
                }
                return name.includes(search)
        },

      nuevoServicio: function(){
          this.setHistoryOptionMain('Servicios')
          this.setHistoryOptionSrv('Add')
      },

       getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },

    downloadWithCSS: async function(item) {
        //this.aver.push(cliDat)
        //this.aver.push(estud) 
        
           
       const servicio = await this.getInfoById({
            id: item.id,
            option:'servicios'
        })
        
        if(servicio.id){
             this.setAddedPDF(servicio)
             this.setHistoryOptionMain('PDFclient')
        }
       
          
      },

      actualizar: async function(fecha1, fecha2){
            this.fecha1 =fecha1
            this.fecha2 = fecha2

             localStorage.setItem('fecha1',fecha1)
              localStorage.setItem('fecha2',fecha2)

            let payload ={
                option:'servicios_rg',
                data:{
                    fecha1:this.fecha1,
                    fecha2:this.fecha2
                }
            }
            this.getAllRangeSrv(payload)
      },

         showModal: function(id){
             this.itemDel =id;
             this.viewModal=true
         },
         closeModal:function(){
             this.viewModal=false;
             this.itemDel =''
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
         eliminar: async function(){
             let id = this.itemDel
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("solserv/deleteItemSrv", {option: 'servicios', id:id});
          
            if(result.status=='error'){
            this.message = result.message;
            this.status='error'
            this.delStatus();
            }else{
                this.status='';
                this.message='';
                this.wait()
            }
         },
       
         changeView: async function(option, item){
           

              const servicio = await this.getInfoById({
                id: item.id,
                option:'servicios'
               })
            
            if(servicio.id){
                    this.item=servicio
                    this.setAddedSrv(servicio)
                    this.setHistoryOptionSrv(option)
                }

         },



         // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },



          convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },


   }

    
 
}
</script>

<style scoped>

.tbloptions{
        width: 5.427083333333334VW;
        display: flex;
        background: #007E94;
        border: 1px solid white;
        padding: 0.1vw 0.5vw;
        text-align: center;
        cursor: pointer;
    }
    .tbloptions p {
        width: 3vw;
        font-size: 0.53vw;
        text-align: left;
        color: white;
        margin: auto;
        margin-left: 0.5vw;
    }
    .tbldwl img{
        width: 0.729vw;
        height: 0.885vw;
        margin-top: 0.6vw;
    }

p.snr{
         width:  54.3VW;
          height: 2.2916666666666665VW;
          text-align: center;
          padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }



.arrow_left{
    position: absolute;
    width: 2.5VW;
    height: 2.5VW;
    right: 68vw;
    top: 11vw;
    padding-top: .7vw;
    padding-left: .23vw;
  
    border-radius:  50% ;
    transition: all 500ms;
   

}.arrow_left:hover{
       background: #D3D3D3;
}
    #Solicitudes{
        margin-top: 5.288020833333333VW;
        margin-left: 5.427083VW;
    }

    .buscador {
    display: flex;
    margin-left: 7.5375VW;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }




    .btn_añadir button{
        background: var(--color-1);
        border: 0vw;
        width: 17.395833333333332VW;
        height: 2.2916666666666665VW;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
         padding-left: 0.78125VW;
        
    }.btn_añadir button span{
        font-weight: 700;
        padding-left: 0.5744791666666667VW;
    }

    .tbl_solserv{
        width: 59.8VW;
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }.hp2{
        width: 25.521vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp4{
        width: 11.823vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp3{
        width: 6.771vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp1{
        width: 5.156vw;
        
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hp1 p{
        font-size: 0.625vw;
        padding-top: 0.26875VW;
        
    }.hoptions{
        width: 5.156vw;
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
       
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
        color: #868686;
      
    }.itp2{
         width: 25.521vw;
         overflow: auto;
          color: var(--color-4);
          cursor: pointer;
    }.itp2 p{
        color: var(--color-4);
        font-style: italic;
        font-weight: 700;
          padding-left: 1VW;
    }.itp4{
        width: 11.823vw;
        text-align: center;
         overflow: auto;
    }.itp3{
        width: 6.771vw;
          text-align: center;
         overflow: auto;
    }.itp1{
        width: 5.156vw;
        overflow: auto;
        cursor: pointer;
    }
    .itp1{
        padding-left: 0vw !important;
    }.itp1 p{
         color: var(--color-4);
         font-weight: 600;
         text-align: center;
         
    }
    .itoptions{
            justify-content: center;
         width: 5.15625VW;
       background: #F8F8F8;
        display: flex;
       padding-top: 0.26875VW;
       
        border: 1px solid white;
    }
    .icon_eliminar{
        background: var(--color-4);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_eliminar:hover{
        background: var(--color-6);
    }.icon_eliminar img{
        width: 0.625VW;
        height: 0.625VW;
        margin-top: 0.3166666666666667VW;
        margin-left:0.46875VW;
    }

     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }


    .buscador-fecha{
    margin-top: 1vw;
}
.buscador-fecha label{
    color: #868686;
    font-weight: 400;
    padding-top: .2vw;
    margin-right: .3vw;
    padding-left: .5vw;
    padding-right: .5vw;
}
.buscador-fecha input{
    width: 9vw;
}
.buscador-fecha button{
   
   background: #007E94;
   width:5.6vw;
   margin-left: .3vw;
    border: 0px;
    
    font-size: 0.625vw;
    font-weight: 700;
    color: white;
   
}

</style>

<template>
    <div>
        <div class="contendos">

            <div class="F1">
                <img src="../../assets/logo2laason2.png" />
                <div class="F1B2">
                    <h3>Laboratorio de Alimentos y Aguas de Sonora, S.A. de C.V.</h3>
                    <h4>Garmendia No. 80 Colonia San Benito CP 83280 Hermosillo, Sonora, México</h4>
                    <div class="F1B2_F2">
                        <h3>Teléfono:<span>213 2302</span></h3>
                        <h4>Correo:<span>laasonora@gmail.com</span></h4>
                    </div>
                </div>
            </div>

            <div class="F2">
                <h3>FORMATO TÉCNICO DE SOLICITUD DE SERVICIO</h3>
            </div>

            <div class="marco">
                <div class="B3">

                    <div class="B3F1">
                        <div class="B3F1_B1">
                            <p>Fecha de recepción</p>
                            <h3>{{infoC.cliente.update_at.slice(0, 10)}}</h3>
                        </div>
                        <div class="B3F1_B2">
                            <p>Hora de recepción</p>
                            <h3>{{infoC.cliente.hr}}</h3>
                        </div>
                    </div>

                    <div class="B3F2">

                        <div class="B3F2_B1">
                            <p>No. de Cliente</p>
                            <h3>{{infoC.cliente.numcli}}</h3>
                        </div>

                        <div class="B3F2_B2">
                            <p>Nombre de usuario</p>
                            <h3>{{infoC.cliente.name}}</h3>
                        </div>

                        <div class="B3F2_B3">
                            <p>Dirección</p>
                            <h3>{{infoC.cliente.address}}</h3>
                        </div>

                    </div>

                </div>

                <div class="B4">
                    <div class="B4F1">
                        <div class="B4F1_B1">
                            <p>Ciudad</p>
                            <h3>{{infoC.cliente.city}}</h3>
                        </div>
                        <div class="B4F1_B2">
                            <p>Código Postal</p>
                            <h3>{{infoC.cliente.cp}}</h3>
                        </div>
                    </div>
                    <div class="B4F2">
                        <div class="B4F2_B1">
                            <p>Teléfono</p>
                            <h3>{{infoC.cliente.phone}}</h3>
                        </div>
                        <div class="B4F2_B2">
                            <p>Tel/Fax</p>
                            <h3>{{infoC.cliente.td_phone}}</h3>
                        </div>
                    </div>
                    <div class="B4F3">
                        <p>E-mail</p>
                        <h3>{{infoC.cliente.email}}</h3>
                    </div>
                </div>
            </div>
            
            <div class="B5" v-for="(item, key) in infoC.muestras" :key="key">
                <div class="B5F0">
                    <p class="B5F0_B1">Descripción de la muestra</p>
                    <p class="B5F0_B2">Estudios Solicitados</p>
                </div>
                <div v-for="(items, keys) in item.estudios" :key="keys">
                <div class="B5F1">
                    <p>{{item.muestra}}</p>
                    <h3>{{items.name}}</h3>
                </div>
                </div>
            </div>

            <div class="WhiteSpace"></div>
            
            <div class="F6">
                <h3>Observaciones:</h3>
                <p>{{infoC.observaciones}}</p>
            </div>

            <div class="B7">
                <p>El Laboratorio de Alimentos y Aguas de Sonora se compromete a prestar sus servicios de laboratorio solicitados por el usuario de acuerdo a las siguientes condiciones:</p>
            </div>

            <div class="B8">
                <div class="B8F1">
                    <p>FORMATO TÉCNICO DE SOLICITUD DE SERVICIO | CONDICIONES</p>
                </div>
                <p>I. La solicitud de servicio de laboratorio se refiere exclusivamente a las muestras recibidas. | II. El <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> no interviene en el plan de muestreo, sólo realiza toma de muestra a solicitud del usuario, por lo que no es responsable de la información proporcionada, ni de la representatividad de la muestra. | III. El <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> le otorga al usuario los servicios de laboratorio que se precisan en el anverso de la presente solicitud. | IV. El usuario pagará al <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> por concepto de Servicio de Laboratorio, el importe que resulte por el número y cantidad de servicios de acuerdo a los aranceles establecidos y acordados con el usuario. | V. El usuario deberá ingresar las muestras al <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> utilizando para ello unicamente el presente documento. | VI. El <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> se compromete a entregar al usuario la factura al momento de la entrega del pago establecido, debiendo reunir dicho documento, los requisitos fiscales de ley. | VII. La descripción e información de la muestra es resopnsabilidad del cliente. <span>Laboratorio de Alimentos y Aguas de Sonora S.A. de C.V.</span> no es responsable del uso de esta información. | DC: Información descrita en la cotización</p>
            </div>

            <div class="B9">
                <div class="B9B2">
                    <p>Quejas y Sugerencias</p>
                    <h3>laasonora@gmail.com</h3>
                    <h4>Tel. (662) 3130650</h4>
                </div>
            </div>

            <div class="B10">
                <div class="B10B1">
                    <p>Nombre y firma de quien <span>entrega las muestras</span> Aceptación de Cotización</p>
                </div>
                <div class="B10B2">
                    <p>Nombre y firma de quien <span>recibe las muestras</span></p>
                </div>
            </div>

            <div class="B11">
                <p>REV-01</p>
                <h3>FTL-03</h3>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'PDFclienteInf',
    props:{
        infoC:{
            required: true
        }
    }
}
</script>

<style scoped>

.WhiteSpace {
    width: auto;
    height: 40vw;
}

.contendos {
    width: 100%;
    text-align: -webkit-center;
}
.F1 {
    display: flex;
    justify-content: center;
}
    .F1 img {
        width: 6.8192708333333334VW;
        height: auto;
    }
    .F1B2 {
        width: 35vw;
        align-self: center;
        margin-top: 1vw;
        margin-left: 0.9723958333333335VW;
        text-align: left;
    }
        .F1B2 h3 {
            font-size: 1.4VW;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2 h4 {
            font-size: 1.1VW;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2_F2 {
            display: flex;
        }
        .F1B2_F2 span{
            font-weight: 600;
        }
        .F1B2_F2 h3 {
            font-size: 1.1vw;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2_F2 h4 {
            font-size: 1.1vw;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }


.F2 h3 {
    font-size: 1.4VW;
    font-weight: 600;
    margin: 1.5vw 0vw;    
}



.marco{
    width: 47vw;
    border: 1px solid #E5E5E5;
    padding: 1vw 2vw;
    margin-bottom: 1.5vw;
}
    .B3F1 {
        display: flex;
        justify-content: space-between;
    }
        .B3F1_B1 {
            display: flex;
        }
            .B3F1_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }
            .B3F1_B1 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }

        .B3F1_B2 {
            display: flex;
        }
            .B3F1_B2 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }
            .B3F1_B2 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }

        .B3F2_B1 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 4.2vw;
            }
            .B3F2_B1 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
        .B3F2_B2 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B2 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }
            .B3F2_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
        .B3F2_B3 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B3 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 6vw;
            }
            .B3F2_B3 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }


.B4 {
    margin-top: 1vw;
}
        .B4F1 {
            display: flex;
            justify-content: space-between;
            margin-right: 10vw;
            height: 1.2vw;
        }

            .B4F1_B1 {
                height: 2vw;
                display: flex;
            }
            .B4F1_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 3vw;
            }
            .B4F1_B1 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }
            .B4F1_B2 {
                display: flex;
            }
            .B4F1_B2 p {
                color: #868686;
                font-size: 1vw;
            }
            .B4F1_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
                margin-left: 3vw;
            }

        .B4F2 {
            height: 2vw;
            display: flex;
            justify-content: space-between;
            margin-right: 7.5vw;
            height: 1.2vw;
        }
            .B4F2_B1 {
                display: flex;
            }
            .B4F2_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2.2vw;
            }
            .B4F2_B1 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
            .B4F2_B2 {
                display: flex;
            }
            .B4F2_B2 p {
                color: #868686;
                font-size: 1vw;
            }
            .B4F2_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
                margin-left: 5.7vw;
            }
            
        .B4F3 {
            height: 1.2vw;
            display: flex;
        }
            .B4F3 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 3.2vw;
            }
            .B4F3 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }



.B5 {
    width: 47vw;
}
    .B5F0{
        display: flex;
    }
        .B5F0_B1 {
            width: 15vw;
            height: 1.5vw;
            margin-right: 0.1vw;
            margin-bottom: 0.1vw;
            padding-top: 0.2vw;
            color: white;
            background-color: #009084;
            font-size: 0.8vw;
            font-weight: 700;
        }
        .B5F0_B2 {
            width: 32vw;
            height: 1.5vw;
            margin-right: 0.1vw;
            margin-bottom: 0.1vw;
            padding-top: 0.2vw;
            color: white;
            background-color: #009084;
            font-size: 0.8vw;
            font-weight: 700;
        }
    .B5F1 {
        display: flex;
    }
        .B5F1 p {
            width: 15vw;
            height: 1.5vw;
            color: #868686;
            background-color: #F8F8F8;
            font-size: 0.9vw;
            margin: 0vw;
            margin-right: 0.1vw;
            margin-bottom: 0.2vw;
            padding-top: 0.1vw;
        }
        .B5F1 h3 {
            width: 32vw;
            height: 1.5vw;
            padding-top: 0.2vw;
            align-self: center;
            font-size: 1vw;
            font-weight: 700;
            margin: 0vw;
            margin-bottom: 0.2vw;
            background-color: #F8F8F8;
            color: #868686;
        }

.F6 h3 {
    width: 47vw;
    text-align: left;
    font-size: 0.9vw;
    font-weight: 700;
    color: #868686;
}
.F6 p {
    width: 47vw;
    height: 8vw;
    text-align: left;
    padding: 0.7vw 0vw;
    margin: 1.5vw 0vw;
    text-decoration: underline;
}

.B7 {
    margin-top: 1vw;
}
    .B7 p {
        font-size: 0.6vw;
        width: 47vw;
        text-align: justify;
    }


.B8 {
    width: 47vw;
}
    .B8F1 p {
        text-align: left;
        font-weight: 700;
        font-size: 0.9vw;
        color: #565656 !important;
    }
    .B8 p {
        font-size: 0.79vw;
        text-align: left;
        color: #868686;
    }
    .B8 span {
        color: black;
    }


.B9 {
    width: 47vw;
    text-align: left;
}
.B9 p {
    font-size: 0.9vw;
    margin: 0.5vw 0vw;
    color: #868686;
}
    .B9B2 {
        display: flex;
    }
    .B9B2 p {
        margin: 0vw;
        margin-right: 1vw;
        font-size: 0.9vw !important;
        font-weight: 600;
    }
    .B9B2 h3 {
        width: 11vw;
        margin: 0vw;
        font-size: 0.9vw;
        align-self: center;
        height: 1vw;
        border-right: 1px solid #4c4c4c;
    }
    .B9B2 h4 {
        margin: 0vw;
        margin-left: 1.8vw;
        font-size: 0.9vw;
        align-self: center;
        height: 1vw;
    }


.B10 {
    display: flex;
    width: 47vw;
}
.B10 p {
    font-size: 0.9vw;
}
.B10B1 {
    width: 21vw;
    margin-top: 7.6875VW;
    border-top: 1px solid black;
}
.B10B1 span {
    font-weight: 600;
}
.B10B2 {
    width: 18vw;
    margin-left: 1.32vw;
    margin-top: 7.6875VW;
    border-top: 1px solid black;
}
.B10B2 span {
    font-weight: 600;
}




.B11 {
    display: flex;
    width: 47vw;
    justify-content: space-between;
}
    .B11 p {
        font-size: 0.8vw;
    }
    .B11 h3 {
        font-size: 0.8vw;
    }



</style>
<template >
    <div id="AddPrf">
       
        <div class="form-content">
            <form @submit.prevent="
                submit(
                 name ,params, metodo, tipo_muestra
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Perfil</h3>
                    </div>

            
                    <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del perfil</label>
                            <input  v-model="name" type="text" id="name" name="name"   required class="in2">
                        </div>
                    </div>
                     <div class="grup-form">
                        <div>
                            <label for="metodo" class="name">Método</label>
                            <input  v-model="metodo" type="text" id="metodo" name="metodo"   class="in2">
                        </div>
                    </div>
                     <div class="grup-form">
                        <div>
                            <label for="tipo_muestra" class="name">Tipo de muestra</label>
                            <input  v-model="tipo_muestra" type="text" id="tipo_muestra" name="tipo_muestra"   class="in2">
                        </div>
                    </div>

                     <h3 class="subtitle">Asignar parametros</h3>

                    <div class="dflx">
                            <div class="tbl1">
                                <div class=" headertbl dflx">
                                    <p class="nameparam">Parametro</p>
                                    <p class="addparam"></p>
                                </div>
                                 <div v-if="parametros != 'No se encontraron coincidencias.' && parametros != 'error'">
                                     <div class="bodytbl ">
                                        <div v-for="(item,key) in parametros" :key="key" >
                                        <div v-if="includesItem(buscar, item.name)" class="itemtbl dflx">
                                            <p class="itemparam">{{item.name}}</p>
                                            <p @click="pushItem(item)" class="imgp"><img src="../../assets/i_mas.png" alt=""></p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="buscador dflx">
                                        <label for="buscar">Buscar</label>
                                        <input type="search" v-model="buscar">
                                    </div>
                                </div>
                                <div v-else>
                                         <div class="bodytbl ">
                                        <div  >
                                        <div  class="itemtbl dflx">
                                            <p class="itemparam">No se encontraron parametros</p>
                                            <p  class="imgp"></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="tbl1">
                                <div class=" headertbl dflx">
                                    <p class="nameparam">SELECCIONADOS</p>
                                    <p class="addparam"></p>
                                </div>
                                 <div class="bodytbl ">
                                    <div v-for="(item,key) in params" :key="key" >
                                       <div  class="itemtbl dflx">
                                         <p class="itemparam">{{item.name}}</p>
                                        <p @click="delItem(item)" class="delp">&#215;</p>
                                       </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                  

                  




                        <div class="dflx">
                             <p @click="wait()" class="btnRegresar">Regresar</p>
                                <button class="alta" type="submit">Guardar <span>Perfil</span></button>
                        </div>

                     

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>
          
            
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_add">Alta <span>exitosa</span></h3>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">AÑADIR OTRO</p> <p  @click="toEdit()" class="editar">EDITAR</p>  <p  @click="back()" class="inicio">INICIO</p>
            </div>
          </div>
        </div>

        </div>
           
    </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
    name:'AddPrf',
    data(){
        return{
           status:'',
           message:'',
           name:"" ,
           tipo_muestra:"",
           metodo:"",
           abr:"",
           lc:"",
           uni:"",
           ref:"",
           type:'1',
            params:[],
            buscar:'',
           //MODAL

            viewModal:false,



        }
    },
    created(){
         this.getIdentity()
         
          this.getAllInfoPrm('parametros_b')
    },
    computed:{
          identity() {
           return this.$store.getters['admin/getIdentity'];
        },
         parametros() {
             return this.$store.getters["parametros/data"];
        },
    },
    methods:{
         ...mapActions('profile', ['setAddedPrf']),
                  ...mapActions('parametros', ['getAllInfoPrm']),
         ...mapActions('profile', ['setHistoryOptionPrf']),
         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        submit: async function(  name,params , metodo, tipo_muestra){
                 this.status =''
                 this.message =''
               
                  let parametro ={
                    "name":name,
                    "params":params, metodo, tipo_muestra
                  }

                  

                    let result = await this.$store.dispatch("profile/addItemPrf",  {option:'profile_b', item: parametro});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedPrf(result.added )
                        this.showModal()
                       
                    } 
               
           
             

            
    },

    pushItem(item){
       let arr = []
       arr = this.params
       !arr.includes(item) ? arr.push(item) : arr
       this.params = arr
    },
    delItem(item){
      const diferente = param => param.id != item.id
      let arr = this.params.filter(diferente)
      this.params = arr
    },


     showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        
        this.setAddedPrf('')
        this.setHistoryOptionPrf('Default')
    },
    Add:function(){
        this.viewModal=false;
        this.status = '';
        this.message='';
        this.name="" ;
        this.metodo="" ;
        this.tipo_muestra="" ;
      
        this.setAddedPrf('')
    },
    toEdit:function(){
        
        
        this.setHistoryOptionPrf('Edit')
         this.viewModal=false;
    },
    back:function(){
        setTimeout(() => this.$router.go(), 200);
    },

     includesItem(search, name) {
      if (search == "") {
        return true;
      }
      name = name.toLowerCase();
      search = search.toLowerCase();
      if (search == "") {
        return false;
      }
      return name.includes(search);
    },

    


   }
}
</script>
<style scoped>
    p.parm{
            color: #868686;
        font-size: 0.7291666666666666VW;
        font-weight: 400 !important;
    }

  
    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

 input.in2{
      width: 27.489583333333336VW;
  }

    label.name{
        width: 7.822917VW; 
        margin-right: 0.7291666666666666VW;
    }

   
    

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


   label.role{
        width: 1.3875VW;
        margin-right: 2.7VW;
        margin-left:0vw;
    }


select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
        width: 5VW;
  }

  select:focus-visible{
      outline: none !important;
  }

.headertbl{
    background: #00A175;
    color: white;
}
.headertbl p{
    color: white;
    margin: 0vw;
}
p.nameparam{
    width: 20vw;
    padding:.5vw 1vw;
    border-right: 1px solid white;
    font-size: .8vw;
    text-transform: uppercase;
}
p.addparam{
     width: 3vw;
}
.bodytbl{
    height: 20vw;
    overflow: auto;
}

.itemtbl p{
    margin: 0vw;
    border-bottom: 1px solid white;
     font-size: .7vw;
}
p.itemparam{
    width: 20vw;
    padding:.5vw 1vw;
    border-right: 1px solid white;
}
p.imgp{
     width: 3vw;
     text-align: center;
     background: var(--color-2);
      cursor: pointer;
}
p.imgp img{
   
    padding-top: .5vw;
}
.bodytbl{
    background: #dddddd;
}

.buscador{
    margin-top: 1vw;
}
.buscador label{
    font-size: .8vw;
    padding: 0vw 0.5vw;
}
.buscador input{
    font-size: .8vw;
    width: 20vw;
}

p.delp{
    color: rgb(95, 95, 95);
     width: 3vw;
     text-align: center;
     font-size: 1vw;
     cursor: pointer;
     padding-top: .2vw;
}
.tbl1{
    margin-right: 1vw;
}

h3.subtitle{
     margin-top: 2vw;
    font-size: 1.2229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
}

p.btnRegresar{
    margin-left: 0vw;
}


</style>
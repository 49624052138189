<template>
  
    <div id="Validaciones">
       
     
      
        <div v-if="historyOption =='Default'">
         
          <div class="text_option txt1">
                           
                            <h3>Validar</h3>
                            
                    </div>
         
         
            <div class="dflx">

          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img  src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>
        <p class="validar-p">VALIDAR</p>
        <input v-model="validar" type="checkbox" id="validar-input" class=""  />
          <div v-if="historyOption =='Default'" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>
        
        </div>

         <div class="dflx buscador-fecha" >
              <label for="fecha1" class="fecha_search">Selecciona el rango de fecha</label>
              <input  v-model="fecha2" type="date"  name="fecha2"  > <!-- :max="day()" -->
                <label for="fecha1" class="fecha_search"> a</label>
              <input  v-model="fecha1" type="date"  name="fecha1"  > <!-- :max="day()" -->
              <button @click="actualizar(fecha1, fecha2)">Buscar</button>
        </div>
        
    

        <!-- Tabbla-->

        <div  class="tbl_validaciones" > <!--v-if="identity.role != 'analista'"  -->
                 <div class="tbl_header">
                <div class="tblh th1">
                  <p>FECHA</p>
                </div>

               
                <div class="tblh th2">
                  <p>Nombre del Cliente</p>
                </div>

                
                  <div class="tblh th3">
                  <p>OBSERVACIONES</p>
                </div>
             
                 <div class="tblh th3">
                  <p>DETERMINACIONES</p>
                </div>

                <div class="tblh th10">
                  <p>FIRMA DE <br> ANALISTA</p>
                </div>

                  <div class="tblh th10">
                  <p>FIRMA DE <br> LIBERACION</p>
                </div>

              
                 <div class="tblh th11">
                  <p>SUPERVISÓ</p>
                </div>
                  <div class="tblh th10">
                  <p>PDF <br> ENVIADO</p>
                </div>

             
                 <div class="tblh th7">
                 
                </div> 
                 <div class="tblh th7">
                 
                </div> 
                 <div class="tblh th7">
                 
                </div> 
                
               
            </div>
            
            <div class="tbl_content">
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error'  && data != ''">
                    <div  v-for="(item, key) in filteredItems" :key="key">
                         <div v-if="identity.role !='spadmin'">
                             <div v-if="item.servicio.forma =='1'">
                                 <div  class="tbl_items">
                                        <div @click="changeView('Resultados',item)" :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it1 _cp"> 
                                        <p>{{convertdate(item.servicio.fecha)}}</p>
                                    </div>

                                    <div @click="changeView('Resultados',item)" :class="{am:item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it2 _cp"> 
                                       <p v-if="item.servicio.id_sucursal !=null && item.servicio.sucursal.name"> {{item.servicio.sucursal.name}} </p>
                                       <p v-else>{{item.servicio.cliente.name}}</p>
                                    </div>

                                    
                               
                                    <div :class="{am: item.superviso == null ||  item.superviso  =='' }" class="tbl_item it3"> 
                                        <p>{{item.extra.obv}}</p>
                                    </div>
                                    
                        
                                    <div @click="changeView('Resultados',item)" :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it3 _cp"> 
                                        <p>{{determinaciones(item.resultados)}}</p>
                                    </div>

                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.f_analista}}</p> 
                                    </div>
                                    

                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.f_liberacion}}</p> 
                                    </div>
                                
                                <div :class="{am: item.superviso == null ||  item.superviso  =='' }" class="tbl_item it11"> 
                                        <p>  <span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                                    </div>
                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.extra.pdfenviado}}</p> 
                                    </div>
                                
                                    <div class="tbl_item it7" @click="changeView('Resultados',item), idMEI=item.id" > <!-- -->
                                        <img src="../../assets/editar.png" alt="">
                                    </div> 
                                    <div @click="PDFview(item)" class="tbl_item it7" :class="{pdfsaved: item.extra.pdf_saved == 'si'}" > <!-- -->
                                        <img v-if="item.extra.pdf_saved == 'si'" src="../../assets/Dont_Saved.png" alt="">
                                        <img v-else src="../../assets/download.png" alt="">
                                    </div> 
                                <!--  <div class="tbl_item it7"  > 
                                        <img src="../../assets/compartir.png" alt="">
                                    </div> -->
                                </div>
                             </div>
                         </div>
                         
                         <div v-else>
                             <div v-if="item.servicio.forma =='2'"><!--  -->
                                    <div  class="tbl_items">
                                        <div @click="changeView('Resultados',item)" :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it1 _cp"> 
                                        <p>{{convertdate(item.servicio.fecha)}}</p>
                                    </div>

                                    <div @click="changeView('Resultados',item)" :class="{am:item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it2 _cp"> 
                                           <p v-if="item.servicio.id_sucursal !=null && item.servicio.sucursal.name"> {{item.servicio.sucursal.name}} </p>
                                           <p v-else>{{item.servicio.cliente.name}}</p>
                                    </div>

                                    
                             
                                    <div :class="{am: item.superviso == null ||  item.superviso  =='' }" class="tbl_item it3"> 
                                        <p>{{item.extra.obv}}</p>
                                    </div>
                                    
                        
                                    <div @click="changeView('Resultados',item)" :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it3 _cp"> 
                                        <p>{{determinaciones(item.resultados)}}</p>
                                    </div>

                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.f_analista}}</p> 
                                    </div>
                                    

                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.f_liberacion}}</p> 
                                    </div>
                                
                                <div :class="{am: item.superviso == null ||  item.superviso  =='' }" class="tbl_item it11"> 
                                        <p>  <span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                                    </div>
                                    <div   :class="{am: item.superviso == null ||  item.superviso  =='' }"  class="tbl_item it10"> 
                                        <p>{{item.extra.pdfenviado}}</p> 
                                    </div>
                                
                                    <div class="tbl_item it7" @click="changeView('Resultados',item)" > <!-- -->
                                        <img src="../../assets/editar.png" alt="">
                                    </div> 
                                    <div @click="PDFview(item)" class="tbl_item it7"  > <!-- -->
                                        <img src="../../assets/download.png" alt="">
                                    </div> 
                                <!--  <div class="tbl_item it7"  > 
                                        <img src="../../assets/compartir.png" alt="">
                                    </div> -->
                                </div>
                             </div>
                         </div>
                       
                      
                       
                    </div>
                    <p v-if="lop ==0" class="snp"> Sin muestras por validar.</p>
                </div>  
                <div v-else>
                    <p class="snp">Sin muestras por validar.</p>
                </div>
            </div>
        </div>


       <!-- <div class="F5">
            <form @submit.prevent="
                     submit(
                        text1,
                        text2
                    )
                ">
                <p class="F5Title">Modificar texto resultados</p>
                <div class="F5F1">
                    <label for="texto">Texto 1:</label>
                    <textarea v-model="text1" type="text" id="texto" name="texto" required ></textarea>
                </div>
                <div class="F5F2">
                    <label for="texdos">Texto 2:</label>
                    <textarea v-model="text2" type="text" id="texdos" name="texdos" required ></textarea>
                </div>
                <button class="save" type="submit">Modificar</button>
               
                
            </form>
            
        </div> -->


        </div> <!--- FIN opcion default--->


        <div v-if="historyOption =='Resultados'">
                    <div class="text_option txt1">
                           <h3>Muestra {{added.num}}</h3>
                           <!-- <h3>{{added.servicio.clave}}</h3> -->
                    </div>

                     <div  v-if="identity.role =='legrafica' || identity.role =='admin' || identity.role =='spadmin'" class="">
            <div class="tbl_header2">
                <div class="tblh th12">
                  <p>FECHA DE <br> RECEPCIÓN</p>
                </div>
                
              

                <div class="tblh th14">
                   <p>ANALISTA</p>
                </div>
                 <div class="tblh th14">
                   <p>LIBERACIÓN</p>
                </div>
                
                
               
            </div>
            
            <div class="tbl_content" >
                <div >
                    <div class="tbl_items">
                        <div   class="tbl_item it12"> 
                               <p>{{convertdate(added.servicio.fecha)}}</p>
                        </div>
                     
                           
                      

                         <div  class="tbl_item it14"> 
                               <p>{{added.f_analista}}</p>
                        </div>
                          <div  class="tbl_item it14"> 
                               <p>{{added.f_liberacion}}</p>
                        </div>
                        
                       
                      
                       
                    </div>
                </div>  

                <form @submit.prevent="
                     enviarValidacion(
                        added
                    )
                ">
            <div class="dflx mthr">
                <div class="tblh th15">
                  <p>FECHA DE <br>INICIO</p>
                </div>
                
                <div class="tblh th15">
                  <p>FECHA DE <br> FINALIZACIÓN</p>
                </div> 
                <div class="tblh th15">
                  <p>FECHA DE <br> LIBERACIÓN</p>
                </div> 
                 <div class="tblh th15">
                  <p>FECHA DE <br> VALIDACIÓN</p>
                </div> 
            </div>
     
            <div>
                <div >
                      <input class="in15" v-model="added.fecha_i" type="date" :required="added.fecha_i=='' || added.fecha_i==null">
                      <input  class="in15" v-model="added.fecha_f" type="date" :min="added.fecha_i" :required="added.fecha_f=='' || added.fecha_f==null"> 
                       <input  disabled  class="in15" v-model="f3" type="text" >
                       <input  disabled  class="in15" v-model="f4" type="text" >
                </div>
              
               <div >
                    <input disabled  class="in15" v-model="f2" type="text" >
                     <input  disabled  class="in15" v-model="f2" type="text" >
                       <input  disabled  class="in15" v-model="f5" type="text" >
                         <input  disabled  class="in15" v-model="f5" type="text" >
                     
                     
               </div>
             <!--  <p v-if="added.fecha_i != null && added.fecha_i !=''" class="editarfechas" @click="editfechas()">Editar fechas</p> -->
            </div>

           <div class="dflx mthr">
             <div class="tblh th19">
                  <p>PERFIL</p>
                </div>
                 <div class="tblh th19">
                  <p>TIPO MUESTRA</p>
                </div>
                 <div class="tblh th19">
                  <p>MÉTODO</p>
                </div>
                <div class="tblh th16">
                  <p>DETERMINACIONES</p>
                </div>
                  <div class="tblh th17">
                  <p>RESULTADOS</p>
                </div>
               
                <div class="tblh th19">
                  <p>REFERENCIA</p>
                </div>
                
              
            </div>
            <div v-for="(res , key) in added.resultados" :key="key" class="dflx">
                 <input v-model="res.profile.name" id="sit18" disabled type="text">
                <input v-model="res.profile.tipo_muestra" id="sit18" disabled type="text">
                      <input v-model="res.profile.metodo" id="sit18" disabled type="text">
                      <input v-model="res.pname" class="it16" disabled type="text">
                      <input v-model="res.resultado" class="it17" type="text" >
                      <select v-model="res.uni" id="sit17" >
                          <option
                                        v-for="(uni, key) in res.luni"
                                        v-bind:key="key"
                                        v-bind:value="uni"
                                    >
                                        {{uni}}
                                    </option>
                      </select>
                      

                        <select v-model="res.ref" id="sit18" > <!-- required-->
                          <option
                                        v-for="(re, key) in res.lp"
                                        v-bind:key="key"
                                        v-bind:value="re"
                                    >
                                        {{re}}
                                    </option>
                      </select>
                       <!--
                        <div  class="tbl_item it19"> 
                               <p>{{res.pref}}</p>
                        </div> -->
            </div>

<div class="dflx">
        <p @click="wait()" class="regresar">Regresar</p>
       <button class="modificar" type="submit"> Modificar</button>
        <p v-if="item.superviso == null || item.superviso ==''" @click="changeView('PDF',item)" class="validar">Validar</p>
</div>
               



            </form>
               
            </div>
        </div> <!--- FIN tabla --->


         
        </div>


        
                   

       <div v-if="historyOption =='PDF'">
            <PDF />
       </div>
       


       
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        
   
        
    





         <div v-if="viewModal2" class="modal_view"> 
           
              <div id="modal_val">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_val"> <span>Modificación</span> exitosa</h3>
           
            <div class="modal_val_btns dflx">
                 <p  @click="back()" >INICIO</p>
            </div>
          </div>
        </div> 

        
      

    </div>
</template>
<script>


import PDF from './pdf.vue'

import {mapActions} from 'vuex';
import dayjs from 'dayjs'
export default {
    name: 'Validaciones',
    components:{
       PDF
    },
 
   data(){
       return{
           idMEI: '',
           status:'',
           message:'',
           item: '',
           search_value:'',
           updsearchcli:'',
           currentPage: 2,
           itemsPerPage:   12,


           viewModal:false,
           itemDel:'',
           mtra:'',

           viewModal2:false,
           

           fanalista:'',
           fliberacion:'',
           lop:'',
           editfecha:false,

           f1:'',
           f2:'',
           f3:'',
           f4:'',
           f5:'',

           text1:'',
           text2:'',
           text1Copy:'',
           text2Copy:'',
           english_textone:'',
           english_texttwo:'',

           validar:false,

            fecha1:'',
            fecha2:'',
          
       }
   },
   async created (){
            let day1 =  localStorage.getItem('fecha1')
            let day2 =  localStorage.getItem('fecha2')

            if(!day1 || !day2){
             day1 =  dayjs().format('YYYY-MM-DD')
             day2 =  dayjs().subtract(7,'days').format('YYYY-MM-DD')

              localStorage.setItem('fecha1',day1)
              localStorage.setItem('fecha2',day2)
           
 
            }



            this.fecha1 =day1
            this.fecha2 =day2

           
            
    
        let payload ={
            option:'muestras_b_rg',
            data:{
                fecha1:this.fecha1,
                fecha2:this.fecha2
            }
        }
        this.getAllRangeVal(payload)


   
       this.getIdentity(),
       this.getAllInfoUsr('usuarios')
       await this.getAllInfoTxt('texto')

        let datosT = this.$store.getters["texto/data"];
        this.text1 = datosT[0].texto
        this.text2 = datosT[0].texdos
        this.text1Copy = datosT[0].texto
        this.text2Copy = datosT[0].texdos
        this.english_textone = datosT[0].english_textone
        this.english_texttwo = datosT[0].english_texttwo

         let buscador = localStorage.getItem('buscador')
       if(buscador != undefined && buscador != null && 	buscador != ''){
         this.search_value = buscador
       }

       let validar_value = localStorage.getItem('validar_value')
       
   
             validar_value == 'true' ? this.validar = true : this.validar = false
   
       
   },
  
    watch:{
        validar(value){
            this.validar = value
            localStorage.setItem('validar_value', value)
        },
         search_value(value){
           localStorage.setItem('buscador', value)
        }
    },

    computed:{
        
         datas() {
             
            return this.$store.getters["texto/data"];
        },

        data() {
           let data = this.$store.getters["validaciones/data"]
           let count =0
           let array=[];
           if(data != 'No se encontraron coincidencias.' && data != 'error'){

                if(this.identity.role =='spadmin'){
                     for(var i=0; i<data.length; i++){
                     if(data[i].f_analista != null   &&  data[i].f_analista != ''   &&  data[i].f_liberacion != ''  && data[i].f_liberacion != null  && data[i].servicio.forma =='2'){//
                        if(this.validar){
                            if(this.search_value != ''){
                                if(data[i].superviso == null &&  this.includesItem(this.search_value, data[i].servicio.cliente.name)){
                                    count= count+1
                                    array.push(data[i])
                                }
                            }else{
                                 if(data[i].superviso == null ){
                                    count= count+1
                                    array.push(data[i])
                                }
                            }
                        }else{
                             if(this.search_value != ''){
                                if( this.includesItem(this.search_value, data[i].servicio.cliente.name)){
                                     count= count+1
                                    array.push(data[i])
                                }
                            }else{
                                 count= count+1
                                    array.push(data[i])
                            }
                         
                        }
                    }
                   }
                }else{
                   for(var i=0; i<data.length; i++){
                     if(data[i].f_analista != null   &&  data[i].f_analista != ''   &&  data[i].f_liberacion != ''  && data[i].f_liberacion != null && data[i].servicio.forma =='1'){
                        if(this.validar){
                            if(this.search_value != ''){
                                if(data[i].superviso == null &&  this.includesItem(this.search_value, data[i].servicio.cliente.name)){
                                    count= count+1
                                    array.push(data[i])
                                }
                            }else{
                                 if(data[i].superviso == null ){
                                    count= count+1
                                    array.push(data[i])
                                }
                            }
                        }else{
                            if(this.search_value != ''){
                                if( this.includesItem(this.search_value, data[i].servicio.cliente.name)){
                                     count= count+1
                                     array.push(data[i])
                                }
                            }else{
                                 count= count+1
                                    array.push(data[i])
                            }
                        }
                     }
                   }
                }   
                
           }else{
               array = 'No se encontraron coincidencias.'
           }
         
           this.lop = count
          return array;
        },
        historyOption(){
            return this.$store.getters["validaciones/getHistoryOption"]
        },
        analistas() {
            let data = this.$store.getters["usuarios/data"];
            let array =[]

            for(var i=0; i< data.length; i++){
                if(data[i].role =='analista'){
                    array.push(data[i])
                }
            }
             return array
        }, 
         added(){

            let added = this.$store.getters["validaciones/getAdded"]
           if(added.fecha_i != null && added.fecha_i != ''){
                   this.f1 = this.convertdate(added.fecha_i)
             }else{
                 this.f1 =''
             }
            
          
             if(added.fecha_f != null && added.fecha_f != ''){
                   this.f2 = this.convertdate(added.fecha_f)
             }else{
                 this.f2 =''
             }
             
             
             if(added.fecha_l != null && added.fecha_l != ''){
                 this.f3 = this.convertdate(added.fecha_l)
             }else{
                 this.f3 =''
             }

              if(added.fecha_v != null && added.fecha_v != ''){
                 this.f4 = this.convertdate(added.fecha_v)
             }else{
                 this.f4 =''
             }
           
            return added
            
        },


        



          //Paginacion
        allItems () {
              let data = this.data
            data == 'No se encontraron coincidencias.' || data == 'error' ? data =[] : data
            return Object.keys(data).map(pid => data[pid])
        },
        filteredItems () {
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage = this.page()
                }
        return total
       },
       identity() {
             return this.$store.getters['admin/getIdentity'];
       }
    },
   
   methods:{
        ...mapActions('validaciones', ['setHistoryOptionVal']),
        ...mapActions('validaciones', ['setAddedVal']),
        ...mapActions('validaciones', ['getAllInfoVal']),
        ...mapActions('usuarios', ['getAllInfoUsr']),
        ...mapActions('PDF', ['setAddedPDF']),
        ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('validaciones', ['getAllRangeVal']),
        ...mapActions("texto", ["getAllInfoTxt"]),
        ...mapActions("texto", ["getInfoByIdTxt"]),
        includesItem(search, name){
             search = search.trim()
                name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if(search ==''){
                    return true
                }
                name = name.toLowerCase()
                search= search.toLowerCase()
                if(search ==''){
                    return false;
                }
                return name.includes(search)
        },
         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
        page(){
            let num = localStorage.getItem('page_validaciones');
            isNaN(num) || num == undefined || num == '' || num == null ?  num = 1 : num
            return num
           
        },
        PDFview: async function(muestra) {
            if(muestra.superviso =="" || muestra.superviso==null){
                this.message='Esta muestra aún no se ha supervisado'
                this.status='error'
                this.delStatus2();
            }else{
                
                this.setAddedPDF(muestra)
                this.setHistoryOptionMain('PDFcreate')
            }
          
        },

        editfechas(){
            if( this.editfecha == true){
                this.editfecha = false;
            }else{
                this.editfecha = true;
            }
        },
         showModal: function(item){
             this.mtra = item
             this.viewModal=true
         },
          showModal2: function(){
             this.viewModal2=true
         },
         closeModal:function(){
             this.viewModal2=false;
             this.mtra ='';
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
         back:function(){
        setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
         delStatus2: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },

         enviarValidacion: async function(muestra ){
            
             if(muestra.extra.pdfenviado =='no'){
                  let result = await this.$store.dispatch("validaciones/editItemVal",  {option:'muestras_b/resultados', item: muestra});
                                        
                if(result.status=='error'){
                  
                }else{ // success
                    this.showModal2()
                } 
             }else{
                   this.status='error'
                   this.message= 'La muestra no se puede modificar una vez que ha sido enviado el pdf.'
                   this.delStatus2()
             }
         
        },


        submit: async function(  texto, texdos ){
            this.status =''
            this.message =''
            let array = this.$store.getters["texto/data"];
            let withoutSpace = texto.trim();
            let withoutSpace2 = texdos.trim();
            
            if(withoutSpace.length > 0 && withoutSpace2.length>0){

                let id= array[0].id;
                let item ={
                    "id":id,
                    "texto":texto,
                    "texdos": texdos
                }

                let result = await this.$store.dispatch("texto/editItemTxt",  {option:'texto', item: item});
                    
                if(result.status=='error'){
                    this.status='error'
                    this.message= result.message
                }else{ // success
                    this.showModal2()
                }
            }else{
                this.status='error'
                this.message= 'Campos vacios.'
                this.delStatus2()
            }
               
            
        },
       


       
         changeView(option, item){

            
                 this.item=item
                this.setAddedVal(item)
                this.setHistoryOptionVal(option)
                this.setAddedPDF(item)
             
             
         },

         actualizar: async function(fecha1, fecha2){
            this.fecha1 =fecha1
            this.fecha2 = fecha2

               localStorage.setItem('fecha1',fecha1)
              localStorage.setItem('fecha2',fecha2)

            let payload ={
                option:'muestras_b_rg',
                data:{
                    fecha1:this.fecha1,
                    fecha2:this.fecha2
                }
            }
            this.getAllRangeVal(payload)
        },




         // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
             localStorage.setItem('page_validaciones', this.currentPage)
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
             localStorage.setItem('page_validaciones', this.currentPage)
          }
        },


        convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
        determinaciones(resultados){
            let str =''
            if(resultados ==''){
                return str
            }
            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pabr
                }else{
                    str = str + ',' + resultados[i].pabr
                }
            }

            return str
        },
          determinaciones2(resultados){
            let str =''

            if(resultados ==''){
                return str
            }

            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pname
                }else{
                    str = str + ',' + resultados[i].pname
                }
            }

            return str
        }
   }

    
 
}
</script>

<style scoped>

p.editarfechas{
    color: #868686;
    cursor: pointer;
    font-style: italic;
    margin-top: .5vw;
    margin-left: .5vw;
}

._cp{
    cursor: pointer;
}
.ttop{
    margin-top: 5vw;
}

.am{
    background: #B4FFE4 !important;
}
 #Validaciones{
        margin-top: 5.288020833333333VW;
        margin-left: 5.427083VW;
    }

    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
    margin-bottom: 2.083333333333333VW;
  }

     .buscador {
    display: flex;
    margin-right: 20vw;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
     background: #FFFFFF
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }

   /** TABLA */
    
    .tbl_validaciones{
        width: 60.7VW; 
        margin-top: 1.5625VW;
    }

    p.snp{
         width:  58.1VW;
          height: 2.2916666666666665VW;
          text-align: center;
          padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }
 .tbl_header2{
       display: flex;
 }
      
    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
       
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: 1VW;
         background: #F8F8F8;
         border: 1px solid white; 
         overflow: auto;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
      
    }
    
   
   
    

    .th1{
        width: 5.15625VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th1 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it1{
          width: 5.15625VW;
          padding-left: 0vw;
    }.it1 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
        text-align: center;
        
    }

    .th2{
        width: 16.25VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th2 p{
        font-size: 0.9375VW;
        font-weight: 700;
        padding-top: 0.46875VW;
    }

    .it2{
          width: 16.25VW;
          padding-left: 0vw;
    }.it2 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 700;
        padding-left: 1.0416666666666665VW;
        
    }
    .it9{
           width: 16.25VW;
          padding-left: 0vw;
    }.it9 p{
        font-size: 0.6770833333333334VW;
        color: #868686;
        font-weight: 400;
        padding-left: 1.0416666666666665VW;
        
    }

     .th3{
        width:8.802083333333334VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th3 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it3{
          width:8.802083333333334VW;
          padding-left: 0vw;
    }.it3 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
      padding-left: 1.0416666666666665VW;

    }

    
   

   

    .th7{
        width:1.66VW;
        background: white;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }

    .it7{
          width:1.66VW;
          padding-left: .3vw;
    }.it7 img{
       width: 0.9375VW;
       height: 0.8854166666666666VW;
       cursor: pointer;
    }

    .pdfsaved {
        background: #007E94;
    }

   

    .th10{
        width: 4.2VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th10 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

     .it10{
          width:  4.2VW;
          padding-left: 0vw;
    }.it10 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
        text-align: center;
        
    }
   
    select.it10{
        width: 5.46875VW;
        height: 2.2916666666666665VW;
        padding-left: 0vw;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }

     .th11{
        width:4.2vw;
         border-right: 0.052083333333333336VW solid var(--color-5);
    }.th11 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it11{
          width:4.2vw;
          padding-left: 0vw;
    }.it11 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }


     .th12{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background: #868686 !important;
    }.th12 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

    .it12{
          width:8.229166666666666VW;
          padding-left: 0vw;
    }.it12 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 400;
       padding-left: 0;
       text-align: center;

    }

    .th13{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th13 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it13{
          width:8.229166666666666VW;
          padding-left: 0vw;
          
    }.it13 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }

    .th14{
        width:15.572916666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th14 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    .it14{
          width:15.572916666666666VW;
          padding-left: 0vw;
          
    }.it14 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }
   

    .th15{
        width:8.229166666666666VW;
         border-right: 0.052083333333333336VW solid var(--color-5);
         background:var(--color-2);
    }.th15 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.26875VW;
    }

    .it15{
          width:8.229166666666666VW;
          padding-left: 0vw;
          
    }.it15 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;

    }

    input.in15{
         width:8.229166666666666VW;
         height: 1.6145833333333335VW;
    }

    .mthr{
        margin-top: 1vw;
    }


    .th16{
            width:18.229166666666664VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th16 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    input.it16{
        width:18.229166666666664VW;
  height: 1.6145833333333335VW;
    }

     .th17{
            width:15.572916666666666VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th17 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }
    select#sit17{
        width: 7.19125VW;
    }

   input.it17{
         height: 1.6145833333333335VW;
         width: 8.333333333333332VW;
    }
       .th18{
            width:12.968750000000002VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th18 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

    select#it18{
        width:12.968750000000002VW;
         height: 1.6145833333333335VW;
       background: #F8F8F8;
        
    }

       .th19{
            width:8.229166666666666VW;
            border-right: 0.052083333333333336VW solid var(--color-5);
            background:var(--color-2);
    }.th19 p{
        font-size: 0.625VW;
        font-weight: 700;
        padding-top: 0.66875VW;
    }

  
.it19{
          width:8.229166666666666VW;
          padding-left: 0vw;
           padding-top: 0.1vw;
           height: 1.6145833333333335VW;
          
    }.it19 p{
        font-size: 0.625VW;
        color: #868686;
        font-weight: 700;
       padding-left: 0;
       text-align: center;
      

    }
    button.modificar{
        background:#868686 ;
        color: white;
        border: none;
        width: 10.15625VW;
        height: 2.2916666666666665VW;
        margin-top:  2.2916666666666665VW;
         margin-right: .5vw;
    }

    p.regresar{
         background: #868686;
        color: white;
        border: none;
        cursor: pointer;
        width: 10.15625VW;
        text-align: center;
        padding-top: .4vw;
        height: 2.2916666666666665VW;
        margin-top:  2.2916666666666665VW;
        margin-right: .5vw;
    }

     p.validar{
         background: #007E94;
        color: white;
        border: none;
        cursor: pointer;
        width: 10.15625VW;
        text-align: center;
        padding-top: .4vw;
        height: 2.2916666666666665VW;
        margin-top:  2.2916666666666665VW;
       
    }
   /** FIn tabla */


input, select{
        width: 50%;
         border-top: 0.052083333333333336VW solid var(--color-5);
         border-bottom: 0.052083333333333336VW solid var(--color-5);
         height: 1.6145834VW;
    }
    input{
         border-left: 0.052083333333333336VW solid var(--color-5);
          border-right: none;
            color: #868686;
          background: #F8F8F8;
         
    }
    select{
        background: #D3D3D3;
        border-left: none;
        border-right: none;
        text-align: center;
         color: #868686;
         font-weight: 700;
    }select option{
        background: white;
         color: #868686;
        border: 0.052083333333333336VW solid var(--color-5);
    }




    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }



    .F5 {
        margin-top: 7vw;
    }
        .F5F1 {
            width: 64vw;
            height: 12vw;
            display: grid;
        }
        .F5Title {
            font-size: 1.8229166666666667VW;
            color: var(--color-4);
            font-weight: 300 !important;
            font-style: normal;
            margin-bottom: 2.083333333333333VW;
        }
        .F5F1 label{
            font-size: 0.9375VW;
            color: #868686;
            font-weight: 700;
            padding-left: 1.0416666666666665VW;
        }
        .F5F1 textarea {
            width: 64vw;
            height: 10vw;
            resize: none;
            font-size: 0.7375VW;
        }
        .F5F2 {
            width: 64vw;
            height: 12vw;
            display: grid;
        }
        .F5F2 label{
            font-size: 0.9375VW;
            color: #868686;
            font-weight: 700;
            padding-left: 1.0416666666666665VW;
        }
        .F5F2 textarea {
            width: 64vw;
            height: 10vw;
            resize: none;
            font-size: 0.7375VW;
        }
        .F5 button {
            background: #868686;
            color: white;
            border: none;
            width: 10.15625VW;
            height: 2.2916666666666665VW;
            margin-top: 2.2916666666666665VW;
            margin-right: 0.5vw;
        }

             select#sit18 , input#sit18{
        width: 8.2VW;
    }
    input#sit18{
        overflow: auto;
    }


     .buscador-fecha{
    margin-top: 1vw;
}
.buscador-fecha label{
    color: #868686;
    font-weight: 400;
    padding-top: .2vw;
    margin-right: .3vw;
    padding-left: .5vw;
    padding-right: .5vw;
}
.buscador-fecha input{
    width: 9vw;
}
.buscador-fecha button{
   
   background: #007E94;
   width:5.6vw;
   margin-left: .3vw;
    border: 0px;
    
    font-size: 0.625vw;
    font-weight: 700;
    color: white;
   
}




</style>

<template>
  
    <div id="Clientes">
       
      
       <!--  {{historyOption}} <div v-if="historyOption != 'Default'" class="arrow_left" @click="wait()">
             <img src="../../assets/Arrow_Left.png" alt="">
         </div> -->

        <div v-if="historyOption =='Default'">
            <div class="dflx">
      
        
        <div class="btn_añadir" @click="changeView('Add','')">
           <button><img src="../../assets/i_mas.png" alt=""> <span>Añadir</span> Nuevo Cliente </button>
        </div>

          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img   src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>

          <div v-if="historyOption =='Default'" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">{{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>


        </div>
        

        <div class="tbl_clientes">
            <div class="tbl_header">
                <div class="tblh hnum">
                  <p>No. DE  <br>CLIENTE</p>
                </div>
                <div class="tblh hname">
                    <p>Nombre</p>
                </div>
                <div class="tblh hrfc">
                  <p>RFC</p>
                </div>
                 <div class="tblh hcorreo">
                   <p>Correo</p>
                </div>
                
                 <div class="tblh hoptions">
                    
                </div>
            </div>
          
            <div tbl_content>
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error' && data != ''">
                    <div class="tbl_items" v-for="(item, key) in filteredItems" :key="key">
                        <div @click="changeView('VistaCliente',item)" class="tbl_item itnum">
                               <p>{{item.numcli}}</p>
                        </div>
                        <div @click="changeView('VistaCliente',item)"  class="tbl_item itname">
                            <p>{{item.name}}</p>
                        </div>
                        <div class="tbl_item itrfc">
                            <p>{{item.td_rfc}}</p>
                        </div>
                        <div class="tbl_item itcorreo">
                               <p>{{item.email}}</p>
                        </div>
                      
                        <div class="itoptions">
                            <div class="icon_editar"  @click="changeView('Edit',item)">
                                <img src="../../assets/i_editar.png" alt="icono editar"   >
                            </div>
                            <div class="icon_eliminar " @click="showModal(item.id)">
                                <img src="../../assets/i_eliminar.png" alt="icono eliminar"   >
                            </div>
                        </div>
                    </div>
                </div>  
                <div v-else>
                    <p class="snc">Aun no hay registros.</p>
                </div>
            </div>
        </div>

        </div>
        <div v-else-if="historyOption =='Add'">
            <AddCliente />
        </div>
        <div v-else-if="historyOption =='AddSucursal'">
            <AddSucursal />
        </div>
         <div v-else-if="historyOption =='Edit'">
             <EditCliente />
        </div>
           <div v-else-if="historyOption =='EditSucursal'">
             <EditSucursal />
        </div>
        <div v-else-if="historyOption == 'VistaCliente'">
            <VistaCliente />
        </div>
        <div v-else-if="historyOption == 'MuestraCliente'">
            <MuestraCliente />
        </div>
          <div v-else-if="historyOption == 'AddSubcuenta'">
            <AddSubcuenta />
        </div>
          <div v-else-if="historyOption =='EditSubcuenta'">
             <EditSubcuenta />
        </div>


       
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        

        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_delete">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_modal">Eliminar Cliente/Empresa</h3>
            <p class="message_modal">¿Estás seguro que deseas eliminar este Cliente/Empresa?</p>
            <div class="modal_btns dflx">
                <p @click="closeModal()" class="cancelar">cancelar</p> <p  @click="eliminar()" class="eliminar">eliminar</p>
            </div>
          </div>
        </div>
        

    </div>
</template>
<script>

import AddCliente from './addCliente.vue'
import AddSucursal from './addSucursal.vue'
import EditCliente from './editCliente.vue'
import EditSucursal from './editSucursal.vue'
import VistaCliente from './vistaCliente.vue'
import MuestraCliente from './muestrasCliente.vue'
import AddSubcuenta from './addSubcuenta.vue'
import EditSubcuenta from './editSubcuenta.vue'

import Noti from '../../services/noti.service'

import {mapActions} from 'vuex';
export default {
    name: 'Clientes',
    components:{
        AddCliente,
        EditCliente,
        VistaCliente,
        MuestraCliente,
        AddSucursal,
        EditSucursal,
        AddSubcuenta,
        EditSubcuenta
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           updsearchcli:'',
           currentPage: 1,
           itemsPerPage:   12,


           viewModal:false,
           itemDel:''
           
       }
   },
   created(){
       this.getAllInfoCli('clientes')
            let buscador = localStorage.getItem('buscador')
       if(buscador != undefined && buscador != null && 	buscador != ''){
         this.search_value = buscador
       }
   },
    watch:{
        search_value(value){
                localStorage.setItem('buscador', value)
        }
    },

    computed:{
        data() {
            let data = this.$store.getters["clientes/data"];

              let arr =[]
            if(data != 'No se encontraron coincidencias.' && data != 'error' && this.search_value !=''){
                arr = data.filter(item => this.includesItem(this.search_value , item.name))
            }else{
                arr =   data   
            }
             return arr 
        },
        historyOption(){
            return this.$store.getters["clientes/getHistoryOption"]
        },

        



          //Paginacion
        allItems () {
             let data = this.data
            data == 'No se encontraron coincidencias.' || data == 'error' ? data =[] : data
            return Object.keys(data).map(pid => data[pid])
        },
        filteredItems () {
         
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
   
   methods:{
         ...mapActions('clientes', ['setHistoryOptionCli']),
         ...mapActions('clientes', ['setAddedCli']),
         ...mapActions('clientes', ['getAllInfoCli']),
         
         ...mapActions('muestras', ['getInfoByIdMtr']),

          includesItem(search, name) {
                search = search.trim()
            if (search == "") {
                return true;
            }
            name = name.toLowerCase();
            search = search.toLowerCase();
            if (search == "") {
                return false;
            }
            return name.includes(search);
        },

         showModal: function(id){
             this.itemDel =id;
             this.viewModal=true
         },
         closeModal:function(){
             this.viewModal=false;
             this.itemDel =''
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
         eliminar: async function(){
             let id = this.itemDel
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("clientes/deleteItemCli", {option: 'client', id:id});
          
            if(result.status=='error'){
            this.message = result.message;
            this.status='error'
            this.delStatus();
            }else{
                this.status='';
                this.message='';
                this.wait()
            }
         },
        
         changeView(option, item){
             this.item=item
             this.setAddedCli(item)
             this.setHistoryOptionCli(option)
         },

        



         // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },
   }

    
 
}
</script>

<style scoped>


    p.snc{
         width: 54.3VW;
          height: 2.2916666666666665VW;
          text-align: center;
          padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }



.arrow_left{
    position: absolute;
    width: 2.5VW;
    height: 2.5VW;
    right: 68vw;
    top: 11vw;
    padding-top: .7vw;
    padding-left: .23vw;
  
    border-radius:  50% ;
    transition: all 500ms;
   

}.arrow_left:hover{
       background: #D3D3D3;
}
    #Clientes{
        margin-top: 5.288020833333333VW;
        margin-left: 4.427083VW;
    }

    .buscador {
    display: flex;
    margin-left: 7.5375VW;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }




    .btn_añadir button{
        background: var(--color-1);
        border: 0vw;
        width: 17.395833333333332VW;
        height: 2.2916666666666665VW;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
         padding-left: 0.78125VW;
        
    }.btn_añadir button span{
        font-weight: 700;
        padding-left: 0.5744791666666667VW;
    }

    .tbl_clientes{
        width: 54.3VW;
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }.hname{
        width: 25.521vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hcorreo{
        width: 11.823vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hrfc{
        width: 6.771vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hnum{
        width: 5.156vw;
        
        border-right: 0.052083333333333336VW solid var(--color-5);
    }.hnum p{
        font-size: 0.625vw;
        padding-top: 0.26875VW;
        
    }.hoptions{
        width: 5.156vw;
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: 1VW;
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
      
    }.itname{
         width: 25.521vw;
         overflow: auto;
          color: var(--color-4);
          cursor: pointer;
    }.itname p{
        color: var(--color-4);
        font-style: italic;
        font-weight: 700;
    }.itcorreo{
        width: 11.823vw;
        text-decoration: underline;
        color: var(--color-1);
         overflow: auto;
    }.itrfc{
        width: 6.771vw;
         overflow: auto;
    }.itnum{
        width: 5.156vw;
        overflow: auto;
        cursor: pointer;
    }
    .itnum{
        padding-left: 0vw !important;
    }.itnum p{
         color: var(--color-4);
         font-weight: 600;
         text-align: center;
         
    }
    .itoptions{
         width: 5.15625VW;
       background: #F8F8F8;
        display: flex;
       padding-top: 0.26875VW;
        padding-left: 0.78125VW;
        border: 1px solid white;
    }.icon_editar{
        margin-right: 0.26041666666666663VW;
        background: var(--color-2);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_editar:hover{
        background: var(--color-3);
    }.icon_editar img{
        width: 0.6770833333333334VW;
        height: 0.6770833333333334VW;
        /*margin-top: 0.3166666666666667VW;*/
        margin-left:0.46875VW;
    }.icon_eliminar{
        background: var(--color-4);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_eliminar:hover{
        background: var(--color-6);
    }.icon_eliminar img{
        width: 0.625VW;
        height: 0.625VW;
        /*margin-top: 0.3166666666666667VW;*/
        margin-left:0.46875VW;
    }

     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

</style>

<template >
    <div id="SolicitudServicio">
        

               <div class="form-content dflx">
           
            <form @submit.prevent="
                submit(
                  date_sol , hr_sol , client , obsv, muestras, forma, sucursal
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Usuario</h3>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="date_alt" class="date_alt">Fecha de recepción</label>
                            <input  v-model="date_sol" type="date" id="date_alt" name="date_alt" required >
                        </div>
                        <div>
                            <label class="hr" for="hr">Hora de recepción</label>
                            <input  v-model="hr_sol" type="time"  id="hr"  name="hr"  required />
                        </div>
                       
                    </div>

                     <div class="grup-form">
                        <div>
                            <label for="numcli" class="numcli">Nom. del Cliente</label>
                             <input   v-model="client.name" type="text"  id="name"  name="name"  required disabled  placeholder="Selecciona un cliente" class="in5"/>
                          <!--  <select id="select-numcli"  v-model="client" required disabled>
                            <option
                                v-for="(cli, key) in cliData"
                                v-bind:key="key"
                                v-bind:value="cli"
                            >
                                {{ cli.name}}
                            </option>
                            </select> -->
                        </div>
                    </div>
                    <div class="grup-form" v-if="client.sucursales != 'No se encontraron coincidencias.'">
                        <div class="Content_Sucur" :class="{Content_Sucur2: search_value77!=''}">
                            <label for="numcli" class="numcli">Sucursal</label>

                            <div class="buscador3 ">
                                <input v-model="search_value77" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
                                <div class="icon_buscar">
                                    <img @click="search2(search_value77)" src="../../assets/Search.png" alt="icono buscar">
                                </div>

                            </div>

                            <select v-if="search_value77==''" id="select-numcli" v-model="sucursal" > <!--:required="client.sucursales.length >0" -->
                            <option
                                v-for="(suc, key) in client.sucursales"
                                v-bind:key="key"
                                v-bind:value="suc"
                            >
                                {{ suc.name}}
                            </option>
                            </select>
                            <!--<select v-else id="select-numcli" v-model="sucursal">
                                <option v-for="(suc, key) in array_value77" v-bind:key="key" v-bind:value="suc">{{suc.name}}</option>
                            </select>-->
                            <div v-else>
                                <p class="cliheader">Sucursales Encontradas</p>

                                <div class="tablacli">
                                    <div v-if="array_value77 != 'No se encontraron coincidencias.'">
                                        <div class="cliitem" v-for="(cli, key) in array_value77" :key="key">
                                            <p class="cliname" @click="sucursal=cli, search_value77=cli.name">{{cli.name}}</p>
                                        </div>
                                    </div>
                                    <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>
                                </div>
                            </div>
                           
                        </div>
                         
                        
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del usuario</label>
                            <input :class="{void: client.name==''}"  v-model="client.name" type="text" id="name" name="name" pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"  required class="in2" disabled>
                        </div>
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="address" class="address">Dirección</label>
                            <input :class="{void: client.address==''}" v-model="client.address" type="text" id="address" name="address"  required class="in3" disabled>
                        </div>
                    </div>

                     <div class="grup-form dflx">
                        <div>
                            <label for="city" class="city">Ciudad</label>
                            <input :class="{void: client.city==''}" v-model="client.city" type="text" id="city" name="city"  required class="in1" disabled>
                        </div>
                        <div>
                            <label for="col" class="col">Colonia</label>
                            <input  :class="{void: client.col==''}" v-model="client.col" type="text" id="col" name="col"  required class="in1" disabled>
                        </div>
                       
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="phone" class="phone">Teléfono</label>
                            <input  :class="{void: client.phone==''}" v-model="client.phone" type="text" id="phone" name="phone"  minlength="10" maxlength="10" size="10" pattern="\d*" required class="in1" disabled>
                        </div>
                        <div>
                            <label for="cp" class="cp">Código Postal</label>
                            <input  :class="{void: client.cp==''}" v-model="client.cp" type="text" id="cp" name="cp" pattern="\d*" required class="in1" disabled>
                        </div>
                    </div>

                     <div class="grup-form">
                        <div>
                            <label for="email" class="email">E-mail</label>
                            <input  :class="{void: client.email==''}" v-model="client.email" type="email" id="email" name="email" required class="in3" disabled>
                        </div>
                    </div>
                    <div class="grup-form">
                         <div>
                             <label for="forma" class="forma"> Forma </label>
                             <select id="select-forma" v-model="forma" required name="forma">
                            <option>
                                1
                            </option>
                             <option>
                                2
                            </option>
                           
                            </select>
                        </div>
                    </div>

                    
                    <div class="dflx dvmuestras">
                        <div>
                            <div class="mt1"> <p>Descripción de la muestra e información de la toma de muestra</p></div>
                            <input v-model="muestra" type="text" id="muestra" name="muestra"   class="muestra" placeholder="ej: Agua Purificada">
                        </div>
                        <div>
                            <p class="mt2">Estudios Solicitados</p>
                 
                 
                 <div class="buscador3 ">
                        <input v-model="search_value2" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
                        <div class="icon_buscar">
                            <img @click="search2(search_value2)" src="../../assets/Search.png" alt="icono buscar">
                        </div>

                    </div>
                          <!--   <select id="select-prm" v-model="selectprm" >
                                <option
                                    v-for="(prm, key) in prmData"
                                    v-bind:key="key"
                                    v-bind:value="prm"
                                >
                                    {{ prm.name}}
                                </option>
                            </select>     -->

                         
                        </div>
                        
                    </div>
                    
                   
                    <div class="dflx">
                        <div class="estudios">
                       
                        <div  class="dflx est_item">
                            <p v-for="(est, key) in estudios" :key="key" @click="delestudio(key)"> {{est.name}}<span v-if="key < (estudios.length-1)">, </span> </p>
                            
                        </div>
                        
                    </div>
                   
                    </div>
                    <div class="dflx">
                        <div class="btnelimp">
<p v-if="estudios.length >0" class="eliminarult" >Click al estudio para eliminar.</p> <!-- @click="eliminarUltimo()" -->
                        </div>
                            
                             <div class="btnAdd" @click="agregar(muestra, estudios)">
                                <p class="dflx">  Agregar</p>
                            </div>
                
                    </div>
                     
                    <div class="tblest">
                        <div class=" it_tblest" v-for="(item, key) in muestras" :key="key" >
                            <div  class="dflx itemh">
                                  <p class="itemtitle">{{item.muestra}}</p>
                                  <p class="delitem" @click="deleteItem(item)">&#215;</p>
                            </div> 
                             <div class="estudios">
                       
                                <div  class="dflx est_item">
                                    <p v-for="(itest, key) in item.estudios" :key="key">{{itest.name}}<span v-if="key < (item.estudios.length-1)">, </span> </p>
                                </div>
                            </div>
                          
                        </div>

                    </div>

                    <p class="obvs">
                        Observaciones
                    </p>
                     <textarea name="obsv" id="text-obsv" cols="156" rows="10" v-model="obsv"></textarea>
                    
                    



                  

                        <div class="dflx">
                             <p @click="wait()" class="btnRegresar4">Regresar</p>
                              <!--<p  class="dPDF" @click="downloadWithCSS(client, muestras)">Descargar <span>PDF</span></p>-->
                             <button v-if="cancelClick" class="alta" type="submit"><span>Enviar</span></button>
                             <button v-else class="alta"><span>Enviar</span></button>
                        </div>

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>
        

                  <div class="buscadores">
                       <div class="buscador2">
                        <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
                        <div class="icon_buscar">
                            <img @click="search(search_value)" src="../../assets/Search.png" alt="icono buscar">
                        </div>

                    </div>
                      <p  class="eliminarult2" >*Puedes buscar por Nombre o No. de cliente.</p>
                   <p class="cliheader">Clientes Encontrados</p>
                    <div class="tablacli">
                       
                        <div v-if="cliData != 'No se encontraron coincidencias.'">
                            <div class="cliitem" v-for="(cli, key) in cliData" :key="key">
                                <p class="cliname" @click="setClient(cli), search_value77='', array_value77=[]">{{cli.name}}</p>
                            </div>
                        </div>
                        <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>
                    </div>




                     
                   <p class="cliheader clht">Parametros Encontrados</p>
                    <div class="tablacli">
                       
                        <div v-if="prmData != 'No se encontraron coincidencias.'">
                            <div class="cliitem" v-for="(prm, key) in prmData" :key="key">
                                <p class="cliname" @click="pushValue(prm)">{{prm.name}}</p>
                            </div>
                        </div>
                        <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>
                    </div>

<div class="buscador2 ">
                        <input v-model="search_value3" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
                        <div class="icon_buscar">
                            <img  src="../../assets/Search.png" alt="icono buscar">
                        </div>

                    </div>
                     <p  class="eliminarult2 " >*Buscar perfil por nombre.</p>
                     <p class="cliheader  mtp0">Perfiles Encontrados</p>
                    <div class="tablacli">
                       
                        <div v-if="perfiles != 'No se encontraron coincidencias.'">
                            <div class="cliitem" v-for="(pfl, key) in perfiles" :key="key">
                                <p class="cliname" @click="pushValues(pfl.params_profile)">{{pfl.name}}</p>
                            </div>
                        </div>
                        <div class="cliitem" v-else><p class="cliname">No se encontraron coincidencias.</p></div>
                    </div>
                  
                    
                  </div>
                     
             <div v-if="viewModal" class="modal_view"> 
           
              <div id="modal_ser">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_ser"> <span>Enviado</span> correctamente </h3>
           
            <div class="modal_ser_btns dflx">
                <p @click="Add()" class="otro">AÑADIR OTRA</p> <p  @click="back()" class="inicio">INICIO</p> <p class="downl" @click="downloadWithCSS()">Descargar PDF</p>
            </div>
          </div>
        </div> 

      
    


       
        </div>

      
      
       



    </div>
</template>
<script>
import dayjs from 'dayjs'
import {mapActions} from 'vuex';
export default {
    name:'SolicitudServicio',
    
    data(){
        return{
           status:'',
           message:'',
          
           date_sol:"",
           hr_sol:"",
           obsv: "",
           muestra:"",
           aver:[],
           estudios:[],
            muestras:[],
            forma: '1',
           
            client:{
             numcli:"",  name:"" , address:"", city:"", cp:"", phone:"", email:"", date_alt:"", hr:"", col:"", sucursales:"No se encontraron coincidencias."
            },
            sucursal: null,
            updcli:{
                numcli:"",  name:"" , address:"", city:"", cp:"", phone:"", email:"", date_alt:"", hr:"", col:"", sucursales:"No se encontraron coincidencias."
            },

            //MODAL
            selectprm:'',
            updatedprm:'',
            viewModal:false,
            viewParams:false,
             search_value:'',
             updsearchcli:'',

             search_value2:'',
             updsearchprm:'',
             search_value3:'',
             search_value77: '',
             array_value77: [],

             cancelClick: true
          
        }
    },
    updated(){
        if(this.updatedprm != this.selectprm){
             this.pushValue(this.selectprm)
             this.updatedprm = ''
             this.selectprm =''
        }

        if(this.client.numcli != this.updcli.numcli){
            this.sucursal = null
            this.updcli = this.client
        }

         if(this.search_value != this.updsearchcli){
            this.updsearchcli = this.search_value

             let option = 'clientes';
           
          let value = this.search_value
            let payload ={
              option : option,
              search:value
            }
            if(value!=""){
                this.$store.dispatch("clientes/searchCli", payload);
            }else{
               this.getAllInfoCli('clientes')
            }
         }

         if(this.search_value2 != this.updsearchprm){
            this.updsearchprm = this.search_value2

             let option = 'parametros';
           
          let value = this.search_value2
            let payload ={
              option : option,
              search:value
            }
            if(value!=""){
                this.$store.dispatch("parametros/searchPrm", payload);
            }else{
               this.getAllInfoPrm('parametros')
            }
         }
    },
    watch:{
        sucursal: function(value){
        
         
           if(value != null){
                let  model = {
                id:  this.client.id, numcli: this.client.numcli,  name:value.name , address:value.address, city:value.city, cp:value.cp, phone:value.phone, email:this.client.email, date_alt:this.client.date_alt, hr:this.client.hr, col:value.col, sucursales:this.client.sucursales
               }

               this.client = model
           }
          
        },
        search_value77: function(value){

            value= value.trim()
            value= value.toLowerCase()

            let arrayofwords=[]

            if(value==''){
                this.array_value77=[]
            }else{

                for(let i=0; i<this.client.sucursales.length; i++) {
                    let finded = '', word=this.client.sucursales[i].name
                    word = word.toLowerCase()
                    finded = word.search(value)

                    if(finded != -1){
                        arrayofwords.push(this.client.sucursales[i])
                    }
                }
                this.array_value77 = arrayofwords

            }
            
            
        }

    },
    created (){
        this.getAllInfoPrf('profile')
          this.getAllInfoCli('clientes');
          this.getAllInfoPrm('parametros');
    },  
    computed:{
       
         cliData() {
            return this.$store.getters["clientes/data"];
         },
         prmData() {
            return this.$store.getters["parametros/data"];
         },
          perfiles() {
            const data = this.$store.getters["profile/data"];
           
           let arr =[]

            if(data !='No se encontraron coincidencias.' && data !='error'){
                if( this.search_value3 !=''){
                     const coincide = (item ) => this.includesItem(this.search_value3,item.name) && item.params_profile != 'No se encontraron coincidencias.'
                     arr = data.filter(coincide)
                }else{
                    const coincide = (item ) =>  item.params_profile != 'No se encontraron coincidencias.'
                     arr = data.filter(coincide)
                }
            }else{
                arr = data
            }

             return arr

             //search_value3
          },
    },
    methods:{
        ...mapActions("clientes", ["getAllInfoCli"]),
        ...mapActions('solserv', ['setAddedSrv']),
        ...mapActions("parametros", ["getAllInfoPrm"]),
      ...mapActions('main', ['setHistoryOptionMain']),
      ...mapActions('profile', ['getAllInfoPrf']),
         ...mapActions('PDF', ['setAddedPDF']),
          wait: function(){
                setTimeout(() => this.$router.go(), 200);
          },
          delestudio(key){
                const filt = this.estudios.filter((item, index) => index != key)
                this.estudios = filt
          },
    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,1000);
    },
    downloadWithCSS: function() {
        //this.aver.push(cliDat)
        //this.aver.push(estud)
        //this.setAddedPDF(this.aver)

        this.setHistoryOptionMain('PDFclient')
          
      },
    eliminarUltimo: function(){
        let array=[];
        let items= this.estudios;
        let len = items.length;
        for(var i=0; i<items.length; i++){
            if(i!=(len-1)){
                    array.push(items[i])
            }
        }

        /*  array.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                    }); */

        this.estudios = array

    },
    
    delMsgs: function(){
       this.status=""
       this.msg=""
    },

    deleteItem: function(item){
         
        let array =[]
        let items = this.muestras
        
        for(var i=0; i<items.length; i++){
            if(items[i].muestra != item.muestra){
                array.push(items[i])
            }
        }

        this.muestras=array
    },
          pushValue: function (value){
               this.status='';
             this.message='';
            let values =[]
              values= this.estudios
                let encontrado ='no'
                for(var i=0; i<values.length; i++){
                    if(values[i].id==value.id){
                        encontrado='si'
                    }
                }
                if(encontrado=='no'){
                    values.push(value)
                    
                 /*   values.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                    }); */


                      this.estudios = values 
                }else{
                     this.status='error';
                     this.message='Estudio ya agregado';
                     this.delStatus()
                }

                this.viewParams=false //cerrar div cada que agregas un parametro.

              

          },
          pushValues: function (arr){
               this.status='';
             this.message='';
            let values =[]
              values= this.estudios
                for (const item of arr) {
                    let encontrado ='no'
                    let value = item.param
                    for(var i=0; i<values.length; i++){
                        if(values[i].id==value.id){
                            encontrado='si'
                        }
                    }
                    if(encontrado=='no'){
                        values.push(value)
                    }
                }

                this.estudios = values     
                this.viewParams=false //cerrar div cada que agregas un parametro.

              

          },
        agregar: function(muestra, estudios){
             this.status='';
             this.message='';
            if((muestra != '' && muestra != undefined) && ( estudios !='' &&  estudios != undefined)){
                let array =[];
                array = this.muestras
                let cadena = '';
                for(var i=0; i < estudios.length; i++){
                    if(i != estudios.length - 1){
                        cadena = cadena + estudios[i].name + ','
                    }else{
                        cadena = cadena + estudios[i].name
                    }
                }
                let model ={
                    muestra: muestra,
                    estudios:estudios,
                    cadenaEstudio: cadena
                }
                array.push(model)
                this.muestras= array
                this.muestra=''
                this.estudios=[];
            }else{
                     this.status='error';
                     this.message='Campos vacios.';
                     this.delStatus()
            }
            

        },
        showParams(){
            if(this.viewParams==false){
                 this.viewParams =true;
            }else{
                 this.viewParams =false;
            }
           
        },
        submit: async function(  date_sol , hr_sol , client , obsv, muestras, forma, sucursal ){

            if(!date_sol) { 
                this.status = 'error'
                this.message='La fecha no es valida.'
                this.delStatus()

                return;
            }
           
            const fecha1 = dayjs(date_sol).format('YYYY')
            const fecha2 = dayjs().subtract(1,'year').format('YYYY')
            const fecha3 = dayjs().add(1,'year').format('YYYY')

          
            if(fecha1 < fecha2 || fecha1 > fecha3){
                this.status = 'error'
                this.message='La fecha no es valida.'
                this.delStatus()

                return null
            }
         
            if(client.sucursales){
                delete client.sucursales
            }
            
            

            if(muestras.length==0 || muestras == undefined || muestras ==''){
                this.status = 'error'
                this.message='Falta agregar las muestras.'
                this.delStatus()
            }else{
                this.cancelClick=false
                let id_sucursal = null
                //console.log(sucursal)
                sucursal?.id ? id_sucursal =sucursal.id : id_sucursal
                 let solicitud ={
                     fecha: date_sol,
                     hr: hr_sol,
                     cliente: client,
                     observaciones: obsv,
                     muestras: muestras,
                     forma:forma,
                     id_sucursal:id_sucursal
                 }
                 

               /* let parse = JSON.stringify(solicitud)
                
                 localStorage.setItem('solicitud', parse) */

                   let result = await this.$store.dispatch("solserv/addItemSrv",  {option:'servicios', item: solicitud});
                   
                    if(result.status=='error'){
                        this.cancelClick=true
                        this.status='error'
                        this.message= result.message
                        this.delStatus()
                    }else{ // success
                        this.setAddedSrv(result.added)
                        this.setAddedPDF(solicitud)
                        this.showModal()
                    } 
            }
        },

           search: async function(value){
            let option = 'clientes';
           
          
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("clientes/searchCli", payload);
            }else{
               this.getAllInfoCli('clientes')
            }
         },

          search2: async function(value){
            let option = 'parametros';
           
          
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("parametros/searchPrm", payload);
            }else{
               this.W('parametros')
            }
         },
         setClient(cli){
             this.client = cli
         },


      showModal: function(){
         this.viewModal=true
     },
      includesItem(search, name) {
        search = search.trim()
      if (search == "") {
        return true;
      }
      name = name.toLowerCase();
      search = search.toLowerCase();
      if (search == "") {
        return false;
      }
      return name.includes(search);
    },
    closeModal:function(){
        this.viewModal=false;
        setTimeout(() => this.$router.go(), 200);
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    },
     Add:function(){
         this.cancelClick=true
        this.status='';
        this.message='';
        this.name="" ;
        this.date_sol="";
        this.hr_sol="";
        this.obsv =""
        this.muestras =[]
        this.muestra=''
        this.estudios =[]
        this.client={
               name:"" , address:"", city:"", cp:"", phone:"", email:"", pass:"", passc:"", date_alt:"", hr:"", col:"", sucursales:'No se encontraron coincidencias.',
            }

            this.updcli={
                name:"" , address:"", city:"", cp:"", phone:"", email:"", pass:"", passc:"", date_alt:"", hr:"", col:"", sucursales:'No se encontraron coincidencias.',
            }
        

        this.viewModal=false;
        this.setAddedCli('');
    },
    }
}
</script>
<style scoped>


.Content_Sucur {
    width: 36vw;
    height: 4vw;
    display: flex;
    flex-wrap: wrap;
}
.Content_Sucur2 {
    height: 17vw !important;
}


.mtp0{
    margin-top: 0vw !important;
}
.downl {
    margin-left: 3.5vw;
}

.buscadores{
    margin-left: 1vw;
}

.tablacli{
    
   
    overflow: auto;
    width: 14.7vw;
    height: 10vw;
    padding: .5vw;
}


.mtbprm{
    margin-top: 3vw;
}

p.cliheader{
    margin-top: 1vw;
    background: #009084;
    color: white;
    padding: .64vw;
    text-align: center;
    margin-bottom: 0vw;
}
.clht{
    margin-top: 2.8vw !important;
}

p.cliname{
    color: #868686;
    padding-bottom: .4vw;
   border-bottom:1px solid #D3D3D3;
    font-size: 0.6770833333333334VW;
    cursor: pointer;
}






#SolicitudServicio{
    min-height: 55vw;
}

.btnAdd>p {
    cursor: pointer;
    height: 1.7187500000000002VW;
    color: #FFFFFF;
     margin-left: 1vw;
     background-image: url("../../assets/btn_add.png");
     width: 7.916666666666666VW;
     text-align: center;
     padding: .3vw 3vw;
     font-weight: 700;

}
.btnAdd{
        margin-top: 0.5vw;
    margin-left: 8.9vw;
}

.btnelimp{
    width: 21.2vw;
}





.dvmuestras{
    margin-top: 2.2916666666666665VW;
}

  .dvmuestras p{
      background: #009084;
      height: 2.2916666666666665VW;
      color: white;
      text-align: center;
  }

div.mt1{
  width: 22.083333333333332VW;
  border-right: 1px solid white;
  font-size: 0.625VW;
  height: 2.2916666666666665VW;
  background: #009084;
}
input.muestra{
     width: 22.083333333333332VW;
     height: 1.7187500000000002VW;
     border-left: 0.052083333333333336VW solid #D3D3D3 ;
     border-right: none;
     border-top: none;
     border-bottom: none;
   
}


.mt1 p{
     padding-top: 0.3125VW;
    margin-left: 5vw;
     width: 11vw;
      font-weight: 600;
}

p.obvs{
    margin-top: 2.2395833333333335VW;
     background: #009084;
     color: white;
     text-align: center;
     font-size: 0.625VW;
    height: 2.2916666666666665VW;
    width: 39.03125VW;
     font-weight: 600;
     padding-top: 0.7291666666666666VW;
     margin-bottom: 0vw;
}
textarea#text-obsv{
     border: 0.052083333333333336VW solid #D3D3D3 ;
     width: 39.03125VW;
     outline: none;
     max-width:  39.03125VW;
     min-width: 39.03125VW;
     min-height: 10vw;
}


p.mt2{
    width: 16.947917VW;
    font-weight: 700;
    font-size: 0.9375VW;
    padding-top: 0.46875VW;
    margin-bottom: 0vw;
   
}


/*
p.mostrar{
width: 16.947917VW;
height: 1.7187500000000002VW;
background: #D3D3D3;
border-top: 0.052083333333333336VW solid white;
border-bottom: 0.052083333333333336VW solid white;
color: white;
margin-bottom: 0vw;
text-align: right;
cursor: pointer;
}

p.mostrar img{
    padding: .6vw .5vw;
}*/

#select-prm{
   width: 16.947917VW;
height: 1.7187500000000002VW; 
background: #D3D3D3;
border-top: 0.052083333333333336VW solid white;
border-bottom: 0.052083333333333336VW solid white;

margin-bottom: 0vw;
text-align: right;
cursor: pointer;
}

#select-prm option{
    background: white;
}

.tblest{
    margin-top: 1vw;
}

.paramdv{
    position:absolute;
      top: 38.8vw;
    right: 14.5vw;
    background: white;
    width: 16.947917VW;
    height: 9vw;
    overflow-y: auto;
    
}p.prmop{
    width: 16.947917VW;
    padding: .3vw .5vw;
    margin-bottom: 0vw;
    color: #868686;
    font-size: 0.6770833333333334VW;
    font-weight: 700;
    
}

p.eliminarult{
     color: #868686;
     font-style: italic;
     padding: .2vw .3vw;
    font-size: 0.6770833333333334VW;
    cursor: pointer;
}
p.eliminarult2{
     color: #868686;
     font-style: italic;
     padding: .2vw .3vw;
    font-size: 0.6770833333333334VW;
   
}
p.prmop:hover{
    background: #009084;
    color: white;
}

.estudios{
        background: #D3D3D3;
    width: 39.03125VW;
    height: 2.71875VW;
    overflow: auto;
    display: flex;
}

.itemh{
    width: 39.03125VW;
    height: 1.7187500000000002VW;
    
}.itemh p{
  
     padding-top: 0.26875VW;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
      margin-bottom: 0vw;
}
.est_item p{
      color: #797979;
      cursor: pointer;
          width: auto;
      margin-bottom: 0vw;
            padding-top: 0.3vw;
       padding-left: .5vw;
       margin-bottom: 0vw;
     
      font-weight: 400 !important;
}
p.itemtitle{
     border: 0.052083333333333336VW solid #D3D3D3 ;
     width: 22.083333333333332VW;
     height: 1.7187500000000002VW;
     padding-left: .5vw;
     color: #868686;
      font-size: 0.7291666666666666VW;
}
p.delitem{
     color: white;
      font-size: 0.9291666666666666VW;
      background: #797979;
      text-align: center;
      width: 1.7187500000000002VW;
      height: 1.7187500000000002VW;
      cursor: pointer;
}

   p.chpass{
         color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
  }


    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0vw ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #009084;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input, select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
        height: 1.3541666666666667VW;
  }

    .void{
        background: #D3D3D3;
    }

  
  select:focus-visible{
      outline: none !important;
  }

  input.in1{
      width: 12.812499999999998VW;
      font-weight: 700;
  }

  select{
       width: 29.8125VW;
       font-weight: 700;
       height: 1.3541666666666667VW;
  }


  select#select-forma{
      width: 12.8vw;
  }

  



    input.in2{
      width: 28.489583333333336VW;
      font-weight: 700;
  }

    input.in3{
      width: 31.927083333333332VW;
      font-weight: 700;
  }

    input.in4{
      width: 30.677083333333332VW;
      font-weight: 700;
  }

    input.in5{
      width: 29.8VW;
      font-weight: 700;
  }

    label.name{
        width: 6.822916666666666VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.numcli{
        width: 5.522917VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.address{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    
    label.city{
        width: 2.3958333333333335VW; 
        margin-right: 1.7187500000000002VW;
    }

     label.cp{
        width: 4.6875VW; 
        margin-right: 0.3645833333333333VW;
        margin-left: 1.25VW;
    }
    label.col{
       width: 2.3875VW;
        margin-right: .9VW;
        margin-left: 3VW;
    }

     label.email{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }

     label.forma{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    label.phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    label.pass{
        width: 4.1VW; 
    }
    label.passc{
        width: 7.575VW; 
        margin-left: 1.7vw;
    }

    label.hr{
       width: 6.25VW; 
       margin-right: 1.40625VW;
       margin-left: 1.64375VW;
    }
    label.date_alt{
        width: 6.5625VW;
        margin-right: 1.09375VW;
    }
    

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }

/** buscador */
    .buscador2 {
        margin-top: 3vw;
    }
    .buscador2, .buscador3 {
    display: flex;
    margin-left: 0vw;
    }
   .buscador2 input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador2 input:target, .buscador3 input:target{
       background: var(--color-1);
   } .buscador3 input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 14.43VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }



  /** estilos scroll bar */
    .paramdv::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .paramdv::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .paramdv::-webkit-scrollbar-button:increment,.paramdv::-webkit-scrollbar-button {
        display: none;
    } 

    .paramdv::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .paramdv::-webkit-scrollbar-thumb {
        background-color: #918e8e;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #918e8e;
    }

    .paramdv::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

    /** estilos scroll bar */
    .estudios::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .estudios::-webkit-scrollbar:vertical {
        width:0.2645833333333333VW;
    }

    .estudios::-webkit-scrollbar-button:increment,.estudios::-webkit-scrollbar-button {
        display: none;
    } 

    .estudios::-webkit-scrollbar:horizontal {
        height: 0.2645833333333333VW;
    }

    .estudios::-webkit-scrollbar-thumb {
        background-color: #918e8e;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #918e8e;
    }

    .estudios::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }


    
</style>
<template>
    <div v-if="lab =='laason'" id="Dashboard">
      
            <SideMenu />
          <div class="content_sections">
              <Sections :option="'Default'" />
          </div>
       
      
    </div>
    <div v-else id="Dashboard">
        <SideMenuBarragan />
          <div class="content_sections">
              <SectionsBarragan :option="'Default'" />
          </div>
    </div>
</template>
<script>

import SideMenu from '../components/SideMenu.vue'
import Sections from '../components/Sections.vue'
import SideMenuBarragan from '../components/barragan/SideMenu.vue'
import SectionsBarragan  from '../components/barragan/Sections.vue'
import {mapActions} from 'vuex';
export default {
  name: 'Dashboard',
  components:{
    SideMenu,
    Sections,
    SideMenuBarragan,
    SectionsBarragan
  },
  created(){
    let laboratorio = localStorage.getItem('laboratorio')
    
    if(laboratorio =='laason' || laboratorio =='barragan') {
      this.setlab(laboratorio)
    }
  },
  computed:{
    lab() {
      return this.$store.getters['main/getlab'];
    },
  },
  methods:{
      ...mapActions('main', ['setlab']),
  }
}
</script>
<style scoped>
   
   #Dashboard{
     display: flex;
   }

   .content_sections{
     background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
     
   }
</style>

import { render, staticRenderFns } from "./Solicitudes.vue?vue&type=template&id=6c5a4e41&scoped=true&"
import script from "./Solicitudes.vue?vue&type=script&lang=js&"
export * from "./Solicitudes.vue?vue&type=script&lang=js&"
import style0 from "./Solicitudes.vue?vue&type=style&index=0&id=6c5a4e41&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c5a4e41",
  null
  
)

export default component.exports
<template>
    <div id="Sections">

        

        <HeaderSec />
       
   
        <div v-if="historyOption =='Default'">
            <Default/>

        </div>
        <div v-else-if="historyOption =='Usuarios'">
                <Usuarios/>
        </div>
        <div v-else-if="historyOption =='Dashboard'">
                 <Default/>
        </div>
      <div v-else-if="historyOption =='Clientes'">
            <Clientes />
        </div>
          <div v-else-if="historyOption =='Parametros'">
            <Parametros />
        </div>
          <div v-else-if="historyOption =='Personalizados'">
          <Personalizados /> 
          
        </div>
          <div v-else-if="historyOption =='Solicitudes'">
            <Solicitudes />
        </div>
         <div v-else-if="historyOption =='Perfiles'">
            <Perfiles />
        </div>
          <div v-else-if="historyOption =='Muestras'">
            <Muestras />
        </div>
         <div v-else-if="historyOption =='Validaciones'">
            <Validaciones />
        </div>
        <div v-else-if="historyOption =='PDFcreate'">
            <PDFcreate_B />
        </div>

        <div v-else>
            <div class="proximamente">
                <Default/>
            </div>       
        </div>

       
    </div>
</template>
<script>

import {mapActions} from 'vuex';
import HeaderSec from './HeaderSec.vue';
import Usuarios from '../../views/Usuarios/Usuarios.vue';
import Default  from '../../views/Default/DefaulBarragan.vue'
import Clientes from '../../views/clientes_b/Clientes.vue'
import Parametros from '../../views/params_b/Parametros.vue'
import Solicitudes from '../../views/servicios_b/Solicitudes.vue'
import Perfiles from '../../views/params_b/profile.vue'
import Muestras from '../../views/muestas_b/Muestras.vue'
import Validaciones from '../../views/validaciones_b/Validaciones.vue'
import PDFcreate_B from './PDFcreate_B.vue'
import Personalizados from '../../views/params_b/group.vue'
export default {
  name: 'Sections',
  components:{
    HeaderSec,
    Default,
    Usuarios,
    Clientes,
    Parametros,
    Perfiles,
    Solicitudes,
    Muestras,
    Validaciones,
    PDFcreate_B,
    Personalizados
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
       ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>

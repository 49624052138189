<template>
  
    <div id="vistaClientes">
     
        <div class="info_cliente">
            <p class="client_name">{{cliente.name}}</p>

             <div class="dflx">
            <p class="pnum">No. de Cliente</p> 
            <p class="inp i1">{{cliente.numcli}}</p>   
            <p class="prfc">RFC</p>
            <p class="inp i2">{{cliente.td_rfc}}</p>  
            <p class="pemail">E-mail</p>
            <p class="inp i2">{{cliente.email}}</p>  
         </div>

         


       

         

         <div class="dflx">
             <p class="pdir">Direccion</p>
               <p class="inp i3">{{cliente.address}}</p>  
            <p class="pciu">Ciudad</p>
            <p class="inp i2">{{cliente.city}}</p>  
         </div>

          <div class="dflx">
               <p class="pcol">Colonia</p>
            <p class="inp i2">{{cliente.col}}</p>  
             <p class="pcp">C.P.</p>
            <p class="inp i2">{{cliente.cp}}</p>  
             <p class="ptel">Teléfono</p>
            <p class="inp i2">{{cliente.phone}}</p>  
          </div>
        </div>

       
         <hr class="hrinf">
    

        <div>
            <div class="dflx">
      
         
         <div class="btnfiltro btn1 dflx" @click="filtFecha()"> <div class="txtb"> FECHA</div> <img src="../../assets/filtrar.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtActivas()"> <div class="txtb">ACTIVAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
          <div class="btnfiltro btn2 dflx" @click="todas()"> <div class="txtb">TODAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
         <div class="btnfiltro btn2 dflx" @click="filtConcluidas()"> <div class="txtb">CONCLUIDAS</div> <img src="../../assets/filtrar2.png" alt="icono filtrar"></div>
        
    

          <div class="buscador">
            <input v-model="search_value" type="search" id="input-search" class="form-control" :placeholder="'Búsqueda'"  />
            <div class="icon_buscar">
                <img  src="../../assets/Search.png" alt="icono buscar">
            </div>

        </div>

           <div  id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages">  {{currentPage}} - {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
        </div>


        </div>
        

        <div class="tbl_vistaclientes">
            <div class="tbl_header">
                <div class="tblh hfecha">
                  <p>FECHA</p>
                </div>
                 <div class="tblh hclv">
                  <p>CLAVE LAASON</p>
                </div>
                 <div class="tblh htipo">
                  <p>TIPO DE MUESTRA</p>
                </div>
                  <div class="tblh htipo">
                  <p>OBSERVACIONES</p>
                </div>
                 <div class="tblh hdtm">
                  <p>DETERMINACIONES</p>
                </div>
                 <div class="tblh hfma">
                  <p>FIRMA DE <br> ANALISTA</p>
                </div>
                 <div class="tblh hfma">
                  <p>FIRMA DE <br> LIBERACIÓN</p>
                </div>
                 <div class="tblh hspv">
                  <p>SUPERVISÓ</p>
                </div>
                <div class="hopt">

                </div>
               
            </div>
           
        
            <div tbl_content>
                <div v-if="data != 'No se encontraron coincidencias.' && data != 'error' && data != ''" > <!--  -->
                    <div  v-for="(item, key) in filteredItems" :key="key">
                        
                        <div v-if="identity.role != 'spadmin'">
                            <div v-if="item.servicio.forma=='1'" class="tbl_items">
                                <div  class="tbl_item itfecha">
                               <p>{{convertdate(item.servicio.fecha)}}</p>
                        </div>
                         <div  class="tbl_item itclv">
                               <p @click="muestraCliente(item)">{{item.servicio.clave}}</p>
                        </div>
                         <div  class="tbl_item ittipo">
                               <p>{{item.tipo}}</p>
                        </div>
                          <div  class="tbl_item ittipo">
                               <p>{{item.extra.obv}}</p>
                        </div>
                         <div  class="tbl_item itdtm">
                               <p>{{determinaciones(item.resultados)}}</p>
                        </div>
                         <div  class="tbl_item itfma">
                               <p><span v-if="item.f_analista != null"> {{item.f_analista}}</span>  <span v-else> - </span></p>
                        </div>
                         <div  class="tbl_item itfma">
                               <p><span v-if="item.f_liberacion != null"> {{item.f_liberacion}}</span>  <span v-else> - </span></p>
                        </div>
                         <div  class="tbl_item itspv">
                               <p><span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                        </div>
                        <div @click="PDFview(item)" class="tbloptions tbldwl">
                            <img src="../../assets/download.png" alt="">
                        </div>
                         <div href="" class="tbloptions tbldel">
                            <img src="../../assets/compartir.png" alt="">
                        </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="item.servicio.forma =='2'" class="tbl_items">
                                <div  class="tbl_item itfecha">
                               <p>{{convertdate(item.servicio.fecha)}}</p>
                        </div>
                         <div  class="tbl_item itclv">
                               <p @click="muestraCliente(item)">{{item.servicio.clave}}</p>
                        </div>
                         <div  class="tbl_item ittipo">
                               <p>{{item.tipo}}</p>
                        </div>
                          <div  class="tbl_item ittipo">
                               <p>{{item.extra.obv}}</p>
                        </div>
                         <div  class="tbl_item itdtm">
                               <p>{{determinaciones(item.resultados)}}</p>
                        </div>
                         <div  class="tbl_item itfma">
                               <p><span v-if="item.f_analista != null"> {{item.f_analista}}</span>  <span v-else> - </span></p>
                        </div>
                         <div  class="tbl_item itfma">
                               <p><span v-if="item.f_liberacion != null"> {{item.f_liberacion}}</span>  <span v-else> - </span></p>
                        </div>
                         <div  class="tbl_item itspv">
                               <p><span v-if="item.superviso != null"> {{item.superviso}}</span>  <span v-else> - </span></p>
                        </div>
                        <div @click="PDFview(item)" class="tbloptions tbldwl">
                            <img src="../../assets/download.png" alt="">
                        </div>
                         <div href="" class="tbloptions tbldel">
                            <img src="../../assets/compartir.png" alt="">
                        </div>
                            </div>
                        </div>
                       
                    </div>
                     
                </div>  
                <div v-else>
                    <p class="snr">Aun no hay registros.</p>
                </div> 
            </div>
        </div>

        </div>
      
                        <div class="dflx">
                             <p @click="wait()" class="btnRegresar3">Regresar</p>
                        </div>

      
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        
        <!-- Lista subcuentas -->

    <div class="info_cliente divtop dflx">
                   <p class="client_name">SUBCUENTAS</p>

                       <div  class="nbsuc" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev3" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages"> {{currentPage3}} - {{totalPages3}}</p>
                        <button @click="next3"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
                   </div>
                
                    <div class="btn_añadirsuc" @click="changeView('AddSubcuenta',cliente)">
                           <button><img src="../../assets/i_mas.png" alt=""> <span>Añadir</span> Nueva Subcuenta </button>
                    </div>
              </div>
                 <div class="listsuc">
                      <div v-if="cliente.subcuentas != 'No se encontraron coincidencias.' && cliente.subcuentas != 'error'">
                    <div v-for="(sub, key) in filteredItems3" :key="key">
                   <hr class="hrinf">
                    <div class="dflx">
                         <div class="info_cliente">
                   

                    <div class="dflx">
                   <p class="pdir">Nombre</p>
                    <p class="inp i3">{{sub.name}}</p>  
                    <p class="pemail">E-mail</p>
                    <p class="inp i2">{{sub.email}}</p>  
                </div>

              
                
                </div>
                <div class="itoptions" >
                            <div class="icon_editar" @click="changeView('EditSubcuenta',sub)">
                                <img src="../../assets/i_editar.png" alt="icono editar"   >
                            </div>
                            <div class="icon_eliminar" @click="showModal2(sub.id)">
                                <img src="../../assets/i_eliminar.png" alt="icono eliminar" >
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div v-else>
                       <hr class="hrinf">
                       <p class="ssuc">Sin sucursales registradas...</p>
                </div>

                 <hr class="hrinf">
               </div>



        <!-- Lista sucursañes -->
            

             <div class="info_cliente divtop dflx">
                   <p class="client_name">SUCURSALES</p>

                       <div  class="nbsuc" id="navigate-buttons">
                    <div class="dflx">
                        <button @click="prev2" id="btn-prev" class="btn-navigate  ">	&#10094;</button>
                        <p class="num-pages"> {{currentPage2}} - {{totalPages2}}</p>
                        <button @click="next2"  id="btn-next" class="btn-navigate  ">&#10095;</button>
                     </div>
                   </div>
                
                    <div class="btn_añadirsuc" @click="changeView('AddSucursal',cliente)">
                           <button><img src="../../assets/i_mas.png" alt=""> <span>Añadir</span> Nueva Sucursal </button>
                    </div>
              </div>
            
             
               <div class="listsuc">
                      <div v-if="cliente.sucursales != 'No se encontraron coincidencias.' && cliente.sucursales != 'error'">
                    <div v-for="(suc, key) in filteredItems2" :key="key">
                   <hr class="hrinf">
                    <div class="dflx">
                         <div class="info_cliente">
                   

                    <div class="dflx">
                   <p class="pdir">Nombre</p>
                    <p class="inp i3">{{suc.name}}</p>  
               

                </div>

                <div class="dflx">
                    <p class="pdir">Direccion</p>
                    <p class="inp i3">{{suc.address}}</p>  
                    <p class="pciu">Ciudad</p>
                    <p class="inp i2">{{suc.city}}</p>  
                </div>

                <div class="dflx">
                    <p class="pcol">Colonia</p>
                    <p class="inp i2">{{suc.col}}</p>  
                    <p class="pcp">C.P.</p>
                    <p class="inp i2">{{suc.cp}}</p>  
                    <p class="ptel">Teléfono</p>
                    <p class="inp i2">{{suc.phone}}</p>  
                </div>
                
                </div>
                <div class="itoptions" >
                            <div class="icon_editar" @click="changeView('EditSucursal',suc)">
                                <img src="../../assets/i_editar.png" alt="icono editar"   >
                            </div>
                            <div class="icon_eliminar" @click="showModal(suc.id)">
                                <img src="../../assets/i_eliminar.png" alt="icono eliminar" >
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div v-else>
                       <hr class="hrinf">
                       <p class="ssuc">Sin sucursales registradas...</p>
                </div>

                 <hr class="hrinf">
               </div>


               
     <div v-if="viewModal2" class="modal_view">
           
            <div id="modal_delete">
            <p @click="closeModal2()" class="close_modal"> &#215;</p>
            <h3 class="title_modal">Eliminar Subcuenta</h3>
            <p class="message_modal">¿Estás seguro que deseas eliminar esta subcuenta?</p>
            <div class="modal_btns dflx">
                <p @click="closeModal2()" class="cancelar">cancelar</p> <p  @click="eliminarSub()" class="eliminar">eliminar</p>
            </div>
          </div>
        </div>
             
      
 <div v-if="viewModal" class="modal_view">
           
            <div id="modal_delete">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_modal">Eliminar Sucursal</h3>
            <p class="message_modal">¿Estás seguro que deseas eliminar esta sucursal?</p>
            <div class="modal_btns dflx">
                <p @click="closeModal()" class="cancelar">cancelar</p> <p  @click="eliminar()" class="eliminar">eliminar</p>
            </div>
          </div>
        </div>
        

    </div>
</template>
<script>

import AddCliente from './addCliente.vue'
import EditCliente from './editCliente.vue'
import VistaCliente from './vistaCliente.vue'
import {mapActions} from 'vuex';
import dayjs from 'dayjs'
export default {
    name: 'vistaClientes',
    components:{
        AddCliente,
        EditCliente,
        VistaCliente
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           currentPage: 1,
           itemsPerPage:   12,


           viewModal:false,
            viewModal2:false,
           itemDel:'',

           currentPage2: 1,
           itemsPerPage2:   4,
             currentPage3: 1,
           itemsPerPage3:   4,
           
       }
   },
   created(){
     let item =  this.$store.getters["clientes/getAdded"]
        let data ={
            id:item.id,
            option:'muestras_b/cliente'
        }
        this.getInfoByIdMtr(data)
          this.getIdentity()
   },

    computed:{
        cliente(){
            return this.$store.getters["clientes/getAdded"]
        },
        historyOption(){
            return this.$store.getters["clientes/getHistoryOption"]
        },

        data(){
          let data = this.$store.getters["muestras/data"]
               let arr =[]
            if(data != 'No se encontraron coincidencias.' && data != 'error' && this.search_value !=''){
                arr = data.filter(item => this.includesItem(this.search_value , item.servicio.clave_LAASON))
            }else{
                arr =   data   
            }
             return arr 
        },
 identity() {
           return this.$store.getters['admin/getIdentity'];
        },
        


      
        allItems () {
            let data = this.data
            data == 'No se encontraron coincidencias.' || data == 'error' ? data =[] : data
            return Object.keys(data).map(pid => data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },

        allItems2 () {
            let cliente = this.$store.getters["clientes/getAdded"]
            let data = cliente.sucursales
            if(data != 'No se encontraron coincidencias.'){
                return Object.keys(data).map(pid => data[pid])
            }else{
                return 'sin datos'
            }
            
        },
        filteredItems2 () {
             if(this.allItems2 != 'sin datos'){
                  return  this.allItems2.slice((this.currentPage2 - 1) * this.itemsPerPage2, this.currentPage2 * this.itemsPerPage2 )
             }else{
                 return []
             }
            
        },
        totalPages2 () {

              if(this.allItems2 != 'sin datos'){
                  let total = Math.ceil(this.allItems2.length / this.itemsPerPage2)
                        if(total < this.currentPage2){
                        this.currentPage2 =1
                        }
                return total
             }else{
                 return 0
             }
          
       },

       allItems3 () {
            let cliente = this.$store.getters["clientes/getAdded"]
            let data = cliente.subcuentas
            if(data != 'No se encontraron coincidencias.'){
                return Object.keys(data).map(pid => data[pid])
            }else{
                return 'sin datos'
            }
            
        },
        filteredItems3 () {
             if(this.allItems3 != 'sin datos'){
                  return  this.allItems3.slice((this.currentPage3 - 1) * this.itemsPerPage3, this.currentPage3 * this.itemsPerPage3 )
             }else{
                 return []
             }
            
        },
        totalPages3 () {

              if(this.allItems3 != 'sin datos'){
                  let total = Math.ceil(this.allItems3.length / this.itemsPerPage3)
                        if(total < this.currentPage3){
                        this.currentPage3 =1
                        }
                return total
             }else{
                 return 0
             }
          
       },
    },
   
   methods:{
         ...mapActions('clientes', ['setHistoryOptionCli']),
         ...mapActions('clientes', ['setAddedCli']),
         ...mapActions('subcuenta', ['setAddedSub']),
           ...mapActions('sucursal', ['setAddedSuc']),
         ...mapActions('muestras', ['getInfoByIdMtr']),
         ...mapActions('muestras', ['setAddedMtr']),
         ...mapActions('PDF', ['setAddedPDF']),
        ...mapActions('main', ['setHistoryOptionMain']),
          includesItem(search, name) {
                search = search.trim()
            if (search == "") {
                return true;
            }
            name = name.toLowerCase();
            search = search.toLowerCase();
            if (search == "") {
                return false;
            }
            return name.includes(search);
        },
         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
        PDFview: function(muestra) {
          if(muestra.superviso =="" || muestra.superviso==null){
              this.message='Esta muestra aún no se ha supervisado'
              this.status='error'
          }else if(muestra.extra.apar == '' || muestra.extra.apar == null){
               this.message='Faltan campos por llenar. (apariencia, cantidad, presentación, transporte).'
               this.status='error'
          }else{
            this.setAddedPDF(muestra)
            this.setHistoryOptionMain('PDFcreate')
          }
        },

        muestraCliente(item){
               this.setAddedMtr(item)
                this.setHistoryOptionCli('MuestraCliente')
        },
         showModal: function(id){
             this.itemDel =id;
             this.viewModal=true
         },
         closeModal:function(){
             this.viewModal=false;
             this.itemDel =''
         },
          showModal2: function(id){
             this.itemDel =id;
             this.viewModal2=true
         },
         closeModal2:function(){
             this.viewModal2=false;
             this.itemDel =''
         },
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
            setTimeout(() => this.wait()
            ,500);
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
        },
         eliminar: async function(){
             let id = this.itemDel
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("sucursal/deleteItemSuc", {option: 'sucursal', id:id});
          
            if(result.status=='error'){
            this.message = result.message;
            this.status='error'
            this.delStatus();
            }else{
                this.status='';
                this.message='';
                this.wait()
            }
         },

         eliminarSub: async function(){
             let id = this.itemDel
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("subcuenta/deleteItemSub", {option: 'subaccount', id:id});
          
            if(result.status=='error'){
            this.message = result.message;
            this.status='error'
            this.delStatus();
            }else{
                this.status='';
                this.message='';
                this.wait()
            }
         },
         search: async function(value){
            let option = 'cli/muestras';
           let item =  this.$store.getters["clientes/getAdded"] 
         
            let payload ={
              option : option,
              search: value,
              id_client: item.id
            }
            if(value!=""){
               
                this.$store.dispatch("muestras/searchMtrCli", payload);  // cambiar el computed debe ser el de muestras
            }else{
               
                let data ={
                    id:item.id,
                    option:'muestras/cliente'
                }
                this.getInfoByIdMtr(data)
            }
         },
         changeView(option, item){
             this.item=item
             this.setAddedCli(this.cliente)
              this.setAddedSuc(item)
              this.setAddedSub(item)
             this.setHistoryOptionCli(option)
         },



         // botones de paginacion.
         next () {
          
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },

         next2 () {
          
          if ( this.currentPage2 < this.totalPages2) {
            this.currentPage2++
          }
        },
        prev2 () {
          if (this.currentPage2 > 1) {
            this.currentPage2--
          }
        },

        next3 () {
          
          if ( this.currentPage3 < this.totalPages3) {
            this.currentPage3++
          }
        },
        prev3 () {
          if (this.currentPage3 > 1) {
            this.currentPage3--
          }
        },

          convertdate(fecha){
            let day = dayjs(fecha).format('DD/MM/YYYY')
            return day
        },
        determinaciones(resultados){
            let str =''
            if(resultados ==''){
                return str
            }
            for(var i=0; i< resultados.length; i++){
                if(i == 0){
                    str = str + resultados[i].pabr
                }else{
                    str = str + ',' + resultados[i].pabr
                }
            }

            return str
        },

        filtActivas(){
             let item =  this.$store.getters["clientes/getAdded"]
            let data ={
                id:item.id,
                option:'muestras/cliente/activas'
            }
            this.getInfoByIdMtr(data)
        },
         todas(){
             let item =  this.$store.getters["clientes/getAdded"]
            let data ={
                id:item.id,
                option:'muestras/cliente'
            }
            this.getInfoByIdMtr(data)
        },

         filtConcluidas(){ 
             let item =  this.$store.getters["clientes/getAdded"]
            let data ={
                id:item.id,
                option:'muestras/cliente/concluidas'
            }
            this.getInfoByIdMtr(data)
        },  

         filtFecha(){
             let item =  this.$store.getters["clientes/getAdded"]
                let data ={
                    id:item.id,
                    option:'muestras/cliente/fecha'
                }
                this.getInfoByIdMtr(data)
        },


   }

    
 
}
</script>

<style scoped>

.listsuc{
    margin-bottom: 5vw;
}

.nbsuc{
    margin-left: 12.3vw  !important;
}

.btn_añadirsuc button{
        background: var(--color-1);
        border: 0vw;
        width: 17.395833333333332VW;
        height: 2.2916666666666665VW;
        color: var(--color-5);
        font-size: 0.9375VW;
        font-weight: 300;
        text-align: left;
         padding-left: 0.78125VW;
         margin-left: 15vw;
        
    }.btn_añadirsuc button span{
        font-weight: 700;
        padding-left: 0.5744791666666667VW;
    }


.divtop{
    margin-top: 4vw;
}
 p.snr{
         width: 53.8VW; 
        height: 2.2916666666666665VW;
        text-align: center;
        padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
             font-size: 0.7375VW;
    }

    p.ssuc{
         width: 53.8VW; 
        height: 2.2916666666666665VW;
        text-align: center;
        padding-top: .5vw;
        background: #F8F8F8;
        color: #868686;
        font-size: 0.7375VW;
    }


  hr.hrinf{
    width: 57.969vw; 
    border: 0.5px solid #D3D3D3;
    margin-left: -1vw;
    margin-bottom: 2vw;
    margin-top: 1vw;
  }


    #vistaClientes{
        margin-top: 5.288020833333333VW;
        margin-left: 0.9375VW ;
        }

        .info_cliente p{
            font-size: 0.677vw;
            padding-top: 0.148vw ;
            color: #868686;
        }

        p.client_name{
            font-size: 1.615vw;
            font-weight: 400;
            padding-top: 0vw;
        }

   p.inp{
        border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
       font-weight: 700;
       height: 1.354vw;
       padding-left: 0.573vw;
   }
   p.i2{
       width: 12.813vw;
   }
   p.i1{
       width: 11.094vw;
   }

    p.i3{
        width: 31.927vw;
    }

    p.pnum{
        width: 4.792vw;
        margin-right: 1.042vw;
    }
    p.pdir{
        width: 3.229vw;
        margin-right: 0.885vw;
    }

    p.pcol{
        width: 2.604vw;
        margin-right: 1.510vw;
    }

    p.prfc, p.pcp {
        margin-left: 3.958vw;
        margin-right:1.042vw ;
        width: 1.302vw;
    }

     p.pemail{
        margin-left:2.865vw;
        margin-right:1.719vw ;
        width: 2.135vw;
    }

     p.pciu{
        margin-left:2.865vw;
        margin-right:1.458vw ;
        width: 2.396vw;
    }
     p.ptel{
        margin-left:2.865vw;
        margin-right:0.833vw;
        width: 3.021vw;
    }




   .btnfiltro{
        cursor:pointer;
        width: 7.240vw;
        height: 1.823vw;
        border: 0px;
        margin-right: 0.072vw;
        font-size: 0.625vw;
        font-weight: 700;
        color:white;
        text-align: left;
        padding-top: .4vw;
        padding-left: 0.990vw;
    }
   .btnfiltro .txtb{
        width: 4.6vw;
       
    }
    .btnfiltro img{
        width: 0.885vw;
        height: 0.938vw;
    }
    .btn1{
        background: #009084;
    }
    .btn2{
         background: #007E94;
    }

    .buscador {
    display: flex;
    margin-left: 7.5375VW;
    }
   .buscador input{
     border-radius: 0vw;
     border: 0.052083333333333336VW solid var(--color-6);
     width: 11.250000000000002VW;
     height: 1.8192708333333332VW; 
     padding-left: 1.8vw;
     font-size: 0.6770833333333334VW;
     color: var(--color-4) !important;
     border-right: 0vw;
   }.buscador input:target{
       background: var(--color-1);
   }

   input:-internal-autofill-selected {
       background: white !important;
   }

   .icon_buscar{
        border-radius: 0vw;
        border: 0.052083333333333336VW solid var(--color-6);
        width: 2.5VW;
        height: 1.8192708333333332VW;
        padding-left: 0.8vw;
        font-size: 0.6770833333333334VW;
        color: var(--color-4) !important;
    border-left: 0vw;
   }.icon_buscar img{
       width: 0.9895833333333333VW;
       height: 0.9895833333333333VW;
       margin-top: 0.4411458333333334VW;
   }




    

    .tbl_vistaclientes{
        width: 65.2VW;
        margin-top: 1.5625VW;
    }

    .tbl_header{
        display: flex;
        background:var(--color-2);
    }.tblh, .tbl_item{
        height: 2.2916666666666665VW;
    }.tblh p{
        color:var(--color-5);
        font-weight: 700;
        font-size: 0.9375VW;
        padding-top: 0.46875VW;
        /*padding-left: 1.4583333333333333VW;*/
        text-align: center;
    }

    .tbl_items{
        display: flex;
        
    }.tbl_item{
         padding-top: 0.36875VW;
         padding-left: .9VW;
         background: #F8F8F8;
         border: 1px solid white;

    }.tbl_item p{
        font-size:0.7291666666666666VW;
        color: #868686;
        margin-bottom: 0vw;
     
    }

    .hopt{
      width: 3.333vw;
       border-right: 0.052083333333333336VW solid var(--color-5);
       background: white;
    }

    .tbloptions{
        width: 1.667vw;
        background: #F8F8F8;
        border: 1px solid white;
        padding: 0.1vw 0.3vw;
        cursor: pointer;
    }
    .tbldwl img{
        width: 0.729vw;
        height: 0.885vw;
    }
    .tbldel img{
        width: 0.729vw;
        height: 0.573vw;
    }


    .hfecha{
        width: 5.156vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
    .itfecha{
        width: 5.156vw;
         overflow: auto;
    }


    .hclv{
        width: 5.156vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
    .hclv p{
        padding-top: .7vw;
        font-size: 0.625vw;
    }
    .itclv{
        width: 5.156vw;
         overflow: auto;
         cursor: pointer;
    }
    .itclv p{
      color:  #868686;
      font-weight: 700;
    }

    .htipo{
        width: 8.125vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
     .htipo p{
        padding-top: .7vw;
        font-size: 0.625vw;
    }
    .ittipo{
        width: 8.125vw;
         overflow: auto;
    }
    
     .hdtm{
        width: 16.250vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }
   
    .itdtm{
        width: 16.250vw;
         overflow: auto;
    }


     .hfma{
        width: 5.469vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }

     .hfma p{
         padding-top: .3vw;
        font-size: 0.625vw;
        
    }
   
    .itfma{
        width: 5.469vw;
         overflow: auto;
         padding-left: 0vw;
    }.itfma p{
        text-align: center;
        
    }

     .hspv{
        width: 8.229vw;
        border-right: 0.052083333333333336VW solid var(--color-5);
    }

   
    .itspv{
        width: 8.229vw;
         overflow: auto;
         padding-left: 0vw;
    }.itspv p{
       text-align: center;
    }
    
    
.itoptios{
         width: 5.15625VW;

    }
    .itoptions{
         width: 5.15625VW;
      
      display: flex;
       padding-top: 0.26875VW;
        padding-left: 0.78125VW;
        border: 1px solid white;
    }.icon_editar{
        margin-right: 0.26041666666666663VW;
        background: var(--color-2);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_editar:hover{
        background: var(--color-3);
    }.icon_editar img{
        width: 0.6770833333333334VW;
        height: 0.6770833333333334VW;
        /*margin-top: 0.3166666666666667VW;*/
        margin-left:0.46875VW;
    }.icon_eliminar{
        background: var(--color-4);
        width: 1.6145833333333335VW;
        height: 1.5625VW;
        border-radius: 3vw;
    }.icon_eliminar:hover{
        background: var(--color-6);
    }.icon_eliminar img{
        width: 0.625VW;
        height: 0.625VW;
        /*margin-top: 0.3166666666666667VW;*/
        margin-left:0.46875VW;
    }
     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

</style>

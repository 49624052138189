<template>
  
    <div id="Servicios">
        

        <div v-if="historyOption =='Default'">
            

        </div>
        <div v-else-if="historyOption =='Add'">
            <AddServicio />
        </div>
       
     

       <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
        </div>
        

       
        

    </div>
</template>
<script>

import AddServicio from './addServicio.vue'
import {mapActions} from 'vuex';
export default {
    name: 'Servicios',
    components:{
        AddServicio,
    },
 
   data(){
       return{

           status:'',
           message:'',
           item: '',
           search_value:'',
           currentPage: 2,
           itemsPerPage:   12,


           viewModal:false,
           itemDel:''
           
       }
   },
   created(){
       
   },

    computed:{
        
        historyOption(){
            return this.$store.getters["solserv/getHistoryOption"]
        },
    },
   
   methods:{
         
   }

    
 
}
</script>

<style scoped>


    #Servicios{
        margin-top: 5.288020833333333VW;
        margin-left: 8.427083333333334VW;
        
    }

   


     

    

  /** estilos scroll bar */
    .tbl_item::-webkit-scrollbar {
    -webkit-appearance: none;
    }

    .tbl_item::-webkit-scrollbar:vertical {
        width:0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-button:increment,.tbl_item::-webkit-scrollbar-button {
        display: none;
    } 

    .tbl_item::-webkit-scrollbar:horizontal {
        height: 0.3645833333333333VW;
    }

    .tbl_item::-webkit-scrollbar-thumb {
        background-color: #797979;
        /*border-radius: 1.0416666666666665VW;*/
        border: 0.10416666666666667VW solid #f1f2f3;
    }

    .tbl_item::-webkit-scrollbar-track {
        border-radius: 0.3645833333333333VW;  
    }

</style>

<template>
    <div>
        <div class="contendos">

            <div class="F1">
                <img src="../../assets/logo2laason2.png" />
                <div class="F1B2">
                    <h3>Laboratorio de Alimentos y Aguas de Sonora, S.A. de C.V.</h3>
                    <h4>Garmendia No. 80 Colonia San Benito CP 83280 Hermosillo, Sonora, México</h4>
                    <div class="F1B2_F2">
                        <h3>Teléfono:<span>213 2302</span></h3>
                        <h4>Correo:<span>laasonora@gmail.com</span></h4>
                    </div>
                </div>
            </div>

            <div class="F2">
                <h3>FORMATO TÉCNICO DE SOLICITUD DE SERVICIO</h3>
            </div>

            <div class="marco">
                <div class="B3">

                    <div class="B3F1">
                        <div class="B3F1_B1">
                            <p>Fecha de recepción</p>
                            <h3>{{infoC.cliente.update_at.slice(0, 10)}}</h3>
                        </div>
                        <div class="B3F1_B2">
                            <p>Hora de recepción</p>
                            <h3>{{infoC.cliente.hr}}</h3>
                        </div>
                    </div>

                    <div class="B3F2">

                        <div class="B3F2_B1">
                            <p>No. de Cliente</p>
                            <h3>{{infoC.cliente.numcli}}</h3>
                        </div>

                        <div class="B3F2_B2">
                            <p>Nombre de usuario</p>
                            <h3>{{infoC.cliente.name}}</h3>
                        </div>

                        <div class="B3F2_B3">
                            <p>Dirección</p>
                            <h3>{{infoC.cliente.address}}</h3>
                        </div>

                    </div>

                </div>

                <div class="B4">
                    <div class="B4F1">
                        <div class="B4F1_B1">
                            <p>Ciudad</p>
                            <h3>{{infoC.cliente.city}}</h3>
                        </div>
                        <div class="B4F1_B2">
                            <p>Código Postal</p>
                            <h3>{{infoC.cliente.cp}}</h3>
                        </div>
                    </div>
                    <div class="B4F2">
                        <div class="B4F2_B1">
                            <p>Teléfono</p>
                            <h3>{{infoC.cliente.phone}}</h3>
                        </div>
                        <div class="B4F2_B2">
                            <p>Tel/Fax</p>
                            <h3>{{infoC.cliente.td_phone}}</h3>
                        </div>
                    </div>
                    <div class="B4F3">
                        <p>E-mail</p>
                        <h3>{{infoC.cliente.email}}</h3>
                    </div>
                </div>
            </div>
            
            <div class="B5" v-for="(item, key) in infoC.muestras" :key="key">
                <div v-for="(items, keys) in item.estudios" :key="keys">
                <div class="B5F1">
                    <p>{{item.muestra}}</p>
                    <h3>{{items.name}}</h3>
                </div>
                </div>
            </div>

            <div class="B6">
                <p>El Laboratorio de Alimentos y Aguas de Sonora se compromete a prestar sus servicios de laboratorio solicitados por el usuario de acuerdo a las condiciones establecidas al reverso.</p>
            </div>

            <div class="B7">
                <p>REV-01</p>
                <h3>FTL-03</h3>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'PDFclienteInfo',
    props:{
        infoC:{
            required: true
        }
    }
}
</script>

<style scoped>

.contendos {
    width: 80%;
    text-align: -webkit-center;
}
.F1 {
    display: flex;
    justify-content: center;
}
    .F1 img {
        width: 6.8192708333333334VW;
        height: auto;
    }
    .F1B2 {
        align-self: center;
        margin-top: 1vw;
        margin-left: 0.9723958333333335VW;
        text-align: left;
    }
        .F1B2 h3 {
            font-size: 1.4VW;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2 h4 {
            font-size: 1.1VW;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2_F2 {
            display: flex;
        }
        .F1B2_F2 span{
            font-weight: 600;
        }
        .F1B2_F2 h3 {
            font-size: 1.1vw;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }
        .F1B2_F2 h4 {
            font-size: 1.1vw;
            margin: 0.1vw 0vw;
            white-space: nowrap;
        }


.F2 h3 {
    font-size: 1.4VW;
    font-weight: 600;
    margin: 2vw 0vw;    
}



.marco{
    width: 47vw;
    border: 1px solid #E5E5E5;
    padding: 1vw 2vw;
    margin-bottom: 1.5vw;
}
    .B3F1 {
        display: flex;
        justify-content: space-between;
    }
        .B3F1_B1 {
            display: flex;
        }
            .B3F1_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }
            .B3F1_B1 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }

        .B3F1_B2 {
            display: flex;
        }
            .B3F1_B2 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }
            .B3F1_B2 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }

        .B3F2_B1 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 4.2vw;
            }
            .B3F2_B1 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
        .B3F2_B2 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B2 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2vw;
            }
            .B3F2_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
        .B3F2_B3 {
            display: flex;
            height: 1.2vw;
        }
            .B3F2_B3 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 6vw;
            }
            .B3F2_B3 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }


.B4 {
    margin-top: 1vw;
}
        .B4F1 {
            display: flex;
            justify-content: space-between;
            margin-right: 10vw;
            height: 1.2vw;
        }

            .B4F1_B1 {
                height: 2vw;
                display: flex;
            }
            .B4F1_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 3vw;
            }
            .B4F1_B1 h3 {
                height: 1.6vw;
                align-self: center;
                font-size: 1vw;
                margin: 0vw;
            }
            .B4F1_B2 {
                display: flex;
            }
            .B4F1_B2 p {
                color: #868686;
                font-size: 1vw;
            }
            .B4F1_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
                margin-left: 3vw;
            }

        .B4F2 {
            height: 2vw;
            display: flex;
            justify-content: space-between;
            margin-right: 7.5vw;
            height: 1.2vw;
        }
            .B4F2_B1 {
                display: flex;
            }
            .B4F2_B1 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 2.2vw;
            }
            .B4F2_B1 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }
            .B4F2_B2 {
                display: flex;
            }
            .B4F2_B2 p {
                color: #868686;
                font-size: 1vw;
            }
            .B4F2_B2 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
                margin-left: 5.7vw;
            }
            
        .B4F3 {
            height: 1.2vw;
            display: flex;
        }
            .B4F3 p {
                color: #868686;
                font-size: 1vw;
                margin-right: 3.2vw;
            }
            .B4F3 h3 {
                align-self: end;
                font-size: 1vw;
                margin: 0vw;
            }



.B5 {
    width: 47vw;
    height: 32vw;
}
    .B5F1 {
        display: flex;
    }
        .B5F1 p {
            width: 29.5vw;
            color: #868686;
            background-color: #F8F8F8;
            font-size: 0.9vw;
            margin: 0vw;
            margin-right: 0.1vw;
            margin-bottom: 0.2vw;
            padding-top: 0.8vw;
        }
        .B5F1 h3 {
            width: 29.5vw;
            height: 2.6vw;
            padding-top: 0.8vw;
            align-self: center;
            font-size: 1vw;
            margin: 0vw;
            margin-bottom: 0.2vw;
            background-color: #F8F8F8;
        }

.B6 {
    margin-top: 4vw;
}
    .B6 p {
        font-size: 0.7vw;
        width: 47vw;
        text-align: justify;
    }


.B7 {
    display: flex;
    width: 47vw;
    justify-content: space-between;
    margin-bottom: 13vw;
}
    .B7 p {
        font-size: 0.8vw;
    }
    .B7 h3 {
        font-size: 0.8vw;
    }



</style>
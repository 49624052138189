<template>
    
   
         <div id="Default">
            <div class="img_bienvenida">
                <img src="../../assets/banerbarra.png" alt="">
            </div>

            <hr class="hr_sec">
            <div class="dflx">
            <div class="accdirec"> 
                <p class="tltacc">Accesos directos</p>

                <!-- cambiar segun el rol de usuario.-->
                <div v-if="this.identity.role == 'admin' || this.identity.role == 'legrafica' || this.identity.role == 'spadmin'" >
                      <div class="btnacc bac1">
                    <p  @click="vistaClientes()" > <span>Añadir</span>  Nuevo Cliente/Empresa </p> <img src="../../assets/i_mas.png" alt="">
                        </div>
                        <div class="btnacc bac1">
                            <p  @click="nuevoServicio()" > <span>Formato Técnico</span>  de Solicitud de Servicios</p> <img src="../../assets/i_mas.png" alt="">
                        </div>
                    <div class="btnacc bac2">
                        <p  @click="validarResultados()"> <span>Validar Resultados </span></p> <img src="../../assets/i_mas.png" alt="">
                    </div>
                </div>

                <!-- Analista.-->
                 <div v-if="this.identity.role == 'analista'">
                       <div class="btnacc bac1">
                            <p @click="listaMuestras()">  <span>Lista de Muestras</span></p> <img src="../../assets/i_mas.png" alt="">
                        </div>
                      <!--  <div class="btnacc bac1">
                            <p> <span>Resultados</span></p> <img src="../../assets/i_mas.png" alt="">
                        </div> -->
                   
                </div>
                
                  <!-- REcepcionista.-->
                 <div v-if="this.identity.role == 'recepcionista'">
                       <div class="btnacc bac1">
                    <p @click="nuevoCliente()" > <span>Añadir</span>  Nuevo Cliente/Empresa </p> <img src="../../assets/i_mas.png" alt="">
                        </div>
                        <div class="btnacc bac1">
                            <p   @click="nuevoServicio()"> <span>Formato Técnico</span>  de Solicitud de Servicios</p> <img src="../../assets/i_mas.png" alt="">
                        </div>
                   
                </div>


                
            </div>
             <div class="datainfo">
                <p class="dateinf">{{getDate2()}}</p>
                <p class="pt1">Pruebas Activas</p>
                <p v-if="identity.role != 'spadmin'" class="dt dt1">{{data.activas}}</p>
                <p v-else class="dt dt1">{{data.activas2}}</p>
                <p class="pt1">Resultados Validados</p>
                <p v-if="identity.role != 'spadmin'" class="dt dt2">{{data.validados}}</p>
                  <p v-else class="dt dt2">{{data.validados2}}</p>
            </div>

            <div class="options">
               
                <div class="dflx">
                    <div v-if="this.identity.role == 'admin' || this.identity.role == 'spadmin' || this.identity.role == 'legrafica' || this.identity.role == 'recepcionista'" class="option_item tp2"  @click="vistaClientes()">
                    <div class="img_item">
                       <img src="../../assets/Empresas_w.png" alt="">
                       
                    </div>
                    <div class="text_item">
                        <p>Clientes</p>
                    </div>
                    
                   
                    </div>
                    <div v-if="this.identity.role == 'admin' || this.identity.role == 'spadmin' || this.identity.role == 'legrafica' || this.identity.role == 'analista'" class="option_item tp1" @click="vistaMuestras()" >
                        <div class="img_item img_mt">
                        <img src="../../assets/Pruebas_w.png" alt="">
                        </div>
                        <div class="text_item">
                            <p>Muestras</p>
                        </div>
                        
                    
                    </div>

                      <div v-if=" this.identity.role == 'recepcionista'" class="option_item tp1"  @click="vistaSolicitudes()" >
                        <div class="img_item img_mt">
                        <img src="../../assets/Tests.png" alt="">
                        </div>
                        <div class="text_item">
                            <p>Solicitudes</p>
                        </div>
                        
                    
                    </div>
                </div>

                <div class="dflx bx2"  >
                       <div v-if="this.identity.role == 'admin' || this.identity.role == 'spadmin' || this.identity.role == 'legrafica'" class="option_item tp1" @click="vistaValidaciones()">
                    <div class="img_item">
                     <img src="../../assets/Resultados_w.png" alt="">
                    </div>
                    <div class="text_item">
                       <p>Validaciones</p>
                    </div>
                    
                    
                    </div>
                    <div v-if="this.identity.role == 'admin' || this.identity.role == 'spadmin' || this.identity.role == 'legrafica'" class="option_item  tp2"  @click="vistaUsuarios()">
                        <div class="img_item">
                        <img src="../../assets/Usuarios_w.png" alt="">
                        </div>
                        <div class="text_item">
                        <p>Usuarios</p>
                        </div>
                    
                    
                    </div>
                </div>

             

            </div> 
            </div>

        </div>

</template>
<script>
import dayjs from 'dayjs';
import { es } from "dayjs/locale/es";
import {mapActions} from 'vuex';

export default {
  name: 'Default',
  
   data(){
       return{
          
           status:'',
           message:'',
           validados:0,
           activas:0,
           validados2:0,
           activas2:0,
         
       }
   },
    computed:{
         identity() {
            return this.$store.getters['admin/getIdentity'];
            },
            info(){
                 return this.$store.getters["muestras/data"]
            },
             data() {
           let data = this.$store.getters["muestras/data"]
          
           let count =0
           let act=0
           let act2=0
           let count2=0
          
           if(data !='error' && data !='No se encontraron coincidencias.'){
               for(var i=0; i<data.length; i++){
                 if(data[i].servicio.forma =='1'){
                    
                      if(data[i].superviso != null && data[i].superviso != ''  ){
                        count++
                        
                      }
                      act++
                 }else{
                       if(data[i].superviso != null && data[i].superviso != ''  ){
                        count2++
                        
                    }
                    act2++
                 }
               
              }

              this.validados = count
              this.activas = act
              this.validados2 = count2
              this.activas2 = act2

            
              
           }
           
        
             return {validados: this.validados, activas: this.activas, validados2: this.validados2, activas2: this.activas2};
        },
      
    },
   created(){
       this.getIdentity()
     
       let day1 =  localStorage.getItem('fecha1')
            let day2 =  localStorage.getItem('fecha2')

            if(!day1 || !day2){
             day1 =  dayjs().format('YYYY-MM-DD')
             day2 =  dayjs().subtract(7,'days').format('YYYY-MM-DD')

              localStorage.setItem('fecha1',day1)
              localStorage.setItem('fecha2',day2)
           
 
            }
            
        


    let payload ={
        option:'muestras_b_rg',
        data:{
            fecha1:day1,
             fecha2:day2
        }
    }
    this.getAllRangeMtr(payload)
   },
   methods:{
      ...mapActions('main', ['setHistoryOptionMain']),
      ...mapActions('clientes', ['setHistoryOptionCli']),
       ...mapActions('solserv', ['setHistoryOptionSrv']),
        ...mapActions('muestras', ['setHistoryOptionMtr']),
         ...mapActions('muestras', ['getAllRangeMtr']),
...mapActions('validaciones', ['setHistoryOptionVal']),
       getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },

  
       
         getDate2:function(){
          let date;
          dayjs.locale("es");
       
          let mes = dayjs().format('MMMM');
          let ano = dayjs().format('YYYY')

          let splitStr = mes.toLowerCase().split(' ');
           
            
          
          let mesct = splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
         

          date =  mesct + ' ' + ano;
          return date
      },
      nuevoCliente: function(){
          this.setHistoryOptionMain('Clientes')
          this.setHistoryOptionCli('Add')
      },

      listaMuestras: function(){
          this.setHistoryOptionMain('Muestras')
          this.setHistoryOptionMtr('Default')
      },

        validarResultados: function(){
                this.setHistoryOptionMain('Validaciones')
                this.setHistoryOptionVal('Default')
            },

      nuevoServicio: function(){
          this.setHistoryOptionMain('Solicitudes')
          this.setHistoryOptionSrv('Add')
      },

      vistaClientes(){
           if(this.identity.role == 'admin' || this.identity =='spadmin' || this.identity.role=='legrafica' || this.identity.role=='recepcionista'){
            this.setHistoryOptionMain('Clientes')
          }
            
      },

      vistaMuestras(){
           if(this.identity.role == 'admin' || this.identity =='spadmin' || this.identity.role=='legrafica' || this.identity.role=='analista'){
               this.setHistoryOptionMain('Muestras')
          }
            
      },

      vistaUsuarios(){
            if(this.identity.role == 'admin' || this.identity =='spadmin' || this.identity.role=='legrafica' ){
             this.setHistoryOptionMain('Usuarios')
          }
           
      },

      vistaValidaciones(){
          if(this.identity.role == 'admin' || this.identity =='spadmin' || this.identity.role=='legrafica'){
              this.setHistoryOptionMain('Validaciones')
          }
            
      },

      vistaSolicitudes(){
          
              this.setHistoryOptionMain('Solicitudes')
          
      }
   }

   
    
 
}
</script>

<style scoped>
   .img_bienvenida img{
       margin-top: 4.558854166666667VW;
       margin-left: 13.802083333333334VW;
       margin-bottom: 2.1354166666666665VW;
       width: 40.36458333333333VW;
       height: 11.5625VW;

   }

    .hr_sec{
        width: 65.3125VW;
        margin-bottom: 2.4479166666666665VW;
    }
    .accdirec{
        margin-left: 8vw;
        margin-right: 4.21875VW;
    }
    p.tltacc{
        font-size: 1.6145833333333335VW;
        font-weight: 300;
        color: #565656;
        margin-bottom: 1.1458333333333333VW;
        margin-top: 3.927083VW;
    }

    .btnacc{
        width: 21.979166666666668VW;
        color: white;
        padding-top: 0.6770833333333334VW;
        padding-bottom: 0.6770833333333334VW;
        padding-left: 1.1979166666666667VW;
        display: flex;
        margin-bottom: 0.3645833333333333VW;
        cursor: pointer;
    }

    .btnacc p{
        margin-bottom: 0vw;
        width: 18.5vw;
        font-weight: 400;
    }

     .btnacc p span{
        font-weight: 600;
    }

    .bac1{
        background: #00A175;
    }
     .bac2{
        background: #007E94;
    }


.datainfo{
    margin-top: 3.304167VW;
    width: 14.635416666666668VW;
    text-align: center;
    margin-right: 3.229166666666667VW;
}

p.dateinf{
    font-weight: 700;
    font-size: 0.8333333333333334VW;
    margin-bottom: 1.729166666666666VW;
    color: #565656;
    
}

p.pt1{
    font-size: 1.0416666666666665VW;
    font-weight: 300;
    margin-bottom: 0vw;
}

p.dt {
    font-size: 3.75VW;
    font-weight: 700;
    margin-bottom: 0vw;
    margin-top: -1.2vw;
}

p.dt1{
    color: #00A175;
}

p.dt2{
    color: #565656;
}





    .bx2{
         margin-top: 0.7291666666666666VW; 
    }
   .options{
      margin-top:3.572917VW ;
   }.option_item{
       width: 6.379166666666666VW;
       height: 6.379166666666666VW;
       margin-left: 0.7291666666666666VW;
       cursor: pointer;
   }

   .option_item img{
        margin-top: 1.510417VW;
        margin-left: 2vw;
        width: 2.3072916666666665VW;
   }.option_item p{
       color: var(--color-5);
       font-weight: 400;
       font-size: 0.78125VW;
       text-align: center;
   }.img_mt{
       padding-top: 0.2vw;
       padding-left: .1vw;
   }

    .tp1{   
       background: var(--color-2);
   }.tp2{
     background: var(--color-3);
   }proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }

   .text_item{
       height: 3.28125VW;
   }.img_item{
       height: 4.5vw;
   }
</style>
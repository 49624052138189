<template >
    <div id="AddCli">
       
        <div class="form-content">
            <form @submit.prevent="
                submit(
                 name , address, city, cp, phone, email, pass,  date_alt, hr, td_rs, td_rfc, td_email, td_phone, col,  reg_fis_rec, cp_dom_fis_rec 
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Cliente</h3>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="date_alt" class="date_alt">Fecha de recepción</label>
                            <input  v-model="date_alt" type="date" id="date_alt" name="date_alt"  required>
                        </div>
                        <div>
                            <label class="hr" for="hr">Hora de recepción</label>
                            <input  v-model="hr" type="time"  id="hr" name="hr"  required  />
                        </div>
                    </div>
                    <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del cliente</label>
                            <input  v-model="name" type="text" id="name" name="name"    required class="in2">
                        </div>
                    </div>

                    <div class="grup-form">
                        <div>
                            <label for="address" class="address">Dirección</label>
                            <input v-model="address" type="text" id="address" name="address"   required class="in3">
                        </div>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="city" class="city">Ciudad</label>
                            <input v-model="city" type="text" id="city"  name="city"  required class="in1">
                        </div>
                         <div>
                            <label for="col" class="col">Colonia</label>
                            <input v-model="col" type="text" id="col"  name="col"  required class="in1">
                        </div>
                       
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="phone" class="phone">Teléfono</label>
                            <input v-model="phone" type="text" id="phone" name="phone"  minlength="10" maxlength="10" size="10" pattern="\d*" required class="in1">
                        </div>
                         <div>
                            <label for="cp" class="cp">Código Postal</label>
                            <input v-model="cp" type="text" id="cp" name="cp"  pattern="\d*" required class="in1">
                        </div>
                    </div>

                     <div class="grup-form">
                       <div>
                            <label for="email" class="email">E-mail</label>
                            <input v-model="email" type="email" id="email" name="email" required class="in3">
                        </div>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="pass" class="pass">Contraseña</label>
                            <input v-model="pass" type="password" id="pass" name="pass"  minlength="8" required class="in5" >
                        </div>
                          <p class="gencode" @click="passCreate()"> Generar Contraseña 	&#11119;</p>
                      <!--  <div>
                            <label for="passc" class="passc">Confirmar Contraseña</label>
                            <input v-model="passc" type="password" id="passc" name="passc"  minlength="8"  required class="in5" >
                        </div> -->
                        <div>
                            <label class="cp mostrarcontra" @click="Mostrar()"> <span v-if="mostrarpass">Mostrar</span> <span v-else>Ocultar</span> contraseña</label>                        
                        </div>
                    </div>



                    <div class="text_option txt2">
                            <h3>Datos Fiscales</h3>
                    </div>

                        <div class="grup-form">
                        <div>
                            <label for="td_rs" class="td_rs">Razón Social</label>
                            <input  v-model="td_rs" type="text" id="td_rs" name="td_rs"   required class="in4">
                        </div>
                    </div>

                        <div class="grup-form dflx">
                        <div>
                            <label for="td_rfc" class="td_rfc">RFC</label>
                            <input  v-model="td_rfc" type="text" id="td_rfc" name="td_rfc"    required class="in1">
                        </div> 
                        <div>
                            <label for="td_email" class="td_email">E-mail</label>
                            <input v-model="td_email" type="td_email" id="td_email" name="td_email"   required class="in1">
                        </div>
                    </div>

                        <div class="grup-form ">
                        <div>
                            <label for="td_phone" class="td_phone">Teléfono</label>
                            <input v-model="td_phone" type="text" id="td_phone" name="td_phone"  minlength="10" maxlength="10" size="10" pattern="\d*" required class="in1">
                        </div>
                        
                        </div>

                     <p class="opcionales">Opcionales</p>

                    <div class="grup-form">
                        <div>
                            <label for="reg_fis_rec" class="reg_fis_rec">Regimen Fiscal Receptor</label>
                            <input  v-model="reg_fis_rec" type="text" id="reg_fis_rec" name="reg_fis_rec"    class="in6">
                        </div>
                    </div>
                     <div class="grup-form">
                        <div>
                            <label for="cp_dom_fis_rec" class="cp_dom_fis_rec">C.P. del Domicilio Fiscal Receptor</label>
                            <input  v-model="cp_dom_fis_rec" type="text" id="cp_dom_fis_rec" name="cp_dom_fis_rec"    class="in7">
                        </div>
                    </div>

                        <div class="dflx">
                            <p @click="wait()" class="btnRegresar">Regresar</p>
                            <button class="alta" type="submit">Dar de <span>Alta</span></button>
                        </div>
                        

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>

            
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_add">Alta <span>exitosa</span></h3>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">AÑADIR OTRO</p> <p  @click="toEdit()" class="editar">EDITAR</p>  <p  @click="back()" class="inicio">INICIO</p>
            </div>
          </div>
        </div>

        </div>
           
    </div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
    name:'AddCli',
    data(){
        return{
           status:'',
           message:'',
           name:"" ,
           address:"",
           city:"",
           col:"",
           cp:"",
           phone:"",
           email:"",
           pass:"",
           passc:"",
           date_alt:"",
           hr:"",
           td_rs:"",
           td_rfc:"",
           td_email:"",
           td_phone:"",
            reg_fis_rec:"", 
            cp_dom_fis_rec:"" ,
mostrarpass:true,
           //MODAL

            viewModal:false,



        }
    },
    created(){
        this.pass = ""
        var ctrs = "abcdefgjklmnopqrtuvwxyzABCDEFGHJKLMNOPQRTUVWXYZ123456789";
        
        var codeGen = "";

         for (var k = 0; k < 10; k++) {
                codeGen += ctrs.charAt(Math.floor(Math.random() * ctrs.length));
         }


       this.pass = codeGen;
    },
    methods:{
         ...mapActions('clientes', ['setAddedCli']),
         ...mapActions('clientes', ['setHistoryOptionCli']),
          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        passCreate: function(){
        this.pass = ""
        var ctrs = "abcdefgjklmnopqrtuvwxyzABCDEFGHJKLMNOPQRTUVWXYZ123456789";
        
        var codeGen = "";

         for (var k = 0; k < 10; k++) {
                codeGen += ctrs.charAt(Math.floor(Math.random() * ctrs.length));
         }


       this.pass = codeGen;
        
        return codeGen
    },
        submit: async function(  name , address, city, cp, phone, email, pass,  date_alt, hr, td_rs, td_rfc, td_email, td_phone,col ,  reg_fis_rec,  cp_dom_fis_rec ){
                 this.status =''
                 this.message =''

                  let cliente ={
                "name":name,
                "address": address,
                "city": city,
                "cp": cp,
                "phone": phone,
                "email": email,
                "pass": pass,
                "date_alt": date_alt,
                "hr": hr,
                "td_rs": td_rs,
                "td_rfc": td_rfc,
                "td_email": td_email,
                "td_phone": td_phone,
                "col":col,
                "reg_fis_rec":reg_fis_rec,
                "cp_dom_fis_rec":cp_dom_fis_rec
                  }

                  

                    let result = await this.$store.dispatch("clientes/addItemCli",  {option:'client', item: cliente});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedCli(result.added )
                        this.showModal()
                       
                    } 

           /*  if(pass === passc){
                
               
             }else{
                 this.status ='error'
                 this.message ='Las contraseñas no coinciden.'
             } */
             
             

            
    },


     showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedCli('')
        this.setHistoryOptionCli('Default')
    },
    Add:function(){
        this.status='';
        this.message='';
        this.name="" ;
        this.address="";
        this.city="";
        this.cp="";
        this.phone="";
        this.email="";
        this.pass="";
        this.passc="";
        this.date_alt="";
        this.hr="";
        this.td_rs="";
        this.td_rfc="";
        this.td_email="";
        this.td_phone="";
          this.reg_fis_rec="";
            this.cp_dom_fis_rec="";

        this.viewModal=false;
        this.setAddedCli('');
    },
    toEdit:function(){
        
        
        this.setHistoryOptionCli('Edit')
         this.viewModal=false;
    },
    back:function(){
        setTimeout(() => this.$router.go(), 200);
    },
     Mostrar: function (){
        var tipo = document.getElementById("pass");
        if(tipo.type == "password"){
            tipo.type = "text";
            this.mostrarpass=false;
        }else{
            tipo.type = "password";
            this.mostrarpass=true;
        }

        var tipo2 = document.getElementById("passc");
        if(tipo2.type == "password"){
            tipo2.type = "text";
        }else{
            tipo2.type = "password";
        }
    }, 
    


   }
}
</script>
<style scoped>


  
    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }
  p.gencode{
       color: #868686;
      font-size: 0.7291666666666666VW;
  
      margin-left: 1vw;
      cursor: pointer;
      font-weight: 700;
  }
  p.opcionales {
    margin-top: 0vw;
    font-size: 1.2229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
    margin-top: 2vw;
  }
  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

  input.in1{
      width: 12.812499999999998VW;
  }

  input.in5{
      width: 9.812499999999998VW;
  }



    input.in2{
      width: 28.489583333333336VW;
  }

    input.in3{
      width: 31.927083333333332VW;
  }

    input.in4{
      width: 30.677083333333332VW;
  }

  

    label.name{
        width: 6.822916666666666VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.address{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    
    label.city{
        width: 2.3958333333333335VW; 
        margin-right: 1.7187500000000002VW;
    }

     label.cp{
        width: 4.6875VW; 
        margin-right: 0.3645833333333333VW;
        margin-left: 1.25VW;
    }
    label.col{
       width: 2.3875VW;
        margin-right: .9VW;
        margin-left: 3VW;
    }

     label.email{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }

    label.phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    label.pass{
        width: 4.1VW; 
    }
    label.passc{
        width: 7.575VW; 
        margin-left: 1.7vw;
    }

    label.hr{
       width: 6.25VW; 
       margin-right: 1.40625VW;
       margin-left: 2.34375VW;
    }
    label.date_alt{
        width: 6.5625VW;
        margin-right: 1.09375VW;
    }
    label.td_rs{
        width: 4.270833333333333VW;
        margin-right: 1.09375VW;
    }
    label.td_rfc{
        width: 1.3020833333333335VW;
        margin-right: 2.8125VW;
    }
    label.td_email{
        width: 2.1354166666666665VW;
        margin-left: 3.5937499999999996VW;
        margin-right: 0.5729166666666666VW;
    }
    label.td_phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }


    label.reg_fis_rec{
 width: 9.020833VW;
 padding-top: .2vw;
    }
    label.cp_dom_fis_rec{
 width:12.020833VW;
    }

    input.in6{
width: 14.2VW;
  }

  input.in7{
      width: 11.2VW;
  }

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


  








</style>
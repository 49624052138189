<template >
    <div id="EditPgp">
      
               <div class="form-content">
            <form @submit.prevent="
                submit(
                  added.name 
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información del Usuario</h3>
                    </div>

                     <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del perfil</label>
                            <input  v-model="added.name" type="text" id="name" name="name"   required class="in2">
                        </div>
                    </div>
                  

                      <h3 class="subtitle">Agregar perfiles</h3>

                    <div class="dflx">
                            <div class="tbl1">
                                <div class=" headertbl dflx">
                                    <p class="nameparam">Perfil</p>
                                    <p class="addparam"></p>
                                </div>
                                <div v-if="perfiles != 'No se encontraron coincidencias.' && perfiles != 'error'">
                                     <div class="bodytbl ">
                                        <div v-for="(item,key) in perfiles" :key="key" >
                                        <div v-if="includesItem(buscar, item.name)" class="itemtbl dflx">
                                            <p class="itemparam">{{item.name}}</p>
                                            <p @click="pushItem(item)" class="imgp"><img src="../../assets/i_mas.png" alt=""></p>
                                        </div>
                                        </div>
                                    </div>
                                    <div class="buscador dflx">
                                        <label for="buscar">Buscar</label>
                                        <input type="search" v-model="buscar">
                                    </div>
                                </div>
                                <div v-else>
                                         <div class="bodytbl ">
                                        <div  >
                                        <div  class="itemtbl dflx">
                                            <p class="itemparam">No se encontraron perfiles</p>
                                            <p  class="imgp"></p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tbl1">
                                <div class=" headertbl dflx">
                                    <p class="nameparam">AGREGADOS</p>
                                    <p class="addparam"></p>
                                </div>
                                 <div class="bodytbl " v-if="added.profiles_group != 'No se encontraron coincidencias.' && added.profiles_group != 'error'">
                                    <div v-for="(item,key) in added.profiles_group" :key="key" >
                                       <div  class="itemtbl dflx">
                                         <p class="itemparam">{{item.profile.name}}</p>
                                        <p @click="delItem(item)" class="delp">&#215;</p>
                                       </div>
                                    </div>
                                </div>
                                <div class="bodytbl " v-else>
                                     <div  >
                                       <div  class="itemtbl dflx">
                                         <p class="itemparam">Sin perfiles agregados</p>
                                        <p  class="delp"></p>
                                       </div>
                                    </div>
                                </div>
                            </div>
                    </div>

   

                            <div class="dflx">
                                 <p @click="wait()" class="btnRegresar">Regresar</p>
                                      <button class="alta" type="submit"><span>Aceptar</span></button>
                            </div>
                   

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>


                     
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_edit">Actualización <span>exitosa</span></h3>
           
            <div class="modal_edit_btns dflx">
                <p @click="Edit()" class="edit_otro">VOLVER A EDITAR</p> <p  @click="back()" class="edit_inicio">INICIO</p>
            </div>
          </div>
        </div>


        </div>
          

    </div>
</template>
<script>

import {mapActions} from 'vuex';
export default {
    name:'EditPgp',
    
    data(){
        return{
           status:'',
           message:'',
          buscar:'',
           

            //MODAL

            viewModal:false,
          
        }
    },
   created (){
       this.getIdentity()
         this.getAllInfoPrf('profile_b')
   
    },  
    computed:{
        added(){
            return this.$store.getters["group_b/getAdded"]
        },
          identity() {
           return this.$store.getters['admin/getIdentity'];
        },
         perfiles() {
           
            let arr =[]
             let profiles =this.$store.getters["profile/data"];
             profiles !='error' && profiles !='No se encontraron coincidencias.' ? arr = profiles : arr
            let arr2=[]
            if(this.added.profiles_group  !='error' && this.added.profiles_group !='No se encontraron coincidencias.'){
                for (const profile of arr) {
                    let encontrado = false
                    for (const add of this.added.profiles_group) {
                        if(add.id_profile == profile.id){
                            encontrado = true
                            break
                        }
                    }   
                    
                    !encontrado ? arr2.push(profile): profile
                }

                arr =arr2
            }

             return  arr
        },
    },
    methods:{
          ...mapActions('group_b', ['setAddedPgp']),
         ...mapActions('group_b', ['setHistoryOptionPgp']),
        ...mapActions("main", ["getInfoById"]),
                   ...mapActions('profile', ['getAllInfoPrf']),
          getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
        },
         wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
             submit: async function(  name ){
                 this.status =''
                 this.message =''
            
              

                let profile ={
                    "id":this.added.id,
                    "name":name
                  
                  }
                   
                        let result = await this.$store.dispatch("group_b/editItemPgp",  {option:'group_b', item: profile});
                    
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.showModal()
                        } 
            
    },
   includesItem(search, name) {
      if (search == "") {
        return true;
      }
      name = name.toLowerCase();
      search = search.toLowerCase();
      if (search == "") {
        return false;
      }
      return name.includes(search);
    },

    
    pushItem: async function (item){
             this.status =''
             this.message =''
            let result = await this.$store.dispatch("group_b/addItemPgp",  {option:'group_profile_b', item: {id_profile_group: this.added.id, id_profile: item.id}});
        
            if(result.status=='error'){
                this.status='error'
                this.message= result.message
                this.delStatus()
            }else{ // success
                this.status='success'
                this.message= 'Perfil agregado con exito.'
                this.delStatus()
                await  this.actualizar()
            } 

             
    },
    delItem: async function(item){
          
           this.status='';
           this.message='';

             let result = await this.$store.dispatch("group_b/deleteItemPgp", {option: 'group_profile_b', id:item.id});
          
            if(result.status=='error'){
                this.message = result.message;
                this.status='error'
                this.delStatus();
            }else{
                await  this.actualizar()
            }
           
    },
    actualizar: async function (){
         let result = await this.getInfoById({option:'group_b', id:this.added.id})
         this.setAddedPgp(result)
    },

          delMsgs: function(){
            this.status=""
            this.message=""
        },
       delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000); 
        },

      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedPgp('')
        this.setHistoryOptionPgp('Default')
    },
    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    }
    }
}
</script>
<style scoped>

     p.parm{
            color: #868686;
        font-size: 0.7291666666666666VW;
        font-weight: 400 !important;
    }



    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }




    input.in2{
      width: 27.489583333333336VW;
  }



    label.name{
        width: 7.822917VW; 
        margin-right: 0.7291666666666666VW;
    }



    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }


 

select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
        width: 5VW;
  }

  select:focus-visible{
      outline: none !important;
  }

.headertbl{
    background: #00A175;
    color: white;
}
.headertbl p{
    color: white;
    margin: 0vw;
}
p.nameparam{
    width: 20vw;
    padding:.5vw 1vw;
    border-right: 1px solid white;
    font-size: .8vw;
    text-transform: uppercase;
}
p.addparam{
     width: 3vw;
}
.bodytbl{
    height: 20vw;
    overflow: auto;
}

.itemtbl p{
    margin: 0vw;
    border-bottom: 1px solid white;
     font-size: .7vw;
}
p.itemparam{
    width: 20vw;
    padding:.5vw 1vw;
    border-right: 1px solid white;
}
p.imgp{
     width: 3vw;
     text-align: center;
     background: var(--color-2);
      cursor: pointer;
}
p.imgp img{
   
    padding-top: .5vw;
}
.bodytbl{
    background: #dddddd;
}

.buscador{
    margin-top: 1vw;
}
.buscador label{
    font-size: .8vw;
    padding: 0vw 0.5vw;
}
.buscador input{
    font-size: .8vw;
    width: 20vw;
}

p.delp{
    color: rgb(95, 95, 95);
     width: 3vw;
     text-align: center;
     font-size: 1vw;
     cursor: pointer;
     padding-top: .2vw;
}
.tbl1{
    margin-right: 1vw;
}

h3.subtitle{
     margin-top: 2vw;
    font-size: 1.2229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
}

p.btnRegresar{
    margin-left: 0vw;
}

    
</style>
<template >
    <div id="EditCli">
          
         
               <div class="form-content">
            <form @submit.prevent="
                submit(
                  added.name ,  added.address, added.city, added.cp, added.phone,   added.date_alt, added.hr,  added.col
                )
              ">
                    <div class="text_option txt1">
                           
                            <h3>Información de la Sucursal</h3>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="date_alt" class="date_alt">Fecha de recepción</label>
                            <input  v-model="added.date_alt" type="date" id="date_alt" name="date_alt" >
                        </div>
                        <div>
                            <label class="hr" for="hr">Hora de recepción</label>
                            <input  v-model="added.hr" type="time"  id="hr"  name="hr"  />
                        </div>
                    </div>
                    <div class="grup-form">
                        <div>
                            <label for="name" class="name">Nombre del cliente</label>
                            <input  v-model="added.name" type="text" id="name" name="name"  required class="in2">
                        </div>
                    </div>

                   

                    <div class="grup-form">
                        <div>
                            <label for="address" class="address">Dirección</label>
                            <input v-model="added.address" type="text" id="address" name="address"  required class="in3">
                        </div>
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="city" class="city">Ciudad</label>
                            <input v-model="added.city" type="text" id="city" name="city"  required class="in1">
                        </div>
                        <div>
                            <label for="col" class="col">Ciudad</label>
                            <input v-model="added.col" type="text" id="col" name="col"  required class="in1">
                        </div>
                       
                    </div>

                    <div class="grup-form dflx">
                        <div>
                            <label for="phone" class="phone">Teléfono</label>
                            <input v-model="added.phone" type="text" id="phone" name="phone"  minlength="10" maxlength="10" size="10" pattern="\d*" required class="in1">
                        </div>
                        <div>
                            <label for="cp" class="cp">Código Postal</label>
                            <input v-model="added.cp" type="text" id="cp" name="cp" pattern="\d*" required class="in1">
                        </div>
                    </div>

                   

                  

                     
                        <div class="dflx">
                             <p @click="wait()" class="btnRegresar">Regresar</p>
                             <button class="alta" type="submit"><span>Aceptar</span></button>
                        </div>
                       

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>

  
                     
             <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
            <p @click="closeModal()" class="close_modal"> &#215;</p>
            <h3 class="title_edit">Actualización <span>exitosa</span></h3>
           
            <div class="modal_edit_btns dflx">
                <p @click="Edit()" class="edit_otro">VOLVER A EDITAR</p> <p  @click="back()" class="edit_inicio">INICIO</p>
            </div>
          </div>
        </div>


        </div>
       

    </div>
</template>
<script>

import {mapActions} from 'vuex';
export default {
    name:'EditCli',
    
    data(){
        return{
           status:'',
           message:'',
          mostrarpass:true,
           pass:"",
           passc:"",

            //MODAL

            viewModal:false,
          
        }
    },
   
    computed:{
        added(){
            return this.$store.getters["sucursal/getAdded"]
        },
         cliente(){
            return this.$store.getters["clientes/getAdded"]
        },
      
    },
    methods:{
          ...mapActions('clientes', ['setAddedCli']),
            ...mapActions('sucursal', ['setAddedSuc']),
         ...mapActions('clientes', ['setHistoryOptionCli']),
        ...mapActions("clientes", ["getInfoByIdCli"]),
         ...mapActions('sucursal', ['setHistoryOptionSuc']),
        ...mapActions("sucursal", ["getInfoByIdSuc"]),
          wait: function(){
                setTimeout(() => this.$router.go(), 200);
            },
             submit: async function( name , address, city, cp, phone,   date_alt, hr, col ){
                 this.status =''
                 this.message =''
            let sucursal ={
                    "id":this.added.id,
                    "id_client":this.added.id_client,
                    "name":name,
                    "address": address,
                    "city": city,
                    "col":col,
                    "cp": cp,
                    "phone": phone,
            
                    "date_alt": date_alt,
                    "hr": hr ,
                    }

                   

                        let result = await this.$store.dispatch("sucursal/editItemSuc",  {option:'sucursal_b', item: sucursal});
                    
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.showModal()
                        } 
             

            
    },


      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedCli('')
        this.setAddedSuc('')
        this.setHistoryOptionCli('Default')
    },
    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    },
 
    }
}
</script>
<style scoped>

   p.chpass{
         color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
  }


    .text_option h3{
   
    margin-top: 0vw;
    font-size: 1.8229166666666667VW;
    color: var(--color-4);
    font-weight: 300 !important;
    font-style: normal;
  }


    .form-content{
        margin-left: 0.9375VW ;
    }

  button.alta{
      width: 7.916666666666666VW;
      height: 2.2916666666666665VW;
      border: 0px;
      color: white;
      background: #00A175;
      margin-left: 0.625VW;
      margin-top: 2.7083333333333335VW;
  }
  button.alta span{
      font-weight: 600 !important;
  }

  label{
      color: #868686;
      font-size: 0.7291666666666666VW;
      font-weight: 400 !important;
     
  }

  input{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
  }

  input.in1{
      width: 12.812499999999998VW;
  }

  input.in5{
      width: 9.812499999999998VW;
  }



    input.in2{
      width: 28.489583333333336VW;
  }

    input.in3{
      width: 31.927083333333332VW;
  }

    input.in4{
      width: 30.677083333333332VW;
  }

    label.name{
        width: 6.822916666666666VW; 
        margin-right: 0.7291666666666666VW;
    }

    label.address{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }
    
    label.city{
        width: 2.3958333333333335VW; 
        margin-right: 1.7187500000000002VW;
    }

     label.cp{
        width: 4.6875VW; 
        margin-right: 0.3645833333333333VW;
        margin-left: 1.25VW;
    }
     label.col{
       width: 2.3875VW;
        margin-right: .9VW;
        margin-left: 3VW;
    }

     label.email{
        width: 3.229166666666667VW; 
        margin-right: 0.8854166666666666VW;
    }


    label.phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    label.pass{
        width: 4.1VW; 
    }
    label.passc{
        width: 7.575VW; 
        margin-left: 1.7vw;
    }

    label.hr{
       width: 6.25VW; 
       margin-right: 1.40625VW;
       margin-left: 2.34375VW;
    }
    label.date_alt{
        width: 6.5625VW;
        margin-right: 1.09375VW;
    }
    label.td_rs{
        width: 4.270833333333333VW;
        margin-right: 1.09375VW;
    }
    label.td_rfc{
        width: 1.3020833333333335VW;
        margin-right: 2.8125VW;
    }
    label.td_email{
        width: 2.1354166666666665VW;
        margin-left: 3.5937499999999996VW;
        margin-right: 0.5729166666666666VW;
    }
    label.td_phone{
        width: 3.0208333333333335VW;
        margin-right: 1.09375VW;
    }

    .txt2{
        margin-top: 2.083333333333333VW;
    }

    .txt1{
        margin-bottom: 1.6145833333333335VW;
    }



    .grup-form{
        margin-bottom: 0.6770833333333334VW;
    }
    
    p.gencode{
       color: #868686;
      font-size: 0.7291666666666666VW;
  
      margin-left: 1vw;
      cursor: pointer;
      font-weight: 700;
  }

   select{
       width: 29.8125VW;
       font-weight: 700;
       height: 1.3541666666666667VW;
  }
 select{
      border: 0.052083333333333336VW solid #D3D3D3 ;
       color: #868686;
        height: 1.3541666666666667VW;
  }

  select#select-forma{
      width: 6.8vw;
      margin-left: 1vw;
  }

 select:focus-visible{
      outline: none !important;
  }
  label.forma2{
        width: 3.229166666666667VW; 
        margin-right: 0.4VW;
         margin-left: 1.64375VW;
    }


    
</style>